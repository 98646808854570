import { IErrorCallbackType, ISuccessCallbackType } from '../domain/services/common';
import {
  IBrokerCurrencyRateVariationData,
  IBrokerCurrencyRateVariationParams,
  IFastDealRatesData,
} from '../domain/services/CurrenciesServiceEntities';
import { getAuthHeader } from '../helpers/authHeader';
import { generalRequest } from '../utils/axiosSetup';

export default abstract class CurrenciesService {
  static getRates = async (
    onSuccess?: ISuccessCallbackType<any>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<any>({
      url: '/rates',
      headers: getAuthHeader(),
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static getСurrencies = async (
    onSuccess?: ISuccessCallbackType<any>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<any>({
      url: '/currencies',
      headers: getAuthHeader(),
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static getFastDealRates = async (
    onSuccess?: ISuccessCallbackType<IFastDealRatesData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IFastDealRatesData>({
      url: '/fast-deal-rates',
      headers: getAuthHeader(),
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static getBrokerCurrencyRateVariation = async (
    params: IBrokerCurrencyRateVariationParams,
    onSuccess?: ISuccessCallbackType<IBrokerCurrencyRateVariationData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IBrokerCurrencyRateVariationData>({
      url: `/currencies/${params.currency.toLowerCase()}/brokers/${
        params.brokerId
      }/variation`,
      headers: getAuthHeader(),
      successCallback: onSuccess,
      errorCallback: onError,
    });
}
