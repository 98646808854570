import './style.scss';

import React from 'react';
import { SpinnerCircular } from 'spinners-react';

import { classNames } from '../../../utils/api';
import { Defaults } from '../../common';

interface SpinnerCircular {
  className?: string;
  style?: object;
  color?: string;
  speed?: number;
  thickness?: number;
  size?: string | number;
}

interface SpinnerContainerProps extends SpinnerCircular {
  loading: boolean;
  children: React.ReactNode;
  classNameContainer?: string;
}

type Size = 'large' | 'middle' | 'small' | string | number;

const DefaultSizes = {
  large: 400,
  middle: 200,
  small: 100,
};

const SpinnerContainer = ({
  loading,
  children,
  classNameContainer,
  color,
  size,
  ...props
}: SpinnerContainerProps) => {
  const getSize = (size?: Size): number | string =>
    size ? DefaultSizes[size] ?? size : DefaultSizes['middle'];

  return (
    <div className={classNames('spinner-container', classNameContainer)}>
      {children}
      {loading && (
        <div className={'spinner'}>
          <SpinnerCircular
            {...props}
            color={color ?? Defaults.mainColor}
            size={getSize(size)}
            className={classNames(props.className, size === 'large' && 'spinner__large')}
          />
        </div>
      )}
    </div>
  );
};

export default SpinnerContainer;
