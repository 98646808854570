import { useEffect, useState } from 'react';

import LotsService from '../../services/LotsService';
import OperationsService from '../../services/OperationsService';
import { getItem, setItem } from '../../utils/localStorage';
import { TextEx } from '../common';
import SwitchEx from '../controls/SwitchEx';
import Txt from '../controls/Txt';

export const COOKIE_LAST_BUY_TRADING_ACTIVE = 'LAST_BUY_TRADING_ACTIVE';
export const COOKIE_LAST_SELL_TRADING_ACTIVE = 'LAST_SELL_TRADING_ACTIVE';

export let externalTradeBuyControlHandler: any = (value: boolean) => ({});
export let externalTradeSellControlHandler: any = (value: boolean) => ({});

export const TradeControl = ({
  onChange,
}: {
  onChange?: ({ is_active_buy, is_active_sell }: any) => void;
}) => {
  const [tradingBuyActive, setTradeBuyActive] = useState(
    getItem(COOKIE_LAST_BUY_TRADING_ACTIVE) === 'true',
  );
  const [tradingSellActive, setTradeSellActive] = useState(
    getItem(COOKIE_LAST_SELL_TRADING_ACTIVE) === 'true',
  );

  useEffect(() => {
    updateStatus();
    externalTradeBuyControlHandler = setTradeBuyActive;
    externalTradeSellControlHandler = setTradeSellActive;
  }, []);

  const updateStatus = () =>
    LotsService.getWalletsStatuses().then(
      ({ tradingSellActivated, tradingBuyActivated }) => {
        setTradeBuyActive(tradingBuyActivated);
        setTradeSellActive(tradingSellActivated);
        setItem(COOKIE_LAST_SELL_TRADING_ACTIVE, tradingSellActivated);
        setItem(COOKIE_LAST_BUY_TRADING_ACTIVE, tradingBuyActivated);
      },
    );

  const handleTradeSellActivatedChange = (activate: boolean) => {
    OperationsService.tradeSellActivate({ activate }, (response) => {
      if (response) {
        setItem(COOKIE_LAST_SELL_TRADING_ACTIVE, activate);
        setTradeSellActive(!!response.is_active_sell);
        onChange?.({ is_active_sell: !!response.is_active_sell });
      }
    });
  };

  const handleTradeBuyActivatedChange = (activate: boolean) => {
    OperationsService.tradeBuyActivate({ activate }, (response) => {
      if (response) {
        setItem(COOKIE_LAST_BUY_TRADING_ACTIVE, activate);
        setTradeBuyActive(!!response.is_active_buy);
        onChange?.({ is_active_buy: !!response.is_active_buy });
      }
    });
  };

  return (
    <div className="controlWrap">
      <div className="controlWrap__element">
        <TextEx className={'controlWrap__text'} size={'1.125rem'} weight={500}>
          <Txt k={`bids.trade-activated.buy.${tradingBuyActive}`} />
        </TextEx>
        <SwitchEx
          swapColors
          checked={tradingBuyActive}
          onChange={handleTradeBuyActivatedChange}
        />
      </div>
      <div className="controlWrap__element">
        <TextEx className={'controlWrap__text'} size={'1.125rem'} weight={500}>
          <Txt k={`bids.trade-activated.sell.${tradingBuyActive}`} />
        </TextEx>
        <SwitchEx
          swapColors
          checked={tradingSellActive}
          onChange={handleTradeSellActivatedChange}
        />
      </div>
    </div>
  );
};
