import { action, computed, makeAutoObservable, observable } from 'mobx';

import { toggleBodyFixed } from '../components/login/PageLogin';
import { getAuthToken, logout, setAuthToken } from '../helpers/authHeader';

type TokenListener = (token: string) => boolean;

export class Auth {
  @observable email: string | undefined = '';
  @observable showLoginDialog = false;
  @observable token: string = getAuthToken() ?? '';
  @observable tokenListeners: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @computed get isAuthorized() {
    return !!this.token;
  }

  @action setAuthorization = (token?: string) => {
    token ? setAuthToken(token) : logout();
    this.token = token ?? '';
  };

  @action openLoginDialog = (email?: string) => {
    this.email = email;
    this.showLoginDialog = true;
    toggleBodyFixed(true);
  };

  @action closeLoginDialog = () => {
    this.showLoginDialog = false;
    toggleBodyFixed(false);
  };

  @action openLoginDialogForFastDeal = (email?: string, onToken?: TokenListener) => {
    if (!!onToken && this.tokenListeners.indexOf(onToken) === -1) {
      this.tokenListeners.push(onToken);
    }
    this.openLoginDialog(email);
  };

  @action clearTokenListeners = () => {
    this.tokenListeners = [];
  };
}

export const AuthStore = new Auth();
