import React from 'react';
import styled from 'styled-components/macro';

import { calcSize, Defaults, px } from '../common';

const SwitchEx = ({
  checked,
  onChange,
  width = '3.75rem',
  left,
  right,
  top,
  bottom,
  disabled,
  swapColors = false,
  className,
}: {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  width?: number | string;
  left?: number | string;
  disabled?: boolean;
  right?: number | string;
  top?: number | string;
  bottom?: number | string;
  swapColors?: boolean;
  className?: string;
}) => (
  <SwitchRoot
    width={width}
    left={left}
    right={right}
    top={top}
    className={`${className ?? ''} ${disabled && 'disabled'}`}
    bottom={bottom}
    swapColors={swapColors}>
    <input
      type={'checkbox'}
      checked={checked}
      onChange={(event) =>
        onChange && !disabled ? onChange(event.currentTarget.checked) : undefined
      }
    />
    <span />
  </SwitchRoot>
);

const SwitchRoot = styled.label`
  position: relative;
  display: inline-block;
  height: 1.875rem;
  width: ${(props) => px(props.width)};
  min-width: ${(props) => px(props.width)};
  margin-left: ${(props) => px(props.left)};
  margin-right: ${(props) => px(props.right)};
  margin-top: ${(props) => px(props.top)};
  margin-bottom: ${(props) => px(props.bottom)};

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &.disabled {
    * {
      cursor: not-allowed;
    }
    filter: opacity(0.5);
  }

  & span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 3rem;
    background-color: ${(props) => (props.swapColors ? Defaults.mainColor : 'white')};
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
  }

  & :not(input:checked) + span {
    background-color: ${(props) => (props.swapColors ? '#dadee0' : 'white')};
  }

  & span:before {
    position: absolute;
    content: '';
    height: 1.375rem;
    width: 1.375rem;
    left: 0.275rem;
    top: 0.22rem;
    border-radius: 50%;
    background-color: ${(props) => (props.swapColors ? 'white' : '#dadee0')};
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  & input:checked + span:before {
    -webkit-transform: translateX(${(props) => calcSize(props.width, (n) => n / 2)});
    -ms-transform: translateX(${(props) => calcSize(props.width, (n) => n / 2)});
    transform: translateX(${(props) => calcSize(props.width, (n) => n / 2)});
    background-color: ${(props) => (props.swapColors ? 'white' : Defaults.mainColor)};
  }
`;

export default SwitchEx;
