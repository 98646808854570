import { action, makeAutoObservable, observable } from 'mobx';
import { Translation } from 'react-i18next';

import { t } from '../config/i18n';
import { getUserRegion, setUserRegion } from '../helpers/settings';
import { getAddress } from '../utils/api';
import { Region } from '../utils/types';
import { AuthStore, CurrencyStore } from './';
import { LanguageStore } from './Language';

const List = {
  RU: {
    id: 'RU',
    value: <Translation>{() => t('top-bar.region-list.russia')}</Translation>,
  },
  BY: {
    id: 'BY',
    value: <Translation>{() => t('top-bar.region-list.belarus')}</Translation>,
  },
  KZ: {
    id: 'KZ',
    value: <Translation>{() => t('top-bar.region-list.kazakhstan')}</Translation>,
  },
  UA: {
    id: 'UA',
    value: <Translation>{() => t('top-bar.region-list.ukraine')}</Translation>,
  },
  OTHER: {
    id: 'OTHER',
    value: <Translation>{() => t('top-bar.region-list.other')}</Translation>,
  },
};

export class Regions {
  @observable list: Region[] = Object.values(List);
  @observable region: Region = this.list[0];

  constructor() {
    makeAutoObservable(this);
  }

  @action setRegion = (region: Region) => {
    setUserRegion(region);
    this.region = region;
  };

  @action defineRegion = async () => {
    const { setFiatCurrencyByRegion, fiatCurrency } = CurrencyStore;
    const { isAuthorized } = AuthStore;

    if (fiatCurrency && isAuthorized) {
      return;
    }
    const previousRegion = getUserRegion();
    if (previousRegion) {
      setFiatCurrencyByRegion(previousRegion.id);
      this.region = previousRegion;
    } else {
      const { setLanguage } = LanguageStore;
      const data = await getAddress();
      if (data?.country) {
        setFiatCurrencyByRegion(data.country);
        this.setRegion(List[data.country] ?? List['OTHER']);
        setLanguage(List[data.country] ? 'ru' : 'en');
      } else {
        setFiatCurrencyByRegion('RU');
        this.setRegion(this.list[0]);
        setLanguage('ru');
      }
    }
  };
}

export const RegionsStore = new Regions();
