import { IErrorCallbackType, ISuccessCallbackType } from '../domain/services/common';
import {
  IDealDisputGetData,
  IDealDisputGetParams,
  IDealDisputOpenData,
  IDealDisputOpenParams,
  IFastDealDisputOpenData,
  IFastDealDisputOpenParams,
} from '../domain/services/DisputesServiceEntities';
import { getAuthHeader } from '../helpers/authHeader';
import { generalRequest } from '../utils/axiosSetup';

export default abstract class DisputesService {
  // ?? UNUSED
  static dealsDisputeGet = async (
    params: IDealDisputGetParams,
    onSuccess?: ISuccessCallbackType<IDealDisputGetData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IDealDisputGetData>({
      url: `/disputes/${params.id}`,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static dealsDisputeOpen = async (
    params: IDealDisputOpenParams,
    onSuccess?: ISuccessCallbackType<IDealDisputOpenData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IDealDisputOpenData>({
      url: '/disputes',
      method: 'POST',
      data: { deal_id: params.id },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // ?? UNUSED
  static fastDealsDisputeOpen = async (
    params: IFastDealDisputOpenParams,
    onSuccess?: ISuccessCallbackType<IFastDealDisputOpenData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IFastDealDisputOpenData>({
      url: '/disputes',
      method: 'POST',
      headers: getAuthHeader(params.token),
      data: { deal_id: params.id },
      successCallback: onSuccess,
      errorCallback: onError,
    });
}
