import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import styled from 'styled-components/macro';

import { truncNumber } from '../../helpers/utils';
import { ComponentRootEx, Defaults, px } from '../common';

const invalidChars = ['-', '+', 'e'];
const percentInvalidChars = ['e'];
export interface InputExProps {
  type: 'number' | 'text' | 'password' | 'email';
  value?: any;
  className?: string;
  minWidth?;
  width?;
  height?: number | string;
  flexGrow?: number;
  top?: any;
  bottom?: any;
  left?: any;
  fontSize?: number | string;
  fontColor?: string;
  fontFamily?: string;
  textAlign?: string;
  textDecoration?: string;
  borderColor?: string;
  backgroundColor?: string;
  borderRadius?: number | string;
  img?: React.ReactNode;
  imgWidth?: number | string;
  imgPos?: 'left' | 'right';
  placeholder?: React.ReactNode;
  hint?: string;
  onChange?: (value) => void;
  onInputClick?: (e) => void;
  onKeyPress?: (e) => void;
  onBlur?: (e) => void;
  autoFocus?: boolean;
  isPercent?: boolean;
  children?: React.ReactNode;
  hoverHint?: string;
  maxFracDigits?: number;
}

const InputEx = ({
  type,
  value,
  className,
  minWidth,
  width,
  height,
  flexGrow,
  top,
  bottom,
  left,
  fontSize,
  fontColor,
  fontFamily,
  textAlign,
  textDecoration,
  borderColor,
  backgroundColor,
  borderRadius,
  img,
  imgWidth,
  imgPos = 'right',
  placeholder,
  hint,
  onChange,
  onInputClick,
  onKeyPress,
  onBlur,
  autoFocus,
  isPercent = false,
  children,
  hoverHint,
  maxFracDigits,
}: InputExProps) => {
  const [edit, setEdit] = useState(false);

  function onChangeValue(event: ChangeEvent<HTMLInputElement>) {
    let newValue: any = event.target.value;

    const numberConversion = () => {
      if (type === 'number') {
        if (newValue === '') {
          newValue = undefined;
        }
        if (newValue !== undefined) {
          const f = Number.parseFloat(newValue);
          newValue = maxFracDigits !== undefined ? truncNumber(f, maxFracDigits) : f;
        }
      }
    };

    if ((isPercent && (newValue.match(/^-?\d+\.?\d*$/) || !newValue)) || !isPercent) {
      numberConversion();
      onChange && onChange(newValue);
    }
  }

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    const forbiddenChars = isPercent ? percentInvalidChars : invalidChars;

    event.key === 'Enter' && target.blur();

    if (type === 'number' && forbiddenChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  return (
    <InputRoot
      className={className}
      minWidth={minWidth}
      width={width}
      height={height}
      flexGrow={flexGrow}
      top={top}
      left={left}
      bottom={bottom}
      fontSize={fontSize}
      fontColor={fontColor}
      fontFamily={fontFamily}
      textAlign={textAlign}
      textDecoration={textDecoration}
      imgPos={imgPos}
      imgWidth={imgWidth}
      cursor={'default'}
      borderColor={borderColor}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      onClick={() => setEdit(true)}>
      {imgPos === 'left' && !!imgWidth ? (
        <ImgWrap width={imgWidth}>{img}</ImgWrap>
      ) : undefined}
      {!!placeholder && !edit ? (
        <Placeholder>{placeholder}</Placeholder>
      ) : (
        <input
          type={type}
          value={value}
          onKeyDown={onKeyDown}
          readOnly={!onChange}
          placeholder={hint}
          onChange={onChangeValue}
          onWheel={(e) => document.activeElement?.['blur']()}
          onBlur={(e) => {
            setEdit(false);
            onBlur && onBlur(e);
          }}
          onClick={onInputClick}
          onKeyPress={onKeyPress}
          autoFocus={autoFocus}
          width={'100%'}
          title={hoverHint}
          autoComplete={'off'}
        />
      )}
      {imgPos === 'right' && !!imgWidth ? (
        <ImgWrap borderColor={borderColor} width={imgWidth}>
          {img}
        </ImgWrap>
      ) : undefined}
      {children ? children : undefined}
    </InputRoot>
  );
};

export default InputEx;

const InputRoot = styled(ComponentRootEx)`
  overflow: hidden;
  ${(props) => (props.flexGrow ? `flex-grow: ${props.flexGrow};` : '')}
  ${(props) =>
    props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''}
    ${(props) => (props.top ? `margin-top: ${px(props.top)};` : '')}
    ${(props) => (props.bottom ? `margin-bottom: ${px(props.bottom)};` : '')}
    ${(props) => (props.left ? `margin-left: ${px(props.left)};` : '')}
    ${(props) => (props.borderRadius ? `border-radius: ${px(props.borderRadius)};` : '')}

    & input {
    width: calc(100% - ${(props) => px(props.imgWidth || '0vh')} - 1vh);
    height: 100%;
    border: unset;
    padding: 0 0.3125rem;
    margin: 0 0.3125rem 0 0.3125rem;
    /* font-family: ${(props) => props.fontFamily || 'inherit'}; */
    font-size: ${(props) => px(props.fontSize || Defaults.fontSize)};
    color: ${(props) => props.fontColor};
    background-color: inherit;
    ${(props) => (props.imgPos === 'left' ? 'padding-left: 0;' : '')}
    ${(props) => (props.imgPos === 'left' ? 'margin-left: 0;' : '')}
    ${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : '')}
    ${(props) =>
      props.textDecoration ? `text-decoration: ${props.textDecoration};` : ''}
  }

  & input:focus {
    outline: none;
  }

  & input::-webkit-inner-spin-button,
  & input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover {
    background-color: ${(props) => props.backgroundColor || 'unset'};
  }
`;
const Placeholder = styled.div`
  display: flex;
  width: 100%;
  padding: 1px 0.8125rem;
`;
const ImgWrap = styled.div`
  display: flex;
  flex-flow: column-reverse;
  flex-shrink: 0;
  height: 100%;
  ${(props) => (props.width ? `width: ${px(props.width)};` : '')}
  ${(props) => (props.borderColor ? `border-left: 1px solid ${props.borderColor};` : '')};
  justify-content: center;
  align-items: center;
`;
