import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { DEFAULT_LIMIT } from '../../../helpers/settings';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { classNames } from '../../../utils/api';
import { Defaults, TextEx } from '../../common';
import { DOTS, usePagination } from './usePagination';

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number | string) => void;
  mobileBreak?: string;
  className?: string;
  limit?: number;
  isNextPrev?: boolean;
  nextWillBeDisabled?: boolean;
  rows?: Record<string, any>[];
}

const Pagination = (props: PaginationProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: 1200px)`);
  const {
    onPageChange,
    totalPages,
    currentPage,
    limit,
    className,
    nextWillBeDisabled,
    isNextPrev,
    rows,
  } = props;
  const paginationRange = usePagination({
    currentPage,
    totalPages,
    limit,
  });

  const [nextDisabled, setNextDisabled] = useState(false);

  useEffect(() => {
    if (isNextPrev) {
      !nextWillBeDisabled && setNextDisabled(false);
      nextWillBeDisabled &&
        rows &&
        rows.length < (limit ?? DEFAULT_LIMIT) &&
        setNextDisabled(true);
    }
  }, [nextWillBeDisabled]);

  if ((currentPage === 0 || paginationRange.length < 2) && !isNextPrev) {
    return null;
  }

  const onNext = () => {
    setNextDisabled(!!nextWillBeDisabled);
    onPageChange(props.isNextPrev ? 'next' : currentPage + 1);
  };

  const onPrevious = () => {
    setNextDisabled(false);
    onPageChange(props.isNextPrev ? 'prev' : currentPage - 1);
  };

  return (
    <PaginationContainer>
      <div
        className={classNames(
          'pagination-button',
          currentPage === 1 && 'disabled',
          isMobile && 'mobile',
        )}
        onClick={onPrevious}>
        <div className="arrow left" />
        <TextEx color={Defaults.silverGray} left={'0.6rem'}>
          {t('pagination.previous')}
        </TextEx>
      </div>
      <ul className={classNames('pagination-container', className)}>
        {!props.isNextPrev &&
          paginationRange.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return (
                <li key={index + pageNumber} className="pagination-item dots">
                  &#8230;
                </li>
              );
            }

            return (
              <li
                key={pageNumber}
                className={classNames(
                  'pagination-item',
                  pageNumber === currentPage && 'selected',
                )}
                onClick={() => onPageChange(pageNumber)}>
                <span>{pageNumber}</span>
              </li>
            );
          })}
      </ul>
      <div
        className={classNames(
          'pagination-button',
          ((currentPage === totalPages && !isNextPrev) || nextDisabled) && 'disabled',
          isMobile && 'mobile',
        )}
        onClick={onNext}>
        <TextEx color={Defaults.silverGray} right={'0.6rem'}>
          {t('pagination.next')}
        </TextEx>
        <div className="arrow right" />
      </div>
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 2.5rem;

  @media (max-width: 640px) {
    margin: 1rem;
  }
`;

export default Pagination;
