import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import {
  listenNotifications,
  Notifications,
  stopListenNotifications,
} from '../../helpers/notifications';
import { getCurrentUser } from '../../helpers/settings';
import { formatNumber } from '../../helpers/utils';
import UsersService from '../../services/UsersService';
import { CurrencyStore, UserStore } from '../../store';
import { changeCurrency } from '../../utils/api';
import { Defaults } from '../common';
import SelectEx from '../controls/SelectEx';
import { currency } from '../currencies';

interface UserBalanceProps {
  height: number | string;
  authorized?: boolean;
}

const UserBalanceSelect = ({ height, authorized = false }: UserBalanceProps) => {
  const { fiatCurrency, balances, getBalances, setFiatCurrency } = CurrencyStore;
  const { updateUser } = UserStore;

  useEffect(() => {
    getBalances();
    getCurrentFiatCurrency();
    listenNotifications(handleNotifications);

    return () => stopListenNotifications(handleNotifications);
  }, []);

  const getCurrentFiatCurrency = () => {
    UsersService.profile({ id: getCurrentUser() }, (profile) => {
      const currency = profile?.currency?.toUpperCase() || 'RUB';
      setFiatCurrency(currency);
    });
  };

  const handleNotifications = (notifications: Notifications) => {
    notifications.unreadGroups > 0 &&
      Object.keys(notifications.deals).length > 0 &&
      getBalances();
  };

  const onChangeCurrency = (currency: string) => {
    changeCurrency(currency)
      .then(() => setFiatCurrency(currency, true))
      .finally(updateUser);
  };

  const options = toJS(balances).map((balance) => {
    return {
      id: balance.currency,
      value: formatNumber(balance.amount, 1) + ' ' + balance.currency,
      img: (
        <OptionImgContainer>
          {currency(balance.currency, undefined, Defaults.neroColor)}
        </OptionImgContainer>
      ),
      font: { weight: '400' },
    };
  });

  return (
    <SelectEx
      authorized={authorized}
      height={height}
      borderRadius={'24px'}
      id={fiatCurrency}
      arrowColor={Defaults.silverGray}
      backgroundColor={Defaults.whiteColor}
      options={options}
      caption={'top-bar.user-currency'}
      captionSize={'12px'}
      captionColor={Defaults.silverGray}
      onChange={(c) => onChangeCurrency(c)}
      optionPaddingLeft={'0.5rem'}
    />
  );
};

const OptionImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: ${Defaults.lightBlue};
`;

export default observer(UserBalanceSelect);
