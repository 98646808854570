import styled from 'styled-components/macro';

import { Defaults, px, TextEx } from '../../common';
import { pageFontSize } from '../../controls/Page';
import Txt from '../../controls/Txt';

export const UserInfoLine = ({
  caption,
  captionWidth = '52%',
  value,
  bottom,
  children,
}: {
  caption: string;
  bottom?;
  captionWidth?: number | string;
  value?: any;
  children?: React.ReactNode;
}) => (
  <UserInfoLineBox bottom={bottom}>
    <TextEx size={pageFontSize} width={captionWidth} color={Defaults.neroColor}>
      <Txt k={caption} />
    </TextEx>
    {value ? <TextEx size={pageFontSize}>{value}</TextEx> : undefined}
    {children}
  </UserInfoLineBox>
);

const UserInfoLineBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => px(props.bottom || 0)};
`;
