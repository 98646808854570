import { io, Socket } from 'socket.io-client';

import { getAuthToken } from '../helpers/authHeader';
import { parseJwt } from '../helpers/settings';

let socket: Socket | null = null;
const typeHandlers: Record<string, (notification: any) => void> = {};

export const WS_SIGNALS = {
  UPDATE_DEAL: 'update deal',
  UPDATE_CODEDATA: 'update codedata',
  SYSTEM_MESSAGE: 'system message',
};

export const registerHandlerOnType = (
  key: string,
  type: string,
  handler: (notification: any) => void,
) => {
  const handleByType = (notification: any) => {
    notification.type === type && handler(notification);
  };
  if (socket) {
    socket.on(key, handleByType);
  } else {
    typeHandlers[key] = handleByType;
  }
};

export const createWebsocketConnect = (
  handlers: Record<string, (notification: any) => any>,
): void => {
  const userData = parseJwt(getAuthToken());
  if (!userData) return;
  const { user_id, nickname } = userData;
  try {
    const WS_URL = process.env.REACT_APP_WS_URL || `ws://localhost:8080/`;
    socket = io(WS_URL, {
      path: '/sky-socket',
      reconnectionAttempts: 3,
      timeout: 5000,
      auth: { id: user_id, name: nickname },
    });

    Object.keys(handlers).map((key) => socket?.on(key, handlers[key]));
    Object.keys(typeHandlers).map((key) => socket?.on(key, typeHandlers[key]));
  } catch (error) {
    console.error(error);
  }
};

export const disconnectCurrentWSConnection = () => {
  socket?.close();
};
