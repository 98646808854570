import React from 'react';
import styled from 'styled-components/macro';

import BrokersService from '../services/BrokersService';
import { BankType, PaymentMethod } from '../utils/types';
import { Defaults, px, TextEx } from './common';
import IconEx from './controls/IconEx';
import { pageFontSize } from './controls/Page';

const exclusiveLimitsRUB = {
  ЮMoney: 500,
  'Пополнение телефона': 1500,
  QIWI: 1500,
};

export function getPaymentMethods(currency: string): Promise<PaymentMethod[]> {
  return new Promise<PaymentMethod[]>((resolve) => {
    BrokersService.paymentMethods(
      { currency: currency.toLowerCase() },
      (paymentMethods) => {
        resolve(paymentMethods ? sortPaymentMethods(paymentMethods) : []);
      },
    );
  });
}

export const PaymentMethodBox = ({
  paymentMethod,
  fontFamily = Defaults.fontFamily,
  fontSize = pageFontSize,
  textColor,
  imgSize,
  left,
  weight,
  mobileHidden,
}: {
  paymentMethod: BankType;
  left?;
  fontFamily?: string;
  fontSize?;
  weight?;
  textColor?: string;
  imgSize?: number | string;
  mobileHidden?: boolean;
}) => {
  const { name, logo } = paymentMethod;

  return (
    <PaymentMethodBoxRoot left={left} mobileHidden={mobileHidden}>
      {!logo && !name ? (
        <SSkeletonLine />
      ) : (
        <>
          {logo && (
            <div className={'select-icon-wrap'}>
              <IconEx src={logo} alt={'logo'} size={imgSize || fontSize} />
            </div>
          )}
          <TextEx
            left={'.8125rem'}
            size={fontSize}
            fontFamily={fontFamily}
            weight={weight}
            color={textColor}>
            {name || ''}
          </TextEx>
        </>
      )}
    </PaymentMethodBoxRoot>
  );
};

export const PaymentMethodBoxRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  padding-left: 1.4rem;
  position: relative;
  .select-icon-wrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 2rem;
    left: 0;
    img {
      max-width: 100%;
    }
  }
  ${(props) => (props.left ? `margin-left: ${px(props.left)};` : '')}

  @media (max-width: 1133px) {
    display: ${(props) => (props.mobileHidden ? 'none' : 'flex')};
  }
`;

export const SSkeletonPulse = styled.div`
  display: inline-block;
  height: 20px;
  width: 6rem;
  border-radius: 6px;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

const SSkeletonLine = styled(SSkeletonPulse)`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  left: -1.4rem;
  &::before {
    content: '\00a0';
  }
`;

const sorting = [
  'QIWI',
  'Сбербанк',
  'Тинькофф',
  'С карты на карту',
  'Яндекс.Деньги',
  'Любой банк РФ',
  'Пополнение телефона',
  'Mastercard',
  'Альфа-Банк',
  'ВТБ 24',
  'VISA',
  'Райффайзен',
  'МТС-Банк',
  'PAYEER',
  'Почта банк',
  'WebMoney',
  'Промсвязьбанк',
  'Другие способы',
  'Наличные в ATM',
  'Рокетбанк',
];

function sortPaymentMethods(source: PaymentMethod[]): PaymentMethod[] {
  const source2 = [...source];

  const sourceMap = {};
  source.forEach((s) => (sourceMap[s.name] = s));

  const result: PaymentMethod[] = [];
  for (const t of sorting) {
    if (sourceMap?.[t]) {
      result.push(sourceMap[t]);
      source2.splice(source2.indexOf(sourceMap[t]), 1);
    }
  }

  return result.concat(source2);
}
