import React from 'react';
import styled from 'styled-components/macro';

import { getPage, setPage } from '../../config/history';
import { t } from '../../config/i18n';
import { getUserIsAdmin } from '../../helpers/settings';
import { cl } from '../../helpers/utils';
import { AuthStore } from '../../store';
import { Defaults, Logout, Spacer, TextEx } from '../common';
import ButtonEx from '../controls/ButtonEx';
import IconEx from '../controls/IconEx';
import Logo from '../controls/Logo';
import { MenuItem, MenuItemCaption, MenuItemsList } from '../controls/NavMenu';
import Txt from '../controls/Txt';
import LangSelect from './LangSelect';
import UserBalanceSelect from './UserBalanceSelect';
import UserNotifications from './UserNotifications';
import UserProfile from './UserProfile';

export const buttonHeight = '3.50rem';

const imgSize = '20px';

const items = [
  'wallet',
  'deals',
  'p2p',
  'bids',
  'add',
  'transfer',
  'exchange',
  'maintenance',
  'history',
  'merchant',
];

const itemsFoot = ['wallet', 'deals', 'p2p', 'bids', 'exchange', 'history'];

const TopBar: React.FC = () => {
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState(getPage().name);
  const { isAuthorized, setAuthorization } = AuthStore;
  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    const body = document.querySelector('body');
    if (body) body.classList.toggle('fixedd');
  };

  const itemClick = (item: string) => {
    setPage(item);
    setActiveItem(item);
    toggleMenu();
  };

  const itemClickFoot = (event, item: string) => {
    event.preventDefault();
    setPage(item);
    setActiveItem(item);
  };

  const checkItem = (item: string): boolean => {
    if (item === 'admin') {
      return getUserIsAdmin();
    }
    return true;
  };

  return (
    <TopBarRoot className={'TopBar'}>
      {!!isAuthorized && (
        <>
          <TopBarBox>
            <UserProfile tabletHidden height={buttonHeight} />
            <Spacer className={'tablet-hidden'} width={'1.5rem'} />
            <UserBalanceSelect height={buttonHeight} />
            <Spacer width={'1.5rem'} />
          </TopBarBox>
          <TopBarBox>
            <LangSelect authorized height={buttonHeight} className="mobile-hidden" />
            <Spacer width={'1.5rem'} className={'mobile-hidden'} />
            <ButtonEx
              className="mobile-hidden"
              hint={'maintenance.title'}
              img={'/ico/top/support.svg'}
              height={buttonHeight}
              width={buttonHeight}
              rounded
              boxShadow={'0px 4px 16px rgba(0, 0, 0, 0.08)'}
              backgroundColor={Defaults.whiteColor}
              imgSize={imgSize}
              onClick={(event) => {
                itemClickFoot(event, 'maintenance');
              }}
            />
            <Spacer width={'1.5rem'} className="mobile-hidden" />
            <UserNotifications imgSize={imgSize} />
            <Spacer width={'1.5rem'} />
            <TopBarButton
              className={'tablet-hidden'}
              img={<Logout color={Defaults.grayColor} />}
              hint={'top-bar.logout'}
              onClick={() => setAuthorization()}
            />
            <ButtonEx
              className="tablet-show"
              hint={'top-bar.burger'}
              height={buttonHeight}
              width={buttonHeight}
              boxShadow={'0px 4px 16px rgba(0, 0, 0, 0.08)'}
              backgroundColor={Defaults.whiteColor}
              imgSize={imgSize}
              onClick={toggleMenu}>
              <div className={`toggleMenu ${cl(showMobileMenu, 'on')} `}>
                <span />
              </div>
            </ButtonEx>
          </TopBarBox>
          <div
            className={`MobileMenu MobileMenuToggle  tablet-show ${cl(
              showMobileMenu,
              ' on',
            )} `}>
            <div className="MobileMenu__head">
              <Logo onClick={() => setPage()} />
              <div
                className={`toggleMenu tablet-show ${cl(showMobileMenu, 'on')} `}
                onClick={toggleMenu}>
                <span />
              </div>
            </div>
            <div className="MobileMenu__body">
              <div className="mobileProfile mobile-user-container">
                <UserProfile height={buttonHeight} />
              </div>
              <div className="mobileMenu__wrapper">
                <MenuItemsList className={'MenuItemsList'}>
                  {items.map((item, i) =>
                    checkItem(item) ? (
                      <MenuItem
                        key={i}
                        href={item}
                        className={'MenuItem ' + (item === activeItem ? 'active' : '')}
                        title={t(`${item}.title`)}
                        onClick={() => itemClick(item)}>
                        <div className="icon-wrap">
                          <IconEx
                            src={`/ico/nav/${item}.svg`}
                            alt={item}
                            size={'28px'}
                            width={'28px'}
                            borderRadius={'0'}
                          />
                        </div>
                        <MenuItemCaption>
                          <TextEx uppercase>
                            <Txt k={`${item}.title`} />
                          </TextEx>
                        </MenuItemCaption>
                      </MenuItem>
                    ) : undefined,
                  )}
                </MenuItemsList>
              </div>
              <div className="border-bottom">
                <LangSelect height={buttonHeight} />
              </div>
              <div className="border-bottom">
                <TopBarButton
                  mobileMode
                  img={'/ico/top/settings.svg'}
                  hint={'top-bar.settings'}
                  left={'1.5rem'}
                  onClick={() => itemClick('settings')}
                />
              </div>
              <div className="border-bottom">
                <TopBarButton
                  mobileMode
                  img={<Logout color={Defaults.mainColor} />}
                  hint={'top-bar.logout'}
                  left={'1.5rem'}
                  onClick={() => setAuthorization()}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </TopBarRoot>
  );
};

const TopBarButton = ({
  img,
  hint,
  left,
  right,
  className,
  onClick,
  mobileMode,
}: {
  img;
  mobileMode?: boolean;
  hint?: string;
  left?;
  right?: number | string;
  className?: string;
  onClick: () => void;
}) => (
  <ButtonEx
    className={className}
    img={img}
    imgSize={imgSize}
    hint={hint}
    width={buttonHeight}
    height={buttonHeight}
    backgroundColor={mobileMode ? '' : Defaults.whiteColor}
    boxShadow={mobileMode ? '' : '2px 3px 32px rgba(168, 184, 200, 0.32)'}
    left={left}
    right={right}
    onClick={onClick}
  />
);

const TopBarRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  margin-top: 1.875rem;
`;

const TopBarBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export default TopBar;
