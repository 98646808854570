import React from 'react';
import { useTranslation } from 'react-i18next';

import { CurrencyStore } from '../../store';
import { Cryptos, CurrencyBox } from '../currencies';
import SelectEx, { SelectExOptionProps, SelectExProps } from './SelectEx';

interface CurrenciesSelectProps extends SelectExProps {
  withAll?: boolean;
  restriction?: string[];
  currencyLeft?: string | number;
  textAlign?: string;
}

const CurrenciesSelect = (props: CurrenciesSelectProps) => (
  <SelectEx {...props} options={getCurrenciesOptions(props)} />
);

const getCurrenciesOptions = (props: CurrenciesSelectProps): SelectExOptionProps[] => {
  const { t } = useTranslation();

  const { fiatCurrenciesList } = CurrencyStore;
  let currencies = [...Cryptos, ...fiatCurrenciesList];

  if (props.restriction) {
    const restrictionSet = new Set(props.restriction);
    currencies = currencies.filter((c) => restrictionSet.has(c));
  }

  const options: SelectExOptionProps[] = currencies.map((currency) => ({
    id: currency,
    img: <CurrencyBox c={currency} left={props.currencyLeft} />,
    imgPosition: 'left' as const,
    textAlign: props.textAlign,
  }));

  if (props.withAll) {
    options.unshift({ id: 'all', value: t('currency.all') });
  }

  return options;
};

export default CurrenciesSelect;
