import { CssBaseline } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { lazy, useEffect } from 'react';
import { Route, Router, Switch } from 'react-router';
import styled from 'styled-components/macro';

import history, { setPage } from '../config/history';
import { AuthStore, CurrencyStore } from '../store';
const PageAdd = lazy(() => import('./add'));
const PageBid = lazy(() => import('./bid/PageBid'));
const PageBids = lazy(() => import('./bids'));
import { changeClass, Notification, NotificationsContainer } from './common';
import NavMenu from './controls/NavMenu';
const PageDeals = lazy(() => import('./deals'));
const PageDeal = lazy(() => import('./deals/PageDeal'));
const ExchangeCoin = lazy(() => import('./exchange'));
const PageHistory = lazy(() => import('./history'));
const PageMaintenance = lazy(() => import('./maintenance'));
const PageAuthorizationData = lazy(() => import('./maintenance/AuthorizationData'));
const PageSettings = lazy(() => import('./maintenance/PageSettings'));
const PageNotifications = lazy(() => import('./notifications/PageNotifications'));
const PageP2P = lazy(() => import('./p2p'));
const PageNotFound = lazy(() => import('./PageNotFound'));
const PagePromocodes = lazy(() => import('./promocodes/PagePromocodes'));
const PageReferrals = lazy(() => import('./referrals/PageReferrals'));
const PageSkyPay = lazy(() => import('./skypay'));
import TopBar from './topBar/TopBar';
const PageTransfer = lazy(() => import('./transfer'));
import SpinnerContainer from './universalComponents/SpinnerContainer';
const PageUserProfile = lazy(() => import('./user/PageUserProfile'));
const PageWallet = lazy(() => import('./wallet'));

const Layout = () => {
  const { isAuthorized, token } = AuthStore;
  const { fiatCurrenciesList } = CurrencyStore;

  useEffect(() => {
    changeClass('html', 'personal-page', true);
    changeClass('body', 'personal-page', true);
    return () => {
      changeClass('body', 'personal-page', false);
      changeClass('html', 'personal-page', false);
    };
  }, []);

  if (!token) {
    setPage();
    return <></>;
  }

  if (!fiatCurrenciesList.length) {
    return <SpinnerContainer loading={true} children={null} size="large" />;
  }

  return (
    <LayoutRoot>
      <CssBaseline />
      <NavMenu open={isAuthorized} />
      {/*
            NEXT BLOCK WITH TWO INPUTS PREVENTS AUTOFILL SUGGESTIONS WHEN USER AUTHORIZED (IN WHOLE APP)
            https://stackoverflow.com/questions/2530/how-do-you-disable-browser-autocomplete-on-web-form-field-input-tags
            */}
      {isAuthorized && (
        <>
          <input type="text" style={{ display: 'none' }} />
          <input type="password" style={{ display: 'none' }} />
        </>
      )}
      <Main className={'mainBlock'}>
        <TopBar />
        <NotificationsContainer id={'notifications-container'}>
          <Notification id={'notification'} />
        </NotificationsContainer>
        <Router history={history}>
          <Switch>
            <Route exact path={'/wallet'} component={PageWallet} />
            <Route exact path={'/wallet/:path'} component={PageWallet} />
            <Route exact path={'/deals'} component={PageDeals} />
            <Route exact path={'/deals/:id'} component={PageDeal} />
            <Route exact path={'/p2p'} component={PageP2P} />
            <Route exact path={'/bids'} component={PageBids} />
            <Route exact path={'/bids/:id'} component={PageBid} />
            <Route exact path={'/add'} component={PageAdd} />
            <Route exact path={'/transfer'} component={PageTransfer} />
            <Route exact path={'/exchange'} component={ExchangeCoin} />
            <Route exact path={'/maintenance'} component={PageMaintenance} />
            <Route exact path={'/settings'} component={PageSettings} />
            <Route exact path={'/settings/auth-data'} component={PageAuthorizationData} />
            <Route exact path={'/history'} component={PageHistory} />
            <Route exact path={'/notifications'} component={PageNotifications} />
            <Route exact path={'/users/:id'} component={PageUserProfile} />
            <Route exact path={'/promocodes'} component={PagePromocodes} />
            <Route exact path={'/referrals'} component={PageReferrals} />
            <Route exact path={'/merchant'} component={PageSkyPay} />
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      </Main>
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f1f1f1;
`;
const Main = styled.main``;

export default observer(Layout);
