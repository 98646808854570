import MapJsonToExchangeHistory from '../data/mapTo/mapJsonToExchangeHistory';
import { ExchangeHistory } from '../domain/ExchangeHistory';
import {
  ICommonParams,
  IErrorCallbackType,
  ISuccessCallbackType,
} from '../domain/services/common';
import {
  IExchangeData,
  IExchangeHistoryData,
  IExchangeHistoryParams,
  IExchangeParams,
  IExchangeRateData,
} from '../domain/services/ExchangesServiceEntities';
import { generalRequest } from '../utils/axiosSetup';

export default abstract class ExchangesService {
  // OK
  static exchange = async (
    params: IExchangeParams,
    onSuccess?: ISuccessCallbackType<IExchangeData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IExchangeData>({
      url: '/exchanges',
      method: 'POST',
      data: params.body,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static exchangeRate = async (
    onSuccess?: ISuccessCallbackType<IExchangeRateData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IExchangeRateData>({
      url: '/exchanges/settings',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static exchangeHistory = async (
    params: IExchangeHistoryParams,
    onSuccess?: ISuccessCallbackType<IExchangeHistoryData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IExchangeHistoryData>({
      url: '/exchanges',
      params: params,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static mappedExchangeHistory = async (config: IExchangeHistoryParams & ICommonParams) =>
    new Promise<{ data: ExchangeHistory[]; totalPages: number }>((resolve) =>
      ExchangesService.exchangeHistory({ ...config }, (response) => {
        if (response)
          resolve({
            data: response.data.map(MapJsonToExchangeHistory),
            totalPages: response.total_pages,
          });
      }),
    );
}
