import { isNil } from 'lodash';

import { MerchantFilters } from '../components/skypay';
import {
  Operation as MerchantOperation,
  OperationV2 as MerchantOperationV2,
} from '../components/skypay/OperationsList';
import {
  ICommonParams,
  IErrorCallbackType,
  ISuccessCallbackType,
} from '../domain/services/common';
import {
  IMerchantCreateData,
  IMerchantCreateParams,
  IMerchantGetData,
  IMerchantOperationsParams,
  IMerchantResetKeys,
  IMerchantResetTokenData,
  IMerchantStatisticsExportParams,
  IMerchantUpdateData,
  IMerchantUpdateParams,
  ISkyPayStatisticsExportData,
} from '../domain/services/MerchantServiceEntities';
import { ISkyPayOperationsV2Data } from '../domain/services/OperationsServiceEntities';
import { getAuthHeader } from '../helpers/authHeader';
import { getUTCTime, parseDate, toLowerCase } from '../helpers/utils';
import { generalRequest } from '../utils/axiosSetup';
import { operationTypes } from '../utils/types';

export default abstract class MerchantService {
  // OK
  static get = async (
    onSuccess?: ISuccessCallbackType<IMerchantGetData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IMerchantGetData>({
      url: '/merchant',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static create = async (
    params: IMerchantCreateParams,
    onSuccess?: ISuccessCallbackType<IMerchantCreateData>,
    onError?: IErrorCallbackType,
  ) => {
    const formData = new FormData();
    !isNil(params.name) && formData.append('name', params.name);
    !isNil(params.website) && formData.append('website', params.website);
    !isNil(params.imageUrl) && formData.append('image', params.imageUrl);
    !isNil(params.callbackUrl) && formData.append('callback_url', params.callbackUrl);
    !isNil(params.callbackUrlSale) &&
      formData.append('callback_url_sale', params.callbackUrlSale);
    !isNil(params.callbackUrlSale) &&
      formData.append('callback_url_cpay', params.callbackUrlCpay);
    !isNil(params?.callbackSafe) &&
      formData.append('callback_safe', (!!params?.callbackSafe)?.toString());

    return generalRequest<IMerchantCreateData>({
      url: '/merchant',
      method: 'POST',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data', ...getAuthHeader() },
      successCallback: onSuccess,
      errorCallback: onError,
    });
  };

  // OK
  static update = async (
    params: IMerchantUpdateParams,
    onSuccess?: ISuccessCallbackType<IMerchantUpdateData>,
    onError?: IErrorCallbackType,
  ) => {
    const formData = new FormData();
    !isNil(params.name) && formData.append('name', params.name);
    !isNil(params.website) && formData.append('website', params.website);
    !isNil(params.imageUrl) && formData.append('image', params.imageUrl);
    !isNil(params.callbackType) &&
      formData.append('callback_type', params?.callbackType?.toString());
    !isNil(params.callbackUrl) && formData.append('callback_url', params.callbackUrl);
    !isNil(params.callbackUrlSale) &&
      formData.append('callback_url_sale', params.callbackUrlSale);
    !isNil(params.callbackUrlSale) &&
      formData.append('callback_url_cpay', params.callbackUrlCpay);
    !isNil(params?.callbackSafe) &&
      formData.append('callback_safe', (!!params?.callbackSafe)?.toString());

    return generalRequest<IMerchantUpdateData>({
      url: '/merchant',
      method: 'PATCH',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data', ...getAuthHeader() },
      successCallback: onSuccess,
      errorCallback: onError,
    });
  };

  // OK
  static resetToken = async (
    onSuccess?: ISuccessCallbackType<IMerchantResetTokenData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IMerchantResetTokenData>({
      url: '/merchant/reset-token',
      method: 'POST',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static resetEncryptionKeys = async (
    onSuccess?: ISuccessCallbackType<IMerchantResetKeys>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IMerchantResetTokenData>({
      url: '/merchant/reset-callback-keys',
      method: 'POST',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static merchantStatisticsExport = async (
    params: IMerchantStatisticsExportParams,
    onSuccess?: ISuccessCallbackType<ISkyPayStatisticsExportData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IMerchantStatisticsExportParams>({
      url: '/merchants/payments-statistics',
      params: {
        year: params.year,
        month: params.month,
        symbol: params.symbol,
        currency: params.currency,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static operations = async (
    params: IMerchantOperationsParams,
    onSuccess?: ISuccessCallbackType<ISkyPayOperationsV2Data>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<ISkyPayOperationsV2Data>({
      url: `/merchant/operations/${params.operationType}`,
      params: {
        _from: params.from,
        _to: params.to,
        symbol: params.symbol,
        currency: params.subCurrency,
        ...(!!params.id && { operation_id: params.id }),
        action: operationTypes[params.operationType],
        op_type: 2,
        page: params.page,
        offset: params.offset,
        limit: params.limit,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static mappedMerchantOperations = async (config: MerchantFilters & ICommonParams) =>
    new Promise<MerchantOperationV2[]>((resolve) =>
      MerchantService.operations(
        {
          from: getUTCTime(config.dateFrom),
          to: getUTCTime(config.dateTo),
          symbol: config.currency === 'all' ? undefined : toLowerCase(config.currency),
          subCurrency:
            config.subCurrency === 'all' ? undefined : toLowerCase(config.subCurrency),
          operationType: config.operationType,
          id: config.id,
          page: config.page,
          offset: config.offset,
          limit: config.limit,
        },
        (response) => {
          if (response) {
            const newOperationsState: MerchantOperationV2[] = [];
            for (const o of response) {
              newOperationsState.push({
                id: o.id,
                date: parseDate(o?.created_at),
                amount: o?.amount,
                symbol: o?.symbol?.toUpperCase() || '',
                currency: o?.currency?.toUpperCase() || '',
                isCurrencyAmount: o?.is_currency_amount,
                target: config.operationType,
                status: o?.status,
              });
            }
            resolve(newOperationsState);
          }
        },
        () => {
          resolve([]);
        },
      ),
    );
}
