import {
  IFastDealBrokersData,
  IFastDealBrokersParams,
  IPaymentMethodsData,
  IPaymentMethodsParams,
  ISkyPayBrokersData,
  ISkyPayBrokersParams,
} from '../domain/services/BrokersServiceEntities';
import { IErrorCallbackType, ISuccessCallbackType } from '../domain/services/common';
import { generalRequest } from '../utils/axiosSetup';
import { BankType } from '../utils/types';

export default abstract class BrokersService {
  // OK
  static paymentMethods = async (
    params: IPaymentMethodsParams,
    onSuccess?: ISuccessCallbackType<IPaymentMethodsData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IPaymentMethodsData>({
      url: '/brokers',
      params: params,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static getBrokerById = async (
    brokerId?: string,
    onSuccess?: ISuccessCallbackType<BankType>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<BankType>({
      url: `/brokers/${brokerId}`,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static fastDealBrokers = async (
    params: IFastDealBrokersParams,
    onSuccess?: ISuccessCallbackType<IFastDealBrokersData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IFastDealBrokersData>({
      url: '/brokers',
      params: {
        ...params,
        fast_deal: true,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static skyPayBrokers = async (
    params: ISkyPayBrokersParams,
    onSuccess?: ISuccessCallbackType<ISkyPayBrokersData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<ISkyPayBrokersData>({
      url: '/brokers',
      params: {
        ...params,
        sky_pay: true,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });
}
