/* tslint:disable:max-line-length */
import React from 'react';
import styled from 'styled-components/macro';

import CurrenciesService from '../services/CurrenciesService';
import { Defaults, px } from './common';

export const currenciesColors = {
  BTC: '#ff8c37',
  ETH: '#3b7693',
  ETC: '#00b881',
  USDT: '#41BA97',
  RUB: '#F3F4F5',
  USD: '#F3F4F5',
  EUR: '#F3F4F5',
  UAH: '#F3F4F5',
  INR: '#F3F4F5',
  BYN: '#F3F4F5',
  KZT: '#F3F4F5',
  UZS: '#F3F4F5',
  TJS: '#F3F4F5',
  AZN: '#F3F4F5',
};

export const colorByTypeDeal = {
  sale: '#0098DB',
  purchase: '#22D37A',
};

export const cryptoMapRC = {
  BTC: 'BTC',
  ETH: 'ETH(ERC20)',
  USDT: 'USDT(TRC20)',
};

const currenciesSigns = {
  RUB: <>&#8381;</>,
  USD: <>&#36;</>,
  EUR: <>&#8364;</>,
};

interface CurrencyProps {
  size?: any;
  fill?: string;
  background?: string;
  top?: number;
  left?: number;
}

const defaultSize = '1.2rem';

export const BTC = ({
  size = defaultSize,
  fill = currenciesColors.BTC,
  background,
  top,
  left,
}: CurrencyProps) => (
  <svg
    viewBox={'0 0 475 475'}
    style={{
      width: size,
      height: size,
      fill,
      background,
      marginTop: top,
      marginLeft: left,
    }}>
    <g>
      <path
        d="M395.655,249.236c-11.037-14.272-27.692-24.075-49.964-29.403c28.362-14.467,40.826-39.021,37.404-73.666
		c-1.144-12.563-4.616-23.451-10.424-32.68c-5.812-9.231-13.655-16.652-23.559-22.266c-9.896-5.621-20.659-9.9-32.264-12.85
		c-11.608-2.95-24.935-5.092-39.972-6.423V0h-43.964v69.949c-7.613,0-19.223,0.19-34.829,0.571V0h-43.97v71.948
		c-6.283,0.191-15.513,0.288-27.694,0.288l-60.526-0.288v46.824h31.689c14.466,0,22.936,6.473,25.41,19.414v81.942
		c1.906,0,3.427,0.098,4.57,0.288h-4.57v114.769c-1.521,9.705-7.04,14.562-16.558,14.562H74.747l-8.852,52.249h57.102
		c3.617,0,8.848,0.048,15.703,0.134c6.851,0.096,11.988,0.144,15.415,0.144v72.803h43.977v-71.947
		c7.992,0.195,19.602,0.288,34.829,0.288v71.659h43.965v-72.803c15.611-0.76,29.457-2.18,41.538-4.281
		c12.087-2.101,23.653-5.379,34.69-9.855c11.036-4.47,20.266-10.041,27.688-16.703c7.426-6.656,13.559-15.13,18.421-25.41
		c4.846-10.28,7.943-22.176,9.271-35.693C410.979,283.882,406.694,263.514,395.655,249.236z M198.938,121.904
		c1.333,0,5.092-0.048,11.278-0.144c6.189-0.098,11.326-0.192,15.418-0.288c4.093-0.094,9.613,0.144,16.563,0.715
		c6.947,0.571,12.799,1.334,17.556,2.284s9.996,2.521,15.701,4.71c5.715,2.187,10.28,4.853,13.702,7.993
		c3.429,3.14,6.331,7.139,8.706,11.993c2.382,4.853,3.572,10.42,3.572,16.7c0,5.33-0.855,10.185-2.566,14.565
		c-1.708,4.377-4.284,8.042-7.706,10.992c-3.423,2.951-6.951,5.523-10.568,7.71c-3.613,2.187-8.233,3.949-13.846,5.28
		c-5.612,1.333-10.513,2.38-14.698,3.14c-4.188,0.762-9.421,1.287-15.703,1.571c-6.283,0.284-11.043,0.478-14.277,0.572
		c-3.237,0.094-7.661,0.094-13.278,0c-5.618-0.094-8.897-0.144-9.851-0.144v-87.65H198.938z M318.998,316.331
		c-1.813,4.38-4.141,8.189-6.994,11.427c-2.858,3.23-6.619,6.088-11.28,8.559c-4.66,2.478-9.185,4.473-13.559,5.996
		c-4.38,1.529-9.664,2.854-15.844,4c-6.194,1.143-11.615,1.947-16.283,2.426c-4.661,0.477-10.226,0.856-16.7,1.144
		c-6.469,0.28-11.516,0.425-15.131,0.425c-3.617,0-8.186-0.052-13.706-0.145c-5.523-0.089-9.041-0.137-10.565-0.137v-96.505
		c1.521,0,6.042-0.093,13.562-0.287c7.521-0.192,13.656-0.281,18.415-0.281c4.758,0,11.327,0.281,19.705,0.856
		c8.37,0.567,15.413,1.42,21.128,2.562c5.708,1.144,11.937,2.902,18.699,5.284c6.755,2.378,12.23,5.28,16.419,8.706
		c4.188,3.432,7.707,7.803,10.561,13.134c2.861,5.328,4.288,11.42,4.288,18.274C321.712,307.104,320.809,311.95,318.998,316.331z"
      />
    </g>
  </svg>
);

export const ETH = ({
  size = defaultSize,
  fill = currenciesColors.ETH,
  background,
}: CurrencyProps) => (
  <svg viewBox={'-116 0 512 512'} style={{ width: size, height: size, fill, background }}>
    <g>
      <path d="m140.28125 333.582031-140.28125-66.734375 140.28125 245.152344 140.285156-245.152344zm0 0" />
      <path d="m265.289062 217.117188-125.007812-217.117188-125.148438 217.367188 125.148438-59.367188zm0 0" />
      <path d="m25.980469 245.535156 114.300781 54.140625 114.492188-54.230469-114.492188-54.136718zm0 0" />
    </g>
  </svg>
);

export const ETC = ({
  size = defaultSize,
  fill = currenciesColors.ETC,
  background,
}: CurrencyProps) => (
  <svg
    viewBox={'-116 0 512 512.001'}
    style={{ width: size, height: size, fill, background }}>
    <g>
      <path d="m140.28125 333.582031-140.28125-66.734375 140.28125 245.152344 140.285156-245.152344zm-66.417969 1.738281 66.417969 31.597657 66.421875-31.597657-66.421875 116.070313zm0 0" />
      <path d="m275.851562 235.460938-135.570312-235.460938-135.566406 235.460938 135.566406 64.214843zm-135.570312-175.132813 77.324219 134.296875-77.324219-36.625-77.320312 36.625zm0 130.980469 79.230469 37.53125-79.230469 37.527344-79.226562-37.527344zm0 0" />
    </g>
  </svg>
);

export const USDT = ({
  size = defaultSize,
  fill = currenciesColors.USDT,
  background,
}: CurrencyProps) => (
  <svg viewBox={'0 0 150 150'} style={{ width: size, height: size, fill, background }}>
    <g>
      <path d="M90.2462 52.3909V34.0085H132.282V6H17.8164V34.0085H59.8576V52.3761C25.6903 53.9454 0 60.7122 0 68.8184C0 76.9246 25.7027 83.6914 59.8576 85.2706V144.152H90.256V85.2657C124.362 83.6914 150 76.9296 150 68.8308C150 60.7319 124.362 53.9701 90.256 52.3959L90.2462 52.3909ZM90.256 80.2784V80.2635C89.3985 80.3179 84.9919 80.5823 75.1804 80.5823C67.3361 80.5823 61.8175 80.3599 59.8725 80.2586V80.2833C29.6866 78.9463 7.15474 73.6896 7.15474 67.3998C7.15474 61.1101 29.6891 55.8608 59.8725 54.5213V75.0464C61.8496 75.1823 67.5042 75.5159 75.3089 75.5159C84.683 75.5159 89.396 75.1255 90.261 75.0464V54.5213C120.388 55.8633 142.867 61.1249 142.867 67.3924C142.867 73.6599 120.378 78.924 90.261 80.266" />
    </g>
  </svg>
);

export const USDTROUNDED = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#59B783" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2029 20.7515V20.7493C22.0795 20.7582 21.4435 20.7965 20.0245 20.7965C18.8916 20.7965 18.0941 20.7627 17.8137 20.7493V20.7526C13.4525 20.5604 10.1973 19.7993 10.1973 18.8888C10.1973 17.9793 13.4525 17.2183 17.8137 17.0227V19.9949C18.0986 20.0152 18.9152 20.0635 20.0436 20.0635C21.3975 20.0635 22.0761 20.0073 22.2029 19.9961V17.0249C26.555 17.2194 29.8023 17.9804 29.8023 18.8888C29.8023 19.7993 26.555 20.5581 22.2029 20.7515ZM22.2029 16.7158V14.056H28.2757V10H11.7408V14.056H17.8137V16.7146C12.8782 16.9417 9.1665 17.922 9.1665 19.0956C9.1665 20.2692 12.8782 21.2484 17.8137 21.4766V30H22.2029V21.4743C27.1305 21.2473 30.8332 20.2681 30.8332 19.0956C30.8332 17.9231 27.1305 16.944 22.2029 16.7158Z"
      fill="white"
    />
  </svg>
);

export const ETHROUNDED = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#527591" />
    <path
      d="M14.1665 20.8333L19.9998 10L25.8332 20.8333L19.9998 24.1667L14.1665 20.8333Z"
      fill="white"
    />
    <path
      d="M14.1665 21.6667L19.9998 25L25.8332 21.6667L19.9998 30L14.1665 21.6667Z"
      fill="white"
    />
  </svg>
);

export const BTCROUNDED = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#FF9500" />
    <path
      d="M16.6715 10V13.3333H13.3335V15.5556C13.3335 15.5556 14.6082 15.5333 14.5879 15.5556C15.2703 15.5452 15.4877 16.0401 15.5583 16.3701V23.7037C15.5322 23.9123 15.5545 24.4296 15.0775 24.4444C15.0995 24.4637 13.3335 24.4444 13.3335 24.4444V26.6667H15.7466C16.1547 26.6519 16.2773 26.6667 16.6715 26.6667V30H18.8968V26.6667H19.9863L20.0326 30H22.2348L22.2116 26.6667C25.0413 26.4615 26.6854 25.1088 26.6667 22.7778C26.6854 20.6334 24.9682 19.4516 23.9037 19.4444C24.968 19.4486 25.5727 18.2927 25.5727 16.6667C25.5727 14.4444 24.037 13.3333 22.2348 13.3333V10H20.0094V13.3333C19.5665 13.3333 19.3465 13.317 18.8968 13.3333V10H16.6715ZM18.8968 15.5556C21.1221 15.5556 22.2348 15.5556 22.2348 17.2222C22.2348 18.8889 21.1221 18.8889 18.8968 18.8889V15.5556ZM18.8968 21.1111C21.8633 21.1112 23.3477 20.926 23.3474 22.7778C23.3477 24.6298 21.8633 24.4444 18.8968 24.4444V21.1111Z"
      fill="white"
    />
  </svg>
);

export const RUB = ({
  size = defaultSize,
  fill = currenciesColors.RUB,
  background,
}: CurrencyProps) => (
  <svg
    viewBox={'-90 -80 690 690'}
    style={{ width: size, height: size, fill, background }}>
    <g>
      <path
        d="M34.786,428.963h81.158v69.572c0,3.385,1.083,6.156,3.262,8.322c2.173,2.18,4.951,3.27,8.335,3.27h60.502
			c3.14,0,5.857-1.09,8.152-3.27c2.295-2.166,3.439-4.938,3.439-8.322v-69.572h182.964c3.377,0,6.156-1.076,8.334-3.256
			c2.18-2.178,3.262-4.951,3.262-8.336v-46.377c0-3.365-1.082-6.156-3.262-8.322c-2.172-2.18-4.957-3.27-8.334-3.27H199.628v-42.754
			h123.184c48.305,0,87.73-14.719,118.293-44.199c30.551-29.449,45.834-67.49,45.834-114.125c0-46.604-15.283-84.646-45.834-114.125
			C410.548,14.749,371.116,0,322.812,0H127.535c-3.385,0-6.157,1.089-8.335,3.256c-2.173,2.179-3.262,4.969-3.262,8.335v227.896
			H34.786c-3.384,0-6.157,1.145-8.335,3.439c-2.172,2.295-3.262,5.012-3.262,8.151v53.978c0,3.385,1.083,6.158,3.262,8.336
			c2.179,2.18,4.945,3.256,8.335,3.256h81.158v42.754H34.786c-3.384,0-6.157,1.09-8.335,3.27c-2.172,2.166-3.262,4.951-3.262,8.322
			v46.377c0,3.385,1.083,6.158,3.262,8.336C28.629,427.887,31.401,428.963,34.786,428.963z M199.628,77.179h115.938
			c25.6,0,46.248,7.485,61.953,22.46c15.697,14.976,23.549,34.547,23.549,58.691c0,24.156-7.852,43.733-23.549,58.691
			c-15.705,14.988-36.354,22.473-61.953,22.473H199.628V77.179z"
      />
    </g>
  </svg>
);

export const USD = ({
  size = defaultSize,
  fill = currenciesColors.USD,
  background,
}: CurrencyProps) => (
  <svg
    viewBox={'-30 -40 570 570'}
    style={{ width: size, height: size, fill, background }}>
    <g>
      <path
        d="M370.5,254c-14.667-11.333-36.667-20.667-66-28l-23-5v-97c0.667,0.667,1.333,1,2,1s1,0.333,1,1
			c19.334,9.333,29.668,25.333,31,48h77c-1.333-40-16.333-70.667-45-92c-18.667-14-40.667-23.333-66-28V0h-71v52
			c-33.333,4-59.667,14.667-79,32c-25.333,22.667-38,51.334-38,86c0,37.333,13,65,39,83c14,10,40,19.333,78,28v104
			c-14-4-24.667-10.333-32-19c-8-9.333-13-22-15-38h-76c0,39.333,14.333,70.333,43,93c20.667,16,47.333,26.333,80,31v40h71v-39
			c34.667-4.667,62.333-15.667,83-33c26.667-23.333,40-52.667,40-88C404.5,298,393.167,272,370.5,254z M210.5,204
			c-11.333-3.333-19.333-6.333-24-9c-12.667-6.667-19-17-19-31c0-15.333,6.333-27,19-35c6.667-4,14.667-7.333,24-10V204z M293.5,383
			c-3.333,1.333-7.333,2.333-12,3v-89c12.667,4,22.333,8,29,12c11.333,7.333,17,17.333,17,30C327.5,360.333,316.167,375,293.5,383z"
      />
    </g>
  </svg>
);

export const EUR = ({
  size = defaultSize,
  fill = currenciesColors.EUR,
  background,
}: CurrencyProps) => (
  <svg
    viewBox={'-20 -50 590 590'}
    style={{ width: size, height: size, fill, background }}>
    <g>
      <path
        d="M444.288,429.288c-5.4-13.2-10.9-26.4-16.9-39.4c-5.3-11.6-12.1-15-24.8-12.1c-16.1,3.7-31.9,8.6-48,11.9
		c-31.1,6.5-62.3,7.1-93-2.6c-38.5-12.1-59-40-71.6-76h104.4c8.2,0,14.8-6.6,14.8-14.8v-32.9c0-8.2-6.6-14.8-14.8-14.8h-114.4
		c0-9.2-0.1-18,0-26.8h114.4c8.2,0,14.8-6.6,14.8-14.8v-32.9c0-8.2-6.6-14.8-14.8-14.8h-100c0-0.4,0-0.8,0.2-1
		c12-27.3,29.5-49.2,58.2-60.6c33.4-13.2,67.5-12.9,101.9-5.8c16.3,3.3,32.3,8.3,48.6,12c11.9,2.7,18.8-0.8,23.9-11.9
		c5.9-12.8,11.3-25.8,16.7-38.9c5.1-12.3,2.1-21-9.5-27.8c-2.9-1.7-5.9-3.1-9-4.3c-48.2-18.8-97.9-25.8-149.2-17.6
		c-36.1,5.8-69.8,18.2-98.9,40.8c-36.7,28.4-60.5,65.9-74.3,110l-1.7,5.1h-51.4c-8.2,0-14.8,6.6-14.8,14.8v32.9
		c0,8.2,6.6,14.8,14.8,14.8h40.5c0,9,0,17.7,0,26.8h-40.5c-8.2,0-14.8,6.6-14.8,14.8v32.9c0,8.2,6.6,14.8,14.8,14.8h48.8
		c3.7,12,6.8,24.2,11.5,35.7c24.7,59.6,66.1,102,128.4,122.2c51.5,16.7,103.4,16.2,155.3,1.9c13.5-3.7,26.9-8.5,39.7-14.4
		C445.988,450.788,449.188,441.188,444.288,429.288z"
      />
    </g>
  </svg>
);

export const UAH = ({
  size = defaultSize,
  fill = currenciesColors.UAH,
  background,
}: CurrencyProps) => (
  <svg viewBox={'0 -1 325 475'} style={{ width: size, height: size, fill, background }}>
    <g>
      <path
        d="M226.8,197.6l-49.1,39.7h144.2v43.9h-187c-12.5,9.4-19.9,20.9-19.9,35.5c0,25.1,19.9,38.7,55.4,38.7
		c30.3,0,56.4-14.6,77.3-44.9l57.5,38.7c-31.3,48.1-87.8,72.1-141.1,72.1c-70,0-122.2-33.4-122.2-88.8c0-17.8,6.3-36.6,18.8-51.2
		H3.2v-43.9h91.9l50.2-39.7H3.2v-43.9h186c13.6-12.5,19.9-24,19.9-37.6c0-21.9-19.9-37.6-50.2-37.6c-29.3,0-54.3,16.7-72.1,44.9
		L31.4,85.8c30.3-48.1,79.4-72.1,132.7-72.1c72.1,0,119.1,37.6,119.1,88.8c0,18.8-6.3,37.6-16.7,51.2h55.4v43.9H226.8z"
      />
    </g>
  </svg>
);

export const INR = ({
  size = defaultSize,
  fill = currenciesColors.INR,
  background,
}: CurrencyProps) => (
  <svg viewBox={'40 0 130 300'} style={{ width: size, height: size, fill, background }}>
    <g>
      <path d="M153 23h41l15-23H55L40 23h26c27 0 52 2 62 25H55L40 71h91v1c0 17-14 43-60 43H48v22l90 113h41L85 133c39-2 75-24 80-62h29l15-23h-45c-1-9-5-18-11-25z" />
    </g>
  </svg>
);

export const BYN = ({
  size = '1.8rem',
  fill = currenciesColors.BYN,
  background,
}: CurrencyProps) => (
  <svg
    viewBox={'0 -240 1024 1224'}
    style={{ width: size, height: size, fill, background }}>
    <g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)">
      <path
        d="M640 5120 l0 -4271 1548 4 c1388 3 1562 5 1690 20 425 51 716 131
                1017 277 237 115 422 248 601 431 377 389 566 887 590 1554 33 904 -300 1631
                -935 2040 -159 103 -420 213 -641 272 -34 9 -31 10 65 32 683 159 1084 655
                1177 1456 16 140 16 541 0 665 -35 263 -95 476 -192 675 -177 364 -457 639
                -845 830 -280 137 -555 213 -955 262 -119 14 -317 17 -1627 20 l-1493 4 0
                -4271z m2961 3297 c635 -96 974 -375 1090 -897 61 -276 41 -655 -46 -900 -128
                -356 -400 -585 -817 -685 -245 -59 -216 -58 -1220 -62 l-928 -4 0 1286 0 1286
                908 -4 c770 -3 923 -6 1013 -20z m164 -3514 c372 -60 627 -171 822 -357 256
                -242 383 -589 400 -1091 31 -940 -350 -1475 -1152 -1615 -207 -36 -322 -40
                -1247 -40 l-908 0 0 1566 0 1565 978 -4 c936 -3 982 -4 1107 -24z"
      />
      <path
        d="M10425 7403 c-395 -27 -798 -162 -1074 -360 -234 -168 -458 -430
                -618 -720 l-32 -58 -1 498 0 497 -475 0 -475 0 0 -3205 0 -3205 475 0 474 0 4
                1852 c3 1671 5 1867 20 1998 122 1034 562 1613 1327 1747 139 25 502 24 625 0
                129 -27 257 -70 359 -122 49 -25 91 -44 93 -41 3 3 1 245 -3 538 l-9 533 -65
                12 c-194 36 -429 49 -625 36z"
      />
    </g>
  </svg>
);

export const AZN = ({
  size = defaultSize,
  fill = currenciesColors.INR,
  background,
}: CurrencyProps) => (
  <svg
    style={{ width: size, height: size, fill: 'none', background }}
    viewBox="0 0 22 18">
    <path
      d="M21 18C21 18 21 2.5 11 2.5C1 2.5 1 18 1 18M11 0V16"
      stroke={fill}
      strokeWidth="2"
    />
  </svg>
);

export const TJS = ({
  size = defaultSize,
  fill = currenciesColors.INR,
  background,
}: CurrencyProps) => (
  <svg style={{ width: size, height: size, fill, background }} viewBox="0 0 21 18">
    <path d="M11.8571 1V0H10.8571V1H11.8571ZM12.1848 1L13.16 0.778727L12.9833 0H12.1848V1ZM15.9286 17.5L14.9534 17.7213L16.9048 17.7166L15.9286 17.5ZM20 1H21V0H20V1ZM19.5887 1V0H18.7862L18.6124 0.78344L19.5887 1ZM12.8571 18V1H10.8571V18H12.8571ZM11.8571 2H12.1848V0H11.8571V2ZM11.2095 1.22127L14.9534 17.7213L16.9038 17.2787L13.16 0.778727L11.2095 1.22127ZM21 18V1H19V18H21ZM20 0H19.5887V2H20V0ZM18.6124 0.78344L14.9523 17.2834L16.9048 17.7166L20.5649 1.21656L18.6124 0.78344ZM0.508929 4.58872C0.508929 6.11375 1.14383 7.17211 2.06639 7.93113C2.91643 8.63048 4.02593 9.08017 4.93013 9.46984C5.90518 9.89004 6.69966 10.2605 7.27016 10.7895C7.78399 11.2659 8.14286 11.8983 8.14286 12.9627H10.1429C10.1429 11.3355 9.54749 10.1736 8.62995 9.32287C7.76909 8.52472 6.65508 8.03539 5.72166 7.63313C4.71737 7.20033 3.91839 6.86493 3.33707 6.38666C2.82826 5.96805 2.50893 5.45627 2.50893 4.58872H0.508929ZM2.50893 4.58872C2.50893 3.66125 2.8445 3.0404 3.28929 2.64213C3.75193 2.22786 4.39585 1.99998 5.0714 2C5.74696 2.00002 6.39089 2.22793 6.85355 2.64222C7.29836 3.04053 7.63393 3.6614 7.63393 4.58885H9.63393C9.63393 3.12379 9.07887 1.95026 8.18774 1.15228C7.31447 0.370292 6.17715 2.68817e-05 5.07145 1.19209e-07C3.96576 -2.65837e-05 2.82842 0.370184 1.95513 1.15215C1.06398 1.95011 0.508929 3.12365 0.508929 4.58872H2.50893ZM0 12.9626C0 14.5827 0.622902 15.8698 1.61108 16.7408C2.58022 17.5951 3.84276 18 5.07143 18C6.3001 18 7.56263 17.5951 8.53178 16.7408C9.51994 15.8698 10.1429 14.5827 10.1429 12.9627H8.14286C8.14286 14.0341 7.74791 14.7657 7.2093 15.2405C6.65165 15.732 5.87847 16 5.07143 16C4.26438 16 3.49121 15.732 2.93357 15.2405C2.39495 14.7657 2 14.0341 2 12.9626H0Z" />
  </svg>
);

export const UZS = ({
  size = defaultSize,
  fill = currenciesColors.INR,
  background,
}: CurrencyProps) => (
  <svg style={{ width: size, height: size, fill, background }} viewBox="0 0 15 18">
    <path d="M13 3.51984V4.51984H15V3.51984H13ZM15 1.32778V0.327778H13V1.32778H15ZM15 3.51984V1.32778H13V3.51984H15ZM0.508929 4.58873C0.508929 6.20764 1.35176 7.27268 2.44006 7.99285C3.4598 8.66765 4.79006 9.10226 5.92696 9.49633C7.13896 9.91644 8.17416 10.3022 8.91921 10.8578C9.59873 11.3645 10 11.9825 10 12.9627H12C12 11.2513 11.2149 10.0749 10.1148 9.25453C9.08031 8.48305 7.74273 8.00898 6.58197 7.60663C5.3461 7.17825 4.30359 6.82777 3.54376 6.32496C2.85248 5.86751 2.50893 5.36241 2.50893 4.58873H0.508929ZM2.50893 4.58873C2.50893 3.75198 2.883 3.14461 3.49147 2.71242C4.1297 2.25909 5.03775 1.99998 5.99998 2C6.96222 2.00002 7.87027 2.25915 8.50852 2.7125C9.11701 3.14472 9.49107 3.7521 9.49107 4.58883H11.4911C11.4911 3.03306 10.7424 1.84604 9.66671 1.08198C8.6208 0.339055 7.28332 2.0206e-05 6.00002 0C4.71672 -2.02656e-05 3.37923 0.338972 2.33331 1.08188C1.25763 1.84593 0.508929 3.03294 0.508929 4.58873H2.50893ZM0 12.9626C0 14.6634 0.808059 15.9623 1.97939 16.8031C3.12113 17.6226 4.58736 18 6 18C7.41265 18 8.87887 17.6225 10.0206 16.803C11.1919 15.9623 12 14.6634 12 12.9627H10C10 13.9534 9.55807 14.6732 8.8544 15.1782C8.12113 15.7046 7.08735 16 6 16C4.91264 16 3.87887 15.7046 3.14561 15.1783C2.44194 14.6732 2 13.9534 2 12.9626H0Z" />
  </svg>
);

export const KZT = ({
  size = defaultSize,
  fill = currenciesColors.KZT,
  background,
}: CurrencyProps) => (
  <svg viewBox={'1 1 710 530'} style={{ width: size, height: size, fill, background }}>
    <g>
      <path d="M 700 150 L 700 250 L 400 250 L 400 950 L 300 950 L 300 250 L 0 250 L 0 150 L 700 150 M -3 101 V 2 H 700 V 101 Z" />
    </g>
  </svg>
);

export const CryptoSigns = {
  BTC: 'BTC',
  USDT: 'USDT',
  ETH: 'ETH',
};
export const Cryptos = [CryptoSigns.BTC, CryptoSigns.ETH, CryptoSigns.USDT];

export const Currencies = {
  BTC,
  BTC_ROUNDED: BTCROUNDED,
  ETH,
  ETH_ROUNDED: ETHROUNDED,
  ETC,
  USDT,
  USDT_ROUNDED: USDTROUNDED,
  RUB,
  USD,
  EUR,
  UAH,
  INR,
  BYN,
  KZT,
  UZS,
  TJS,
  AZN,
};

export const currency = (
  name: string,
  size?: number | string,
  fill?: string,
  background?: string,
) => {
  const c = Currencies[name];
  return c ? c({ size, fill, background }) : undefined;
};

export const currencyColor = (name: string): string => currenciesColors[name];
export const currencySign = (name: string): React.ReactNode => currenciesSigns[name];
export const isCrypto = (name: string): boolean =>
  Cryptos.filter((crypto) => name === crypto).length > 0;

export const CurrencyBox = ({
  c,
  size = '2.875rem',
  charSize = '1.4rem',
  left,
  borderRadius,
}: {
  c: string;
  size?;
  charSize?;
  left?;
  borderRadius?: number | string;
}) => (
  <CurrencyBoxRoot
    size={size}
    background={currencyColor(c)}
    left={left}
    borderRadius={borderRadius}>
    {currency(c, charSize, Cryptos.includes(c) ? 'white' : 'black')}
  </CurrencyBoxRoot>
);

export function isAllowedCrypto(crypto: string): boolean {
  for (const c of Cryptos) {
    if (crypto === c) {
      return true;
    }
  }
  return false;
}

const CurrencyBoxRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 ${(props) => px(props.size)};
  width: ${(props) => px(props.size)};
  height: ${(props) => px(props.size)};
  margin-left: ${(props) => px(props.left || '-0.675rem')};
  border-radius: ${(props) => px(props.borderRadius || Defaults.borderRadius)};
  background-color: ${(props) => props.background};
`;
