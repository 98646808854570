import React from 'react';
import styled from 'styled-components/macro';

import { px } from '../common';

export const LockIcon = ({ size, fill }: { size: number | string; fill: string }) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 512 512'} fill={fill}>
    <path
      d="M400,188h-36.037v-82.23c0-58.322-48.449-105.77-108-105.77c-59.551,0-108,47.448-108,105.77V188H112
			c-33.084,0-60,26.916-60,60v204c0,33.084,26.916,60,60,60h288c33.084,0,60-26.916,60-60V248C460,214.916,433.084,188,400,188z
			M187.963,105.77c0-36.266,30.505-65.77,68-65.77s68,29.504,68,65.77V188h-136V105.77z M420,452c0,11.028-8.972,20-20,20H112
			c-11.028,0-20-8.972-20-20V248c0-11.028,8.972-20,20-20h288c11.028,0,20,8.972,20,20V452z"
    />
    <path
      d="M256,286c-20.435,0-37,16.565-37,37c0,13.048,6.76,24.51,16.963,31.098V398c0,11.045,8.954,20,20,20
            c11.045,0,20-8.955,20-20v-43.855C286.207,347.565,293,336.08,293,323C293,302.565,276.435,286,256,286z"
    />
  </svg>
);

export const CalendarClockIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 4.99998V3.99998C14 3.64636 13.8595 3.30722 13.6095 3.05717C13.3594 2.80712 13.0203 2.66665 12.6667 2.66665H3.33333C2.97971 2.66665 2.64057 2.80712 2.39052 3.05717C2.14048 3.30722 2 3.64636 2 3.99998V13.3333C2 13.6869 2.14048 14.0261 2.39052 14.2761C2.64057 14.5262 2.97971 14.6666 3.33333 14.6666H5.66667M10.6667 1.33331V3.99998M5.33333 1.33331V3.99998M2 6.66665H5.33333M11.6667 11.6666L10.6667 10.8333V9.33331M14.6667 10.6666C14.6667 11.7275 14.2452 12.7449 13.4951 13.4951C12.7449 14.2452 11.7275 14.6666 10.6667 14.6666C9.6058 14.6666 8.58839 14.2452 7.83824 13.4951C7.08809 12.7449 6.66667 11.7275 6.66667 10.6666C6.66667 9.60578 7.08809 8.58837 7.83824 7.83822C8.58839 7.08807 9.6058 6.66665 10.6667 6.66665C11.7275 6.66665 12.7449 7.08807 13.4951 7.83822C14.2452 8.58837 14.6667 9.60578 14.6667 10.6666Z"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BitcointGraphIcon = ({ width }: { width?: number | string }) => (
  <svg
    width={width}
    height="48"
    viewBox="0 0 310 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3965_12230)">
      <path
        d="M-81.2335 40.8079L-81.4999 40.9487V41.25V48V48.5H-80.9999H391H391.5V48V15.75V15.5509L391.363 15.4063L385.685 9.40632L385.56 9.27456L385.38 9.25341L374.734 8.00341L374.565 7.98356L374.418 8.07102L367.66 12.1176L358.709 2.90164L358.428 2.61177L358.085 2.82623L351.807 6.75717L342.001 7.25063L341.829 7.25925L341.699 7.37109L335.463 12.7406L325.884 12.2586L319.621 7.35627L319.528 7.28322L319.412 7.25983L309.609 5.28683L303.323 -0.371624L303.18 -0.5H302.988H291.632H291.285L291.164 -0.175551L286.353 12.6543L278.98 10.7656L278.574 10.6616L278.401 11.0432L272.889 23.1759L264.056 21.2613L263.982 21.2453L263.907 21.2519L255.543 21.9884L247.159 17.3133L246.857 17.1448L246.593 17.3682L239.732 23.1678L230.79 19.2913L230.602 19.2097L230.41 19.2838L223.509 21.9577L211.635 16.545L211.261 16.3749L211.021 16.7078L199.188 33.1342L192.458 30.2894L192.072 30.1265L191.844 30.4773L184.304 42.0668L172.654 34.8253L172.622 34.8056L172.587 34.7907L166.199 32.0407L166.02 31.9635L165.836 32.0284L158.238 34.7045L135.011 22.0608L134.833 21.9642L134.638 22.0181L124.701 24.7681L124.593 24.798L124.508 24.8711L118.371 30.1553L111.57 26.8016L111.359 26.6975L111.144 26.7937L103.587 30.1816L93.1696 23.3322L92.9954 23.2177L92.7915 23.2608L85.8309 24.7318L78.1557 22.0284L77.7817 21.8967L77.5682 22.2308L71.3598 31.9497L61.7515 30.2576L61.6061 30.232L61.4701 30.2894L54.5778 33.2026L48.3916 30.2974L48.1002 30.1606L47.855 30.3693L39.5077 37.4746L30.5018 36.2545L30.3975 36.2404L30.2963 36.2696L22.7127 38.455L15.0987 33.3351L14.8904 33.195L14.6536 33.2784L6.3188 36.2141L-0.6007 33.2894L-0.835548 33.1902L-1.05381 33.322L-15.7761 42.2115L-24.7969 39.7674L-24.9675 39.7212L-25.1292 39.7924L-33.4654 43.4627L-41.0864 40.7784L-41.2707 40.7135L-41.4502 40.7907L-47.6057 43.4407L-55.9046 38.5688L-56.0219 38.5H-56.1578H-66.7089L-72.2984 36.2852L-72.5125 36.2003L-72.7162 36.3079L-81.2335 40.8079Z"
        fill="url(#paint0_linear_3965_12230)"
        fillOpacity="0.4"
        stroke="#FF9500"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3965_12230"
        x1="155"
        y1="-14"
        x2="155"
        y2="48"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9500" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_3965_12230">
        <rect width="309.333" height="48" fill="white" transform="translate(0.333374)" />
      </clipPath>
    </defs>
  </svg>
);

export const EthereumGraphIcon = ({ width }: { width?: number | string }) => (
  <svg
    width={width}
    height="48"
    viewBox="0 0 310 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3965_12244)">
      <path
        d="M392.9 40.8079L393.167 40.9487V41.25V48V48.5H392.667H-79.3334H-79.8334V48V15.75V15.5509L-79.6965 15.4063L-74.0183 9.40632L-73.8936 9.27456L-73.7135 9.25341L-63.0669 8.00341L-62.8978 7.98356L-62.7517 8.07102L-55.9937 12.1176L-47.0424 2.90164L-46.7609 2.61177L-46.4184 2.82623L-40.1407 6.75717L-30.3338 7.25063L-30.1626 7.25925L-30.0327 7.37109L-23.7963 12.7406L-14.2175 12.2586L-7.95435 7.35627L-7.86102 7.28322L-7.74484 7.25983L2.05786 5.28683L8.34415 -0.371624L8.48676 -0.5H8.67865H20.035H20.3815L20.5032 -0.175551L25.3141 12.6543L32.6869 10.7656L33.0929 10.6616L33.2662 11.0432L38.7775 23.1759L47.6103 21.2613L47.6845 21.2453L47.7601 21.2519L56.1242 21.9884L64.5073 17.3133L64.8094 17.1448L65.0736 17.3682L71.9344 23.1678L80.8768 19.2913L81.065 19.2097L81.2563 19.2838L88.1576 21.9577L100.032 16.545L100.405 16.3749L100.645 16.7078L112.478 33.1342L119.209 30.2894L119.594 30.1265L119.823 30.4773L127.363 42.0668L139.013 34.8253L139.045 34.8056L139.079 34.7907L145.467 32.0407L145.647 31.9635L145.831 32.0284L153.429 34.7045L176.656 22.0608L176.834 21.9642L177.029 22.0181L186.965 24.7681L187.073 24.798L187.158 24.8711L193.296 30.1553L200.097 26.8016L200.308 26.6975L200.522 26.7937L208.08 30.1816L218.497 23.3322L218.671 23.2177L218.875 23.2608L225.836 24.7318L233.511 22.0284L233.885 21.8967L234.099 22.2308L240.307 31.9497L249.915 30.2576L250.061 30.232L250.197 30.2894L257.089 33.2026L263.275 30.2974L263.567 30.1606L263.812 30.3693L272.159 37.4746L281.165 36.2545L281.269 36.2404L281.37 36.2696L288.954 38.455L296.568 33.3351L296.776 33.195L297.013 33.2784L305.348 36.2141L312.267 33.2894L312.502 33.1902L312.721 33.322L327.443 42.2115L336.464 39.7674L336.634 39.7212L336.796 39.7924L345.132 43.4627L352.753 40.7784L352.937 40.7135L353.117 40.7907L359.272 43.4407L367.571 38.5688L367.689 38.5H367.825H378.376L383.965 36.2852L384.179 36.2003L384.383 36.3079L392.9 40.8079Z"
        fill="url(#paint0_linear_3965_12244)"
        fillOpacity="0.4"
        stroke="#0098DB"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3965_12244"
        x1="156.667"
        y1="-14"
        x2="156.667"
        y2="48"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#0098DB" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_3965_12244">
        <rect width="309.333" height="48" fill="white" transform="translate(0.333374)" />
      </clipPath>
    </defs>
  </svg>
);

export const TetherGraphIcon = ({ width }: { width?: number | string }) => (
  <svg
    width={width}
    height="48"
    viewBox="0 0 310 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3965_12258)">
      <path
        d="M392.9 40.8079L393.167 40.9487V41.25V48V48.5H392.667H-79.1743H-79.6743V48V15.75V15.551L-79.5375 15.4064L-73.8613 9.40638L-73.7366 9.27458L-73.5564 9.25341L-62.9133 8.00341L-62.7442 7.98355L-62.5981 8.07106L-55.8425 12.1175L-46.8944 2.9017L-46.6129 2.61169L-46.2703 2.82627L-39.9948 6.75717L-30.1912 7.25063L-30.02 7.25925L-29.8901 7.37115L-23.6559 12.7406L-14.0805 12.2586L-7.81955 7.35632L-7.7262 7.28324L-7.60999 7.25984L2.18936 5.28685L8.47348 -0.371568L8.61612 -0.5H8.80804H20.1606H20.5071L20.6288 -0.1755L25.438 12.6542L32.8081 10.7656L33.2142 10.6616L33.3875 11.0433L38.8969 23.1758L47.7265 21.2614L47.8007 21.2453L47.8763 21.2519L56.2376 21.9884L64.6177 17.3134L64.92 17.1448L65.1842 17.3682L72.0425 23.1678L80.9817 19.2913L81.17 19.2096L81.3613 19.2838L88.2603 21.9577L100.131 16.5451L100.504 16.3748L100.744 16.7078L112.573 33.1341L119.301 30.2895L119.686 30.1264L119.915 30.4774L127.452 42.0667L139.098 34.8254L139.13 34.8056L139.165 34.7908L145.551 32.0408L145.73 31.9635L145.915 32.0284L153.51 34.7045L176.729 22.0609L176.906 21.9642L177.101 22.0181L187.035 24.7681L187.143 24.798L187.228 24.8711L193.363 30.1552L200.161 26.8016L200.372 26.6975L200.587 26.7938L208.142 30.1816L218.556 23.3323L218.73 23.2177L218.934 23.2608L225.892 24.7318L233.565 22.0284L233.939 21.8966L234.152 22.2309L240.358 31.9497L249.963 30.2576L250.109 30.232L250.245 30.2895L257.135 33.2026L263.319 30.2975L263.61 30.1605L263.855 30.3693L272.2 37.4745L281.202 36.2545L281.307 36.2404L281.408 36.2696L288.989 38.455L296.6 33.3351L296.809 33.195L297.046 33.2784L305.377 36.2141L312.294 33.2895L312.529 33.1901L312.748 33.322L327.465 42.2115L336.483 39.7674L336.653 39.7212L336.815 39.7924L345.148 43.4627L352.766 40.7784L352.951 40.7135L353.13 40.7908L359.284 43.4406L367.58 38.5688L367.697 38.5H367.833H378.38L383.968 36.2852L384.182 36.2003L384.386 36.3079L392.9 40.8079Z"
        fill="url(#paint0_linear_3965_12258)"
        fillOpacity="0.8"
        stroke="#59B783"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3965_12258"
        x1="156.746"
        y1="-14"
        x2="156.746"
        y2="48"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#59B783" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_3965_12258">
        <rect width="309.333" height="48" fill="white" transform="translate(0.333374)" />
      </clipPath>
    </defs>
  </svg>
);

export const AnonymousIcon = ({
  size,
  fill,
}: {
  size: number | string;
  fill: string;
}) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 512 512'} fill={fill}>
    <path
      d="M418.88,344.043l-54.869-19.093c-3.989-1.408-8.491-0.277-11.371,2.859c-26.901,29.355-61.227,45.525-96.64,45.525
			s-69.739-16.171-96.64-45.525c-2.901-3.136-7.36-4.245-11.371-2.859L93.12,344.043C37.419,363.413,0,416.043,0,475.008v15.659
			c0,5.888,4.779,10.667,10.667,10.667h490.667c5.888,0,10.667-4.779,10.667-10.667v-15.659
			C512,416.021,474.581,363.392,418.88,344.043z M490.667,480H21.333v-4.992c0-49.899,31.659-94.443,78.784-110.827l48.384-16.832
			c30.251,30.571,68.181,47.317,107.499,47.317s77.248-16.747,107.499-47.317l48.384,16.832
			c47.125,16.384,78.784,60.928,78.784,110.827V480z"
    />
    <path
      d="M383.936,141.44c0.043-0.917,0.064-1.856,0.064-2.773c0-70.592-57.408-128-128-128s-128,57.408-128,128
			c0,0.917,0.021,1.856,0.064,2.773c-12.715,6.955-21.397,26.389-21.397,50.56c0,29.675,13.099,52.224,30.677,53.291
			C156.736,309.547,203.435,352,256,352s99.264-42.453,118.656-106.709c17.579-1.067,30.677-23.616,30.677-53.291
			C405.333,167.829,396.651,148.395,383.936,141.44z M372.672,223.531c-2.901-1.792-6.464-2.091-9.643-0.811
			c-3.136,1.28-5.483,3.989-6.315,7.296C341.781,290.219,301.312,330.667,256,330.667s-85.781-40.448-100.715-100.651
			c-0.832-3.307-3.499-5.76-6.656-7.061c-1.173-0.491-2.453-0.704-3.755-0.704c-2.176,0-4.395,0.619-6.208,1.749
			C136,224,128,212.651,128,192c0-19.563,7.189-30.763,10.197-31.915c0.107,0.021,0.235,0.043,0.32,0.043
			c3.136,0.235,6.357-0.811,8.619-3.072c2.261-2.283,3.392-5.461,3.051-8.64l-0.299-2.667c-0.256-2.347-0.555-4.672-0.555-7.083
			C149.333,79.851,197.184,32,256,32s106.667,47.851,106.667,106.667c0,2.411-0.299,4.736-0.555,7.061l-0.299,2.667
			c-0.32,3.2,0.789,6.379,3.051,8.64c2.283,2.283,5.461,3.349,8.619,3.093c0.085-0.021,0.213-0.021,0.32-0.043
			C376.811,161.237,384,172.437,384,192C384,212.651,376.171,224,372.672,223.531z"
    />
    <path
      d="M256,96c-25.131,0-42.667,17.536-42.667,42.667c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.667-10.667
			c0-19.243,14.912-21.333,21.333-21.333s21.333,2.091,21.333,21.333c0,17.899-3.947,26.069-16.235,33.557
			c-23.723,14.507-27.691,33.685-26.304,42.731c0.811,5.248,5.291,8.853,10.453,8.853c0.427,0,0.875-0.043,1.323-0.085
			c5.696-0.661,9.813-5.931,9.344-11.669c-0.021-0.448-0.704-11.221,16.32-21.611c18.773-11.477,26.432-26.475,26.432-51.776
			C298.667,113.536,281.131,96,256,96z"
    />
    <circle cx="245.333" cy="256" r="10.667" />
  </svg>
);

export const ShieldIcon = ({ size, fill }: { size: number | string; fill: string }) => (
  <ShieldIconSvg size={size} viewBox={'0 0 512 512'} fill={fill}>
    <path
      d="M461.144,60.883L260.312,0.633c-2.809-0.844-5.808-0.844-8.62,0L50.858,60.883c-6.345,1.903-10.69,7.743-10.69,14.367
			v220.916c0,28.734,11.632,58.148,34.573,87.425c17.522,22.36,41.762,44.813,72.048,66.736
			c50.877,36.828,100.975,59.42,103.083,60.363c1.95,0.873,4.039,1.31,6.129,1.31c2.089,0,4.179-0.436,6.129-1.31
			c2.108-0.943,52.205-23.535,103.082-60.363c30.285-21.923,54.525-44.376,72.047-66.736c22.941-29.276,34.573-58.69,34.573-87.425
			V75.25C471.833,68.626,467.489,62.786,461.144,60.883z M441.833,296.166c0,50.852-51.023,98.534-93.826,129.581
			c-38.374,27.833-77.291,47.583-92.005,54.678c-14.714-7.095-53.632-26.845-92.006-54.678
			c-42.804-31.047-93.828-78.729-93.828-129.581V86.41l185.833-55.75l185.832,55.75V296.166z"
    />
  </ShieldIconSvg>
);

export const SearchBitcoinIcon = ({
  size,
  fill,
}: {
  size: number | string;
  fill: string;
}) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 512 512'} fill={fill}>
    <path d="M392.876,359.415l-33.564,33.564,71.631,71.632a23.734,23.734,0,0,0,33.565-33.565Z" />
    <rect
      x="347.496"
      y="330.717"
      width="14"
      height="47.353"
      transform="translate(-146.764 354.466) rotate(-45)"
    />
    <path d="M249.834,242.5A26.52,26.52,0,0,0,223.4,216H184v53h39.4A26.52,26.52,0,0,0,249.834,242.5Z" />
    <path d="M249.834,176A26.1,26.1,0,0,0,223.4,150H184v52h39.4A26.1,26.1,0,0,0,249.834,176Z" />
    <path d="M348.911,209.6c0-76.762-62.451-139.212-139.212-139.212S70.488,132.835,70.488,209.6,132.938,348.808,209.7,348.808,348.911,286.358,348.911,209.6ZM199,298V283H188v15a7,7,0,0,1-14,0V283H159.861a7,7,0,0,1,0-14H170V150H159.861a7,7,0,0,1,0-14H174V120a7,7,0,0,1,14,0v16h11V120a7,7,0,0,1,14,0v16h10.4a40.143,40.143,0,0,1,22.712,73.442A40.218,40.218,0,0,1,223.4,283H213v15a7,7,0,0,1-14,0Z" />
    <path d="M378.853,209.6c0-93.272-75.882-169.154-169.154-169.154S40.546,116.325,40.546,209.6,116.428,378.75,209.7,378.75,378.853,302.868,378.853,209.6Zm-322.365,0c0-84.481,68.73-153.212,153.211-153.212S362.911,125.116,362.911,209.6,294.18,362.808,209.7,362.808,56.488,294.078,56.488,209.6Z" />
  </svg>
);

export const SuccessIcon = ({ size, fill }: { size: number | string; fill: string }) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 594 594'} fill={fill}>
    <path
      d="M448.8,161.925l-35.7-35.7l-160.65,160.65l35.7,35.7L448.8,161.925z M555.899,126.225l-267.75,270.3l-107.1-107.1
			l-35.7,35.7l142.8,142.8l306-306L555.899,126.225z M0,325.125l142.8,142.8l35.7-35.7l-142.8-142.8L0,325.125z"
    />
  </svg>
);

const ShieldIconSvg = styled.svg`
  width: ${(props) => px(props.size)};
  height: ${(props) => px(props.size)};
  background: 50% url('/logo.png') no-repeat;
  background-size: 25%;
`;
