import React from 'react';
import styled from 'styled-components/macro';

import { getPage, onPageChange, setPage } from '../../config/history';
import { onLanguageChange, t } from '../../config/i18n';
import { classNames } from '../../utils/api';
import { Defaults, px, Spacer, TextEx } from '../common';
import IconEx from './IconEx';
import Logo from './Logo';
import Txt from './Txt';

interface NavMenuProps {
  open: boolean;
  minimized?: boolean;
  width?: number;
}

interface State {
  activeItem: string;
  minimized: boolean;
}

const items = [
  'wallet',
  'deals',
  'p2p',
  'bids',
  'add',
  'transfer',
  'exchange',
  'history',
  'merchant',
  'settings',
];

const itemHeight = 64;
const fontSize = '16px';

export default class NavMenu extends React.Component<NavMenuProps> {
  public state: State = {
    activeItem: getPage().name,
    minimized: false,
  };

  componentDidMount(): void {
    onLanguageChange(() => this.setState({}));
    onPageChange((page) => this.setActiveItem(page.name));
  }

  setActiveItem = (activeItem: string) => this.setState({ activeItem });

  itemClick = (event, item: string) => {
    event.preventDefault();
    setPage(item);
    this.setActiveItem(item);
  };

  public render(): React.ReactNode {
    const { open, width } = this.props;
    const { activeItem, minimized } = this.state;
    if (!open) {
      return <></>;
    }

    return (
      <MenuRoot className={minimized ? 'minimized' : ''} id={'side-menu'}>
        <div className="menu-root-inner">
          <Logo onClick={() => setPage('wallet')} fontSize={'26px'} />
          <MenuItemsList>
            {items.map((item, i) => (
              <MenuItem
                key={i}
                className={classNames(
                  item === activeItem ? 'active' : '',
                  i == items.length - 1 && 'settings',
                )}
                title={minimized ? t(`${item}.title`) : ''}
                href={`/${item}`}
                onClick={(event) => this.itemClick(event, item)}>
                <IconEx
                  src={`/ico/nav/${item}.svg`}
                  alt={item}
                  size={'28px'}
                  width={'28px'}
                  borderRadius={'0'}
                />
                <MenuItemCaption
                  className={'hidden-mobile ' + (minimized ? 'minimized' : '')}>
                  <TextEx size={fontSize} uppercase>
                    <Txt k={`${item}.title`} />
                  </TextEx>
                </MenuItemCaption>
              </MenuItem>
            ))}
            <HoverSlider className={'nav-menu-slider'} />
            <ActiveSlider className={activeItem} />
          </MenuItemsList>
          <Spacer />
        </div>
      </MenuRoot>
    );
  }
}

const MenuRoot = styled.div`
  min-width: 296px;
  width: 296px;
  background-color: #fff;

  @media (min-width: 660px) {
    min-width: ${(props) => px(props.width)};
    width: ${(props) => px(props.width)};
    min-height: 100vh;
    /* height:100vh; */
  }
  @media (max-width: 767.69px) {
    display: none;
  }

  @media (max-width: 992px) and (min-width: 767.69px) {
    min-width: 64px;
    width: 64px;
  }

  @media (max-width: 767.98px) {
    position: fixed;
    z-index: 200;
    width: 100% !important;
    left: 0;

    &.minimized {
      transform: translateX(-100%);
      width: 100% !important;
    }
    bottom: 0;
    overflow-y: hidden;
    overflow-x: auto;

    .logonav {
      display: none;
    }
  }
`;
export const MenuItemsList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: 767.69px) {
    flex-direction: row;
    width: 100%;
  }

  & > .settings {
    align-self: flex-end;
  }

  width: 296px;
  height: auto;

  @media (max-width: 992px) and (min-width: 767.69px) {
    width: 64px;
  }
`;
export const MenuItem = styled.a`
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  flex-shrink: 0;
  font-weight: 400;
  font-size: 16px;
  height: 4.2rem;
  justify-content: center;
  padding-left: 26px;
  padding-right: 26px;
  width: 296px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  z-index: 20;
  height: ${itemHeight}px;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 767.69px) {
    width: 100%;
  }

  @media (max-width: 992px) and (min-width: 767.69px) {
    width: 64px;
  }

  @media (min-width: 660px) {
    ${items
      .map(
        (item, i) => `
		&:nth-child(${i + 1}).active ~ .nav-menu-slider { visibility: visible; top: ${
          itemHeight * i
        }px; }
        &:nth-child(${i + 1}):hover ~ .nav-menu-slider { visibility: visible; top: ${
          itemHeight * i
        }px !important; }`,
      )
      .join('\n')}
  }
  @media (min-width: 1440px) {
    ${items
      .map(
        (item, i) => `
		&:nth-child(${i + 1}).active ~ .nav-menu-slider { visibility: visible; top: ${
          itemHeight * i
        }px; }
        &:nth-child(${i + 1}):hover ~ .nav-menu-slider { visibility: visible; top: ${
          itemHeight * i
        }px !important; }`,
      )
      .join('\n')}
  }
  @media (max-width: 767.98px) {
    ${items
      .map(
        (item, i) => `
        &:nth-child(${i + 1}).active ~ .nav-menu-slider { visibility: visible; left: ${
          itemHeight * i
        }px; }
        &:nth-child(${i + 1}):hover ~ .nav-menu-slider { visibility: visible; left: ${
          itemHeight * i
        }px !important; }`,
      )
      .join('\n')}
  }
`;
const Slider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 296px;

  @media (min-width: 768px) {
    height: ${itemHeight}px;
  }
  @media (min-width: 1440px) {
    height: ${itemHeight}px;
  }
  @media (max-width: 767.98px) {
    height: 0.3rem;
  }

  @media (max-width: 992px) and (min-width: 767.69px) {
    width: 64px;
  }

  z-index: 15;
  transition: top 0.2s ease-in-out;
  visibility: hidden;
`;
const HoverSlider = styled(Slider)`
  left: ${itemHeight * Math.floor(items.length / 2)}px;
  @media (min-width: 768px) {
    top: ${itemHeight * Math.floor(items.length / 2)}px;
    left: auto !important;
  }
  @media (min-width: 1440px) {
    top: ${itemHeight * Math.floor(items.length / 2)}px;
  }
  box-shadow: 0 4px ${25 / 14}rem rgba(0, 0, 0, 0.08);
`;
const ActiveSlider = styled(Slider)`
  @media (min-width: 768px) {
    border-right: 0.3rem solid ${Defaults.mainColor};
    ${items
      .map((item, i) => `&.${item} { visibility: visible; top: ${itemHeight * i}px; }`)
      .join('\n')}
  }
  @media (min-width: 1440px) {
    ${items
      .map((item, i) => `&.${item} { visibility: visible; top: ${itemHeight * i}px; }`)
      .join('\n')}
  }
  @media (max-width: 767.98px) {
    border-top: 0.3rem solid ${Defaults.mainColor};
    ${items
      .map((item, i) => `&.${item} { visibility: visible; left: ${itemHeight * i}px; }`)
      .join('\n')}
  }
`;
export const MenuItemCaption = styled.div`
  flex-grow: 1;
  padding-right: 1.5rem;
  visibility: visible;

  &.minimized {
    visibility: hidden;
  }

  @media (max-width: 992px) and (min-width: 767.69px) {
    display: none;
  }
`;
