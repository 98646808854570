import { action, makeAutoObservable, observable } from 'mobx';

import { HomeSelectOption, LangOption } from '../components/home/HomeTopMenu';
import { getLanguage, setLanguage, t } from '../config/i18n';

const Options: HomeSelectOption[] = [
  { id: 'ru', component: <LangOption img={'/ico/top/ru.svg'} lang={'Ru'} /> },
  { id: 'en', component: <LangOption img={'/ico/top/en.svg'} lang={'En'} /> },
];

export class Language {
  @observable lang: string = getLanguage();
  @observable options = Options;

  constructor() {
    makeAutoObservable(this);
  }

  @action setLanguage = (lang: string) => {
    setLanguage(lang);
    this.lang = lang;
  };
}

export const LanguageStore = new Language();
