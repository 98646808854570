import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';

import { setPage } from '../../config/history';
import { getCurrentUser, getUserCurrency } from '../../helpers/settings';
import { formatNumber } from '../../helpers/utils';
import UsersService from '../../services/UsersService';
import { BrokersStore, CurrencyStore } from '../../store';
import { setShadowBrokers } from '../../store/Brokers';
import { UserActiveBidsStore } from '../../store/TableStore';
import { updater } from '../../utils/api';
import { registerHandlerOnType, WS_SIGNALS } from '../../utils/websocket';
import { Bid } from '../bids/BidCard';
import {
  ContentRoot,
  ContentRow,
  PageTitle,
  Row,
  StyledPageBlocksContainer,
  StyledPageLeftBlock,
  StyledPageRightBlock,
  TextEx,
} from '../common';
import ActionButton from '../controls/ActionButton';
import ChatEx, {
  ChatMessage,
  ChatUserStatus,
  getChatMessages,
  sendChatMessage,
} from '../controls/Chat';
import { pageFontSize } from '../controls/Page';
import TableEx from '../controls/TableEx';
import Txt from '../controls/Txt';
import { currency, currencyColor } from '../currencies';
import { PaymentMethodBox } from '../paymentMethods';
import { UserInfoBlock } from './components/UserInfoBlock';

export interface UserProfile {
  id?: string;
  amount?: number;
  currency?: string;
  deals?: number;
  rating?: number;
  likes?: number;
  dislikes?: number;
  verified?: boolean;
  duration?: number;
  status?: ChatUserStatus;
}

const PageUserProfile = (props: RouteComponentProps<{ id: string }>) => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [user, setUser] = useState<UserProfile>({ id: '' });
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const { setFiatCurrency } = CurrencyStore;
  const { persistedBrokers, loadBrokersLogoAndName } = BrokersStore;

  const {
    data: bids,
    setFilters,
    pagination,
    isLoading: isTableLoading,
  } = UserActiveBidsStore;

  useEffect(() => {
    if (props.match.params.id === getCurrentUser()) {
      setPage('wallet');
    }
    setFilters({ user: props.match.params.id });
    const stopUpdate = updater(update);
    registerHandlerOnType(WS_SIGNALS.UPDATE_DEAL, 'message', update);

    return () => {
      stopUpdate();
    };
  }, []);

  useEffect(() => {
    if (bids.length) {
      setShadowBrokers(persistedBrokers);
      loadBrokersLogoAndName(bids);
    }
  }, [bids]);

  const update = () => {
    if (isPageLoading) {
      return;
    }
    setIsPageLoading(true);

    UserActiveBidsStore.fetchFunction();

    const id = props.match.params.id;
    Promise.all([getUserProfile(id), getChatMessages(id)])
      .then(([user, messages]) => {
        setUser(user);
        setFiatCurrency(user.currency || 'RUB');
        setMessages(messages);
      })
      .catch(() => undefined)
      .finally(() => setIsPageLoading(false));
  };

  const sendMessage = async (message: ChatMessage) => {
    try {
      const success = await sendChatMessage(message);
      if (success) {
        setMessages((messages) => [...messages, message]);
        update();
      }
    } catch (error) {}
  };
  const bidButtonClick = (bid: Bid) => setPage(`deals/bid$${bid.id}`);

  return (
    <ContentRoot>
      <ContentRow className="user-profile-head">
        <PageTitle title={'user.title'} args={[user.id]} padding={'0 !important'} />
      </ContentRow>
      <StyledPageBlocksContainer>
        <StyledPageLeftBlock flexGrow={1}>
          <ChatEx
            user={user.id}
            userStatus={user.status}
            captionPrefix={'user.chat-caption'}
            height={'50vh'}
            messages={messages}
            onSendMessage={sendMessage}
          />
        </StyledPageLeftBlock>
        <StyledPageRightBlock>
          <UserInfoBlock user={user} />
        </StyledPageRightBlock>
      </StyledPageBlocksContainer>
      <ContentRow className={'usersTable'} flex={'1 1 auto'} top={'40px'}>
        <TableEx<Bid>
          title={'user.bids-title'}
          titleArgs={[user.id]}
          loading={isTableLoading}
          minWidth={'100%'}
          columns={[
            {
              id: 'bidType',
              caption: 'bids.bid-type',
              render: (bidType) => <Txt k={`bids.type.${bidType}`} />,
            },
            {
              id: 'currency',
              caption: 'bids.currency',
              render: (c) => (
                <Row>
                  {currency(c, '1.2rem', currencyColor(c))}
                  <TextEx size={pageFontSize} color={currencyColor(c)} left={'2px'}>
                    {c}
                  </TextEx>
                </Row>
              ),
            },
            {
              id: 'paymentMethod',
              caption: 'bids.payment-method',
              render: (_, bid: Bid) => {
                return (
                  <PaymentMethodBox
                    paymentMethod={persistedBrokers[bid.brokerId] || {}}
                  />
                );
              },
            },
            {
              id: 'rate',
              caption: 'bids.rate',
              render: (_, bid: Bid) => (
                <TextEx color={'#00cd00'}>
                  <Txt
                    k={'p2p.exchange-rate-mask'}
                    args={[
                      formatNumber(bid.rate),
                      bid.subCurrency || getUserCurrency(),
                      bid.currency,
                    ]}
                  />
                </TextEx>
              ),
            },
            {
              id: 'limits',
              caption: 'bids.limits',
              minWidth: '20vh',
              render: (_, bid: Bid) => (
                <Txt
                  k={'bids.limits-mask'}
                  args={[bid.amountMin, bid.amountMax, bid.subCurrency]}
                />
              ),
            },
            {
              id: 'button',
              render: (_, bid: Bid) => (
                <BidButton
                  bidType={bid.bidType}
                  cur={bid.currency}
                  onClick={() => bidButtonClick(bid)}
                />
              ),
            },
          ]}
          rows={bids}
          noSort
          pagination={pagination}
        />
      </ContentRow>
    </ContentRoot>
  );
};

export const getUserProfile = (id: string): Promise<UserProfile> =>
  new Promise((resolve, reject) => {
    UsersService.profile({ id }, (profile) => {
      if (profile) {
        resolve({
          id,
          amount: profile.deals.amount_currency,
          currency: profile.currency.toUpperCase(),
          deals: profile.deals.deals,
          rating: profile.rating,
          likes: profile.deals.likes,
          dislikes: profile.deals.dislikes,
          verified: profile.verified,
          duration: profile.registered,
          status: profile.online ? 'online' : 'offline',
        });
      } else {
        reject('No profile');
      }
    });
  });

const BidButton = ({
  bidType,
  cur,
  onClick,
}: {
  bidType;
  cur: string;
  onClick: () => void;
}) => (
  <ActionButton
    caption={`p2p.action.${bidType === 'purchase' ? 'sell' : 'buy'}`}
    captionArgs={[cur]}
    height={'1.875rem'}
    onClick={onClick}
    swapColors
  />
);

export default observer(PageUserProfile);

export const UserInfoContent = styled.div`
  @media (min-width: 1100px) {
    display: flex;
  }
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem 1.875rem;
  display: none;
  margin-bottom: 30px;
`;
