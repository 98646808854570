import _ from 'lodash';

import { IBidsFilter } from '../components/bids';
import { Bid } from '../components/bids/BidCard';
import { P2PFilters, P2PRow } from '../components/p2p';
import {
  externalTradeBuyControlHandler,
  externalTradeSellControlHandler,
} from '../components/tradeControl';
import { IErrorCallbackType, ISuccessCallbackType } from '../domain/services/common';
import { ICommonParams } from '../domain/services/common';
import {
  IBidsCreateData,
  IBidsCreateParams,
  IBidsDeleteData,
  IBidsDeleteParams,
  IBidsGetData,
  IBidsGetParams,
  IBidsListData,
  IBidsListParams,
  IBidsUpdateData,
  IBidsUpdateParams,
  IFastDealFindBidData,
  IFastDealFindBidParams,
  IMarketBidsData,
  IMarketBidsParams,
  IWalletsStatuses,
} from '../domain/services/LotsServiceEntities';
import { getAuthHeader } from '../helpers/authHeader';
import { getUserCurrency } from '../helpers/settings';
import { nvl, toLowerCase, toUpperCase } from '../helpers/utils';
import { generalRequest } from '../utils/axiosSetup';
import UsersService from './UsersService';

export default abstract class LotsService {
  // OK
  static list = async (
    params: IBidsListParams,
    onSuccess?: ISuccessCallbackType<IBidsListData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IBidsListData>({
      url: '/lots',
      params: _.omit(
        {
          ...params,
          lot_type: params.type,
          currency: params.subCurrency,
        },
        ['type', 'subCurrency'],
      ),
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static getWalletsStatuses = (): Promise<IWalletsStatuses> =>
    new Promise<IWalletsStatuses>((resolve) => {
      UsersService.wallets((wallets) => {
        if (wallets) {
          resolve(
            wallets.reduce(
              (result, wallet) => {
                return {
                  tradingSellActivated: !result.tradingSellActivated
                    ? false
                    : wallet.is_active_sell,
                  tradingBuyActivated: !result.tradingBuyActivated
                    ? false
                    : wallet.is_active_buy,
                  autorequisitesActivated: !result.autorequisitesActivated
                    ? false
                    : wallet.is_active_auto_requisites,
                };
              },
              {
                tradingSellActivated: true,
                tradingBuyActivated: true,
                autorequisitesActivated: true,
              } as IWalletsStatuses,
            ),
          );
        } else {
          resolve({
            tradingSellActivated: false,
            tradingBuyActivated: false,
            autorequisitesActivated: false,
          });
        }
      });
    });

  static getAutorequisitesActivated = (): Promise<boolean> =>
    new Promise<boolean>((resolve) => {
      UsersService.wallets((wallets) => {
        if (wallets) {
          for (const wallet of wallets) {
            if (!wallet.is_active_auto_requisites) {
              resolve(false);
            }
          }
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });

  static bidsShortList = async (
    config: IBidsFilter & ICommonParams,
  ): Promise<{
    data: Bid[];
    totalPages: number;
    tradingSellActivated: boolean;
    tradingBuyActivated: boolean;
    autorequisitesActivated: boolean;
  }> => {
    return new Promise((resolve) =>
      LotsService.list(
        {
          symbol: config.currency !== 'all' ? config.currency?.toLowerCase() : undefined,
          type:
            config.type && config.type !== 'all'
              ? config.type === 'purchase'
                ? 'buy'
                : 'sell'
              : undefined,
          offset: config.offset,
          limit: config.limit,
          page: config.page,
          subCurrency:
            config.subCurrency !== 'all' ? config.subCurrency.toLowerCase() : undefined,
        },
        (response) => {
          const newBids: Bid[] = [];
          if (response) {
            this.getWalletsStatuses().then(
              ({
                tradingBuyActivated,
                tradingSellActivated,
                autorequisitesActivated,
              }) => {
                for (const lot of response.data) {
                  externalTradeBuyControlHandler(tradingBuyActivated);
                  externalTradeSellControlHandler(tradingSellActivated);
                  const statusByTrading =
                    lot.type === 'buy' ? tradingBuyActivated : tradingSellActivated;
                  newBids.push({
                    id: lot.id,
                    bidType: lot.type === 'buy' ? 'purchase' : 'sale',
                    status: lot.is_active && statusByTrading ? 'enabled' : 'disabled',
                    currency: toUpperCase(lot.symbol) || '',
                    rate: lot.rate,
                    subCurrency: toLowerCase(lot.currency) || '',
                    isActive: lot.is_active,
                    brokerId: lot.broker_id,
                    ordering: lot.ordering,
                    amountMin: lot.limit_from,
                    amountMax: lot.limit_to,
                    tradeCondition: nvl(lot.details),
                    autorequisitesStatus:
                      lot.is_active_auto_requisites && autorequisitesActivated
                        ? true
                        : false,
                    requisites: lot.requisites,
                  });
                }
                resolve({
                  data: newBids,
                  totalPages: response.total_pages,
                  tradingBuyActivated,
                  tradingSellActivated,
                  autorequisitesActivated,
                });
              },
            );
          }
        },
      ),
    );
  };

  // OK
  static get = async (
    params: IBidsGetParams,
    onSuccess?: ISuccessCallbackType<IBidsGetData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IBidsGetData>({
      url: `/lots/${params.id}`,
      params: { actual_limits: params.actual_limits },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static create = async (
    params: IBidsCreateParams,
    onSuccess?: ISuccessCallbackType<IBidsCreateData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IBidsCreateData>({
      url: '/lots',
      method: 'POST',
      data: params.bid,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static update = async (
    params: IBidsUpdateParams,
    onSuccess?: ISuccessCallbackType<IBidsUpdateData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IBidsUpdateData>({
      url: `/lots/${params.bid.id}`,
      method: 'PATCH',
      data: params.bid,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static delete = async (
    params: IBidsDeleteParams,
    onSuccess?: ISuccessCallbackType<IBidsDeleteData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IBidsDeleteData>({
      url: `/lots/${params.id}`,
      method: 'DELETE',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static marketBids = async (
    params: IMarketBidsParams,
    onSuccess?: ISuccessCallbackType<IMarketBidsData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IMarketBidsData>({
      url: '/lots/market',
      params: {
        symbol: params.symbol,
        lot_type: params.type,
        broker: params.broker,
        currency: params.subCurrency,
        nickname: params.user,
        page: params.page,
        limit: params.limit,
        offset: params.offset,
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static userActiveBids = async (
    config: ICommonParams & { user: string },
  ): Promise<{ data: Bid[]; totalPages: number }> =>
    new Promise((resolve) =>
      LotsService.marketBids(
        {
          user: config.user,
          limit: config.limit,
          page: config.page,
          offset: config.offset,
          type: 'buy',
        },
        (bids) => {
          const rows: Bid[] = [];
          if (bids) {
            for (const bid of bids.data) {
              rows.push({
                id: bid.id,
                bidType: bid.type === 'buy' ? 'purchase' : 'sale',
                status: bid.is_active ? 'enabled' : 'disabled',
                ordering: 1,
                currency: toUpperCase(bid.symbol) || '',
                rate: bid.rate || 0,
                subCurrency: toUpperCase(bid.currency) || '',
                brokerId: bid.broker_id,
                amountMin: bid.limit_from || 0,
                amountMax: bid.limit_to || 0,
              });
            }
            resolve({ data: rows, totalPages: bids.total_pages });
          }
        },
      ),
    );

  static marketP2PRows = async (
    config: P2PFilters & ICommonParams,
  ): Promise<{ data: P2PRow[]; totalPages: number }> =>
    new Promise((resolve) =>
      LotsService.marketBids(
        {
          symbol: config.currency?.toLowerCase() || 'btc',
          type:
            config.bidType !== 'all'
              ? config.bidType === 'purchase'
                ? 'sell'
                : 'buy'
              : undefined,
          broker:
            config.paymentMethod?.name !== 'all' ? config.paymentMethod?.name : undefined,
          page: config.page,
          subCurrency: (config.subCurrency || getUserCurrency())?.toLowerCase(),
          offset: config.offset,
          limit: config.limit,
        },
        (bids) => {
          const rows: P2PRow[] = [];
          if (bids) {
            for (const bid of bids.data) {
              rows.push({
                id: nvl(bid.id, ''),
                type: nvl(bid.type, 'sell'),
                crypto: nvl(toUpperCase(bid.symbol), ''),
                userName: bid.user.nickname,
                user: bid.user,
                brokerId: bid.broker_id,
                rate: nvl(bid.rate, 0),
                currency: nvl(toUpperCase(bid.currency), 'RUB'),
                limitFrom: nvl(bid.limit_from, 0),
                limitTo: nvl(bid.limit_to, 0),
              });
            }
            resolve({ data: rows, totalPages: bids.total_pages });
          }
        },
      ),
    );

  // OK
  static fastDealFindBid = async (
    params: IFastDealFindBidParams,
    onSuccess?: ISuccessCallbackType<IFastDealFindBidData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IFastDealFindBidData>({
      url: '/lots/fast-deal',
      headers: getAuthHeader(params.token),
      params: {
        broker: params.broker,
        symbol: params.symbol,
        currency: params.currency,
        amount: params.amount,
        exclude: params.exclude.join(','),
      },
      successCallback: onSuccess,
      errorCallback: onError,
    });
}
