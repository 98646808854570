import React, { ChangeEvent, KeyboardEvent } from 'react';
import styled from 'styled-components/macro';

import { Defaults, px } from '../common';

const QuantityInput = ({
  value,
  onChange,
  allowNegative,
  min,
  max,
  width,
  height,
  preventZero,
  borderColor = '#e9e9e9',
  fontSize = Defaults.fontSize,
}: {
  value: number;
  onChange: (value: number) => void;
  allowNegative?: boolean;
  min?: number;
  max?: number;
  width?;
  height?: number | string;
  preventZero?: boolean;
  borderColor?: string;
  fontSize?: number | string;
}) => {
  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (preventZero) {
      if (Number(value)) {
        onChange(parseInt(value, 10));
      }
    } else onChange(parseInt(value, 10));
  };

  const incValue = () => (!max || value < max ? onChange(value + 1) : undefined);

  const decValue = () =>
    (allowNegative || value > 0) && (!min || value > min)
      ? onChange(value - 1)
      : undefined;

  return (
    <QuantityInputRoot
      width={width}
      height={height}
      borderColor={borderColor}
      fontSize={fontSize}>
      <div className={'plus'} onClick={() => incValue()}>
        +
      </div>
      <div className={'input-box'}>
        <input
          type={'number'}
          onWheel={() => document.activeElement?.['blur']()}
          value={value}
          autoFocus
          onChange={onChangeValue}
        />
      </div>
      <div className={'minus'} onClick={() => decValue()}>
        &#8211;
      </div>
    </QuantityInputRoot>
  );
};

const QuantityInputRoot = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => px(props.width)};
  height: ${(props) => px(props.height)};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: ${px(Defaults.borderRadius)};

  & div.plus,
  div.minus {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: ${(props) => px(props.height)};
    height: 100%;
    cursor: pointer;
    font-size: ${(props) => px(props.fontSize)};
    color: ${Defaults.grayColor};
    user-select: none;
  }
  & div.plus:active,
  div.minus:active {
    background-color: #f5f5f5;
  }
  & div.plus {
    border-right: 2px solid ${(props) => props.borderColor};
  }
  & div.minus {
    border-left: 2px solid ${(props) => props.borderColor};
  }

  & input {
    width: 100%;
    text-align: center;
    /* font-family: ${Defaults.fontFamily}; */
    font-size: ${(props) => px(props.fontSize)};
    border: unset;
  }
  & input:focus {
    outline: none;
  }
  & input::-webkit-inner-spin-button,
  & input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default QuantityInput;
