import React from 'react';
import styled from 'styled-components/macro';

import { onLanguageChange, t, tt } from '../../config/i18n';
import {
  formatString,
  getUTCTime,
  roundFloat,
  secondsToText,
  toUpperCase,
} from '../../helpers/utils';
import OperationsService from '../../services/OperationsService';
import { updater } from '../../utils/api';
import { Notification } from '../../utils/types';
import {
  ContentRoot,
  ContentRow,
  Defaults,
  Hr,
  PageTitle,
  Spacer,
  TextEx,
} from '../common';
import ActionButton from '../controls/ActionButton';
import DatePickerEx from '../controls/DatePickerEx';
import {
  pageBorderColor,
  PageCaption,
  pageControlHeight,
  pageFontSize,
  PageRoot,
} from '../controls/Page';
import SelectEx, { SelectExOptionProps } from '../controls/SelectEx';
import Txt from '../controls/Txt';
import { FilterBox, FilterControl } from '../p2p/P2PFilter';

interface State {
  dateFrom?: Date;
  dateTo?: Date;
  notificationType: string;
  notifications: Notification[];
}

export default class PageNotifications extends React.Component {
  state: State = {
    notificationType: 'all',
    notifications: [],
  };

  private stopUpdate;
  private updating;

  componentDidMount(): void {
    onLanguageChange(() => this.setState({}));
    this.stopUpdate = updater(this.update);
  }

  componentWillUnmount(): void {
    this.stopUpdate();
  }

  update = () => {
    if (this.updating) {
      return;
    }
    this.updating = true;

    const { dateFrom, dateTo, notificationType } = this.state;
    OperationsService.allNotifications({
      from: getUTCTime(dateFrom),
      to: getUTCTime(dateTo),
      notificationType: notificationType === 'all' ? undefined : notificationType,
      limit: 500,
    })
      .then((notifications) =>
        notifications ? this.setState({ notifications }) : undefined,
      )
      .catch(() => undefined)
      .finally(() => (this.updating = false));
  };

  onFilterChange = (name: string, value: any) =>
    this.setState({ [name]: value }, this.update);
  onResetFilter = () => {
    this.setState(
      { dateFrom: undefined, dateTo: undefined, notificationType: 'all' },
      this.update,
    );
  };

  render(): React.ReactNode {
    const { dateFrom, dateTo, notificationType, notifications } = this.state;

    return (
      <ContentRoot>
        <ContentRow height={'3.75rem'}>
          <PageTitle title={'notifications.title'} />
        </ContentRow>
        <ContentRow flex={'0 1 auto'}>
          <NotificationsFilter
            dateFrom={dateFrom}
            dateTo={dateTo}
            notificationType={notificationType}
            onChange={this.onFilterChange}
            onReset={this.onResetFilter}
          />
        </ContentRow>
        <ContentRow flex={'1 1 auto'} top={'1.6vw'}>
          <PageRoot
            className={'notificationsItem'}
            minWidth={'35vh'}
            width={'100%'}
            // height={'63vh'}
          >
            <PageCaption title={'notifications.all'} />
            <Hr />
            <NotificationsRoot>
              {notifications.map((notification, n) => (
                <NotificationBox key={n} notification={notification} read />
              ))}
            </NotificationsRoot>
          </PageRoot>
        </ContentRow>
      </ContentRoot>
    );
  }
}

export const NotificationBox = ({
  notification,
  read,
  isFirstElement,
}: {
  isFirstElement?: boolean;
  notification: Notification;
  read?: boolean;
}) => (
  <NotificationRoot $firstElement={isFirstElement}>
    <ReadMark color={read || notification.is_read ? 'transparent' : Defaults.redColor} />
    <div className={'column'}>
      <P dangerouslySetInnerHTML={{ __html: getNotificationText(notification) }} />
      <NotificationTime>
        <TextEx color={'gray'} size={'1rem'}>
          {sinceTime(
            notification.created_ago ??
              (typeof notification.created_at === 'number'
                ? (Date.now() - notification.created_at * 1000) / 1000
                : 2),
          )}
        </TextEx>
      </NotificationTime>
    </div>
  </NotificationRoot>
);

const NotificationsFilter = ({
  dateFrom,
  dateTo,
  notificationType,
  onChange,
  onReset,
}: {
  dateFrom?;
  dateTo?: Date;
  notificationType?: string;
  onChange: (name: string, value: any) => void;
  onReset: () => void;
}) => {
  const [, setLang] = React.useState<string>('');
  onLanguageChange(setLang);

  const getNotificationTypeOptions = (): SelectExOptionProps[] =>
    ['all', 'deal', 'timeout', 'message', 'dispute', 'promocode'].map((o) => ({
      id: o,
      value: tt('notifications.filter.type', o),
    }));

  const onChangeField = (name) => (value) => onChange(name, value);

  return (
    <FilterBox className={'FilterBox-head'} minWidth={'100%'}>
      <FilterControl label={'notifications.filter.date-from'}>
        <FilterDatePicker value={dateFrom} onChange={onChangeField('dateFrom')} />
      </FilterControl>
      <FilterControl label={'notifications.filter.date-to'} left={'1.125rem'}>
        <FilterDatePicker value={dateTo} onChange={onChangeField('dateTo')} />
      </FilterControl>
      <FilterControl label={'notifications.filter.notification-type'} left={'1.125rem'}>
        <SelectEx
          id={notificationType}
          options={getNotificationTypeOptions()}
          borderColor={pageBorderColor}
          onChange={onChangeField('notificationType')}
          height={pageControlHeight}
          width={'10rem'}
        />
      </FilterControl>
      <Spacer />
      <FilterControl left={'1.125rem'}>
        <ActionButton
          caption={'common.reset'}
          height={pageControlHeight}
          onClick={onReset}
          swapColors
        />
      </FilterControl>
    </FilterBox>
  );
};

const FilterDatePicker = ({
  value,
  onChange,
}: {
  value?: any;
  onChange: (value) => void;
}) => (
  <DatePickerEx
    value={value}
    onChange={onChange}
    fontSize={pageFontSize}
    height={pageControlHeight}
    borderColor={pageBorderColor}
  />
);

function getNotificationText(notification: Notification): string | undefined {
  switch (notification.type) {
    case 'deal':
      return formatString(t('notifications.text.deal'), notification.details.id);
    case 'before_timeout':
      return formatString(
        t('notifications.text.before-timeout'),
        notification.details.id,
      );
    case 'cancel_deal':
      return formatString(t('notifications.text.deal-cancel'), notification.details.id);
    case 'timeout':
      return formatString(t('notifications.text.timeout'), notification.details.id);
    case 'message':
      return notification.details.media
        ? formatString(
            t('notifications.text.media'),
            notification.details.sender,
            notification.details.media,
          )
        : formatString(t('notifications.text.message'), notification.details.sender);
    case 'dispute':
      return formatString(
        t('notifications.text.dispute-opened'),
        notification.details.id,
      );
    case 'closed_dispute':
      return formatString(
        t('notifications.text.dispute-closed'),
        notification.details.id,
      );
    case 'transaction':
      return formatString(
        t(`notifications.text.transaction-${notification.details.type}`),
        notification.details.amount,
        toUpperCase(notification.details.symbol),
      );
    case 'income_referral':
      return formatString(
        t('notifications.text.referral'),
        notification.details.referral_nickname,
        notification.details.amount,
        toUpperCase(notification.details.symbol),
      );
    case 'promocode':
      return formatString(
        t('notifications.text.promocode'),
        notification.details.activator,
        notification.details.code,
        notification.details.amount,
        toUpperCase(notification.details.symbol),
      );
    case 'exchange':
      return formatString(
        t('notifications.text.exchange-ended'),
        roundFloat(
          parseFloat(notification.details.amount_sent),
          notification.details.from_symbol === 'btc' ? 8 : 4,
        ),
        toUpperCase(notification.details.from_symbol),
        roundFloat(
          parseFloat(notification.details.amount_received),
          notification.details.to_symbol === 'btc' ? 8 : 4,
        ),
        toUpperCase(notification.details.to_symbol),
      );
    case 'system_message':
      return notification.details.text;
    case 'deal_autorequisites':
      return formatString(
        t('notifications.text.deal_autorequisites'),
        notification.details.id,
        notification.details.amount_currency,
        notification.details.currency.toUpperCase(),
      );
  }
}

function sinceTime(seconds?: number): React.ReactNode {
  if (!seconds) return <></>;
  const [n, measure] = secondsToText(seconds);
  return (
    <>
      {n}&nbsp;
      <Txt k={`time.${measure}`} />
      &nbsp;
      <Txt k={'time.ago'} />
    </>
  );
}

const NotificationsRoot = styled.div`
  overflow-y: auto;
`;
const NotificationRoot = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 0.85rem 0.75rem;
  align-items: center;
  border-bottom: 0.01vh solid #e9e9e9;
  background-color: ${Defaults.whiteColor};
  border-top-right-radius: ${({ $firstElement }) => ($firstElement ? '8px' : 0)};
  border-top-left-radius: ${({ $firstElement }) => ($firstElement ? '8px' : 0)};

  &:last-child {
    border-bottom: unset;
  }

  & .column {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
  }
`;
const NotificationTime = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const P = styled.p`
  color: ${Defaults.textColor};
  font-size: 1rem;
  margin: 0;
`;
const ReadMark = styled.div`
  width: 1vh;
  height: 1vh;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  flex-shrink: 0;
`;
