import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { ACCEPTED_IMAGES_MIME_TYPES } from '../../config/constants';
import { onLanguageChange, t, tt } from '../../config/i18n';
import { showMessage } from '../../helpers/notifications';
import { notify, openLink, parseDate, toLowerCase } from '../../helpers/utils';
import UserMessagesService from '../../services/UserMessagesService';
import { classNames } from '../../utils/api';
import {
  Defaults,
  Hr,
  NoIcon,
  PendingIcon,
  px,
  Spacer,
  TextEx,
  Timer,
  YesIcon,
} from '../common';
import IconEx from './IconEx';
import { pageFontSize, pageInfoCaptionColor } from './Page';
import PDFEx from './PDFEx';
import Txt from './Txt';

export type ChatUserStatus = 'online' | 'offline';
export type ChatMessageType = 'user' | 'system' | 'date' | 'hint' | 'select';

export interface ChatMessage {
  type: ChatMessageType;
  date: Date;
  user?: string;
  text?: string;
  mediaId?: number;
  media?: string;
  options?: string[];
  icons?: string[];
  hideCaptions?: boolean;
  customData?: any;
  selected?: string;
  onSelect?: (option: string, message?: ChatMessage) => void;
  currency?: string;
}

export function getChatMessages(user, token?: string): Promise<ChatMessage[]> {
  return new Promise<ChatMessage[]>((resolve) =>
    UserMessagesService.receive(
      { user, token },
      (userMessages) => {
        const messages: ChatMessage[] = [];
        if (userMessages) {
          for (const message of userMessages) {
            messages.push({
              type: message.sender === user ? 'system' : 'user',
              date: parseDate(message.created_at) || new Date(),
              user: message.sender,
              text: message.message,
              media: message.media,
            });
          }
          messages.sort((a, b) => (a.date > b.date ? 1 : -1));
        }
        resolve(messages);
      },
      () => resolve([]),
    ),
  );
}

export function sendChatMessage(message: ChatMessage, token?: string): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    if (!!message.user && (!!message.text || !!message.mediaId)) {
      UserMessagesService.send(
        {
          message: {
            receiver: message.user,
            message: message.text,
            media_id: message.mediaId,
            symbol: toLowerCase(message.currency),
          },
          token,
        },
        () => resolve(true),
        (error) => {
          showMessage('error', t(`errors.${error.response?.data?.detail}`));
          resolve(false);
        },
      );
    } else {
      resolve(false);
    }
  });
}

const MAX_FILE_SIZE = 2.5 * 1024 * 1024;
const sendTimeout = 10;

const ChatEx = ({
  user,
  alias,
  messages,
  onSendMessage,
  onGetAuthToken,
  userStatus = 'online',
  captionPrefix,
  toolbar,
  userIcon,
  height,
  minWidth,
  icons,
  noHeader = false,
}: {
  user;
  alias?: string;
  messages: ChatMessage[];
  onSendMessage: (message: ChatMessage) => void;
  onGetAuthToken?: () => string;
  userStatus?: ChatUserStatus;
  captionPrefix?: string;
  toolbar?: React.ReactNode;
  userIcon?: React.ReactNode;
  height?;
  minWidth?: number | string;
  icons?: Record<string, React.ReactNode>;
  noHeader?: boolean;
}) => {
  const [inputMessage, setInputMessage] = useState<string>('');
  const [lockTime, setLockTime] = useState(0);
  const [pendingMessage, setPendingMessage] = useState<ChatMessage | null>(null);

  const chatContentRef = useRef<HTMLDivElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  useEffect(scrollChatContent, [messages.length]);
  useEffect(() => {
    const lastMessages = messages.slice(-10);
    if (
      pendingMessage &&
      lastMessages.find(
        (message) =>
          (!message.media && message.text == pendingMessage.text) ||
          (message.mediaId && message.mediaId == pendingMessage.mediaId),
      )
    )
      setPendingMessage(null);
  }, [messages]);

  const [, setLang] = React.useState<string>('');
  onLanguageChange((lang) => setLang(lang));

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files?.[0]) return;
    if (e.target.files[0].size >= MAX_FILE_SIZE) {
      notify(t('errors.Payload is too large'), 'error');
      e.target.value = '';
      return;
    }

    attachFiles(e);
  };

  const attachFiles = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files?.[0]) return;
    const token = onGetAuthToken ? onGetAuthToken() : undefined;
    UserMessagesService.attach({ file: e.target.files[0], token })
      .then((result) => {
        let formedMessage = {} as ChatMessage;
        if (result?.id) {
          formedMessage = {
            type: 'user',
            date: new Date(),
            user,
            mediaId: result.id,
          };

          onSendMessage(formedMessage);
          setPendingMessage(formedMessage);
        }
      })
      .finally(lockSending);
    fileRef?.current && (fileRef.current.value = '');
  };

  function sendMessage() {
    if (lockTime === 0 && !!inputMessage) {
      const message: ChatMessage = {
        type: 'user',
        date: new Date(),
        user,
        text: inputMessage,
      };

      setInputMessage('');
      setPendingMessage(message);
      onSendMessage(message);

      lockSending();
    }
  }

  function lockSending() {
    let lTime = sendTimeout;
    setLockTime(lTime);
    const lockTimer = setInterval(() => {
      lTime -= 1;
      setLockTime(lTime);
      if (lTime <= 0) {
        clearInterval(lockTimer);
      }
    }, 1000);
  }

  function scrollChatContent() {
    const chat = chatContentRef.current!;
    chat.scrollTop = chat.scrollHeight;
  }

  return (
    <ChatRoot height={height} minWidth={minWidth}>
      {!noHeader && (
        <>
          <ChatCaptionRoot>
            {!!userIcon && userIcon}
            <ChatCaptionText>
              <TextEx weight={'500'}>
                {!!captionPrefix && (
                  <>
                    <Txt k={captionPrefix} />
                    &nbsp;
                  </>
                )}
                <TextEx color={Defaults.mainColor}>
                  {alias ? <Txt k={alias} /> : `/u${user}`}
                </TextEx>
              </TextEx>
              <TextEx size={'1rem'} color={pageInfoCaptionColor}>
                <Txt k={`chat.status-${userStatus}`} />
              </TextEx>
            </ChatCaptionText>
            {!!toolbar && (
              <>
                <Spacer />
                {toolbar}
              </>
            )}
          </ChatCaptionRoot>
          <Hr />
        </>
      )}
      <ChatContent ref={chatContentRef}>
        <Spacer />
        {insertDates(messages).map((message, i) => (
          <ChatMessageBox key={i} message={message} alias={alias} icons={icons} />
        ))}
        {pendingMessage && (
          <ChatMessageBox
            key={'pendingMessage'}
            message={pendingMessage}
            pending
            alias={alias}
            icons={icons}
          />
        )}
      </ChatContent>
      <Hr />
      <ChatFooter>
        <AttachBox>
          <AttachmentButton size={'1.5rem'} hint={t('chat.attachments-hint')} />
          <input
            ref={fileRef}
            disabled={lockTime > 0}
            type={'file'}
            autoComplete={'off'}
            accept={ACCEPTED_IMAGES_MIME_TYPES}
            onChange={handleFile}
            id="input-file-field"
          />
        </AttachBox>
        <ChatInput
          maxLength={'1024'}
          value={inputMessage}
          autoComplete={'off'}
          placeholder={t('chat.input-placeholder')}
          onChange={(event) => setInputMessage(event.target.value)}
          onKeyPress={(event) => event.key === 'Enter' && sendMessage()}
        />
        {lockTime > 0 ? (
          <Timer time={lockTime} hint={'chat.send-lock-hint'} />
        ) : (
          <SendButton size={'2.7rem'} hint={t('chat.send-hint')} onClick={sendMessage} />
        )}
      </ChatFooter>
    </ChatRoot>
  );
};

const ChatMessageBox = ({
  message,
  alias,
  icons,
  pending,
}: {
  message: ChatMessage;
  alias?: string;
  icons?: Record<string, React.ReactNode>;
  pending?: boolean;
}) => {
  const [dummy, setDummy] = React.useState(0);

  function onSelectOption(option: string) {
    if (!message.selected && !!message.onSelect) {
      message.selected = option;
      setDummy(dummy + 1);
      message.onSelect(option, message);
    }
  }

  function onMediaMessageClick() {
    message.media?.includes('.pdf') && window.open(message.media, '_blank');
  }

  return (
    <ChatMessageBoxRoot>
      <div className={classNames('message', message.type)}>
        {message.type === 'date' ? (
          <div className={'date'}>
            <TextEx size={'1rem'} color={'unset'}>
              {formatChatDate(message.date)}
            </TextEx>
          </div>
        ) : (
          <>
            <div className={classNames('text', message.media?.includes('.pdf') && 'pdf')}>
              {message.type === 'system' && (
                <div className={'sender'}>
                  <TextEx size={pageFontSize} color={'unset'}>
                    <Txt k={alias || message.user || ''} />
                  </TextEx>
                </div>
              )}
              {!!message.text &&
              (message.type === 'hint' || message.type === 'select') ? (
                <TextEx size={pageFontSize}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: message.text || '',
                    }}
                  />
                </TextEx>
              ) : (
                <TextEx size={pageFontSize}>{message.text}</TextEx>
              )}
              {message.media ? (
                message.media.includes('.pdf') ? (
                  <>
                    <PDFEx src={message.media} width="100%" height="100%" />
                    <a className={'new-tab'} onClick={onMediaMessageClick}>
                      {t('chat.open-in-new-tab')}
                    </a>
                  </>
                ) : (
                  <IconEx
                    src={message.media}
                    alt={message.media}
                    size={'10rem'}
                    top={'.8125rem'}
                    borderRadius={Defaults.borderRadius}
                    onClick={() => openLink(message.media || '')}
                  />
                )
              ) : undefined}
            </div>
            <div className={'time'}>
              <TextEx size={'1rem'} width={'3rem'} color={'unset'}>
                {formatChatTime(message.date)}
              </TextEx>
              {pending && (
                <div className={'pending'}>
                  <PendingIcon size={'1rem'} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {message.options ? (
        <div className={'options'}>
          {message.options.map((option, i) => (
            <div
              key={option}
              className={[
                'option',
                option !== message.selected && !message.selected && !!message.onSelect
                  ? 'enabled'
                  : '',
                option === message.selected ? 'selected' : '',
              ].join(' ')}
              onClick={() => onSelectOption(option)}>
              {option.endsWith('yes') ? (
                <>
                  <YesIcon size={'1.125rem'} />
                  &nbsp;&nbsp;
                </>
              ) : undefined}
              {option.endsWith('no') ? (
                <>
                  <NoIcon size={'1.125rem'} />
                  &nbsp;&nbsp;
                </>
              ) : undefined}
              {!!icons && !!message.icons && !!message.icons[i] ? (
                <>{icons[message.icons[i]]}&nbsp;&nbsp;</>
              ) : undefined}
              {!message.hideCaptions ? (
                <TextEx color={'inherit'} uppercase>
                  {option === 'yes' || option === 'no' ? (
                    <Txt k={`chat.option.${option}`} />
                  ) : (
                    <Txt k={option} />
                  )}
                </TextEx>
              ) : undefined}
            </div>
          ))}
        </div>
      ) : undefined}
    </ChatMessageBoxRoot>
  );
};

const AttachmentButton = ({ size, hint }: { size: number | string; hint?: string }) => (
  <ButtonBox title={hint}>
    <svg style={{ width: size, height: size }} viewBox={'0 0 351 351'}>
      <g>
        <path
          d="M324.572,42.699c-35.419-35.419-92.855-35.419-128.273,0L19.931,219.066c-26.575,26.575-26.575,
                    69.635, 0,96.211 c21.904,21.904,54.942,25.441,80.769,11.224c2.698-0.136,5.351-1.156,
                    7.415-3.197l176.367-176.367 c17.709-17.709,17.709-46.416,0-64.125s-46.416-17.709-64.125,0L76.052,
                    227.116c-4.422,4.422-4.422,11.61, 0,16.031  c4.422,4.422,11.61,4.422,16.031,0L236.388,
                    98.843c8.866-8.866,23.219-8.866,32.063,0c8.866, 8.866,8.866,23.219,0,32.063 L100.088,
                    299.268c-17.709,17.709-46.416,17.709-64.125,0s-17.709-46.416, 0-64.125L212.33,58.73 c26.575-26.575,
                    69.635-26.575,96.211,0c26.575,26.575,26.575,69.635,0,96.211L148.205,315.277c-4.422,4.422-4.422,
                    11.61,0,16.031  c4.422,4.422,11.61,4.422,16.031,0l160.336-160.336C359.991,135.554,359.991,
                    78.118,324.572,42.699z"
        />
      </g>
    </svg>
  </ButtonBox>
);

const SendButton = ({
  size,
  hint,
  onClick,
}: {
  size: number | string;
  hint?: string;
  onClick: () => void;
}) => (
  <ButtonBox title={hint} onClick={onClick} fill={Defaults.mainColor}>
    <svg style={{ width: size, height: size }} viewBox={'0 0 30 30'}>
      <polygon points="28,15 18,15 5.338,12.885 4,11 4,5 6.994,3.266 27.391,14.079" />
      <circle cx="27" cy="15" r="1" />
      <circle cx="6" cy="11" r="2" />
      <circle cx="6" cy="5" r="2" />
      <polygon points="28,15 18,15 5.338,17.115 4,19 4,25 6.994,26.734 27.391,15.921" />
      <circle cx="6" cy="19" r="2" />
      <circle cx="6" cy="25" r="2" />
    </svg>
  </ButtonBox>
);

const ChatRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  ${(props) => (props.minWidth ? `min-width: ${px(props.minWidth)}` : '')}
  ${(props) => (props.height ? `height: ${px(props.height)};` : '')}
    border-radius: ${px(Defaults.borderRadius)};
  background-color: white;
`;
const ChatCaptionRoot = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  height: 4.625rem;
  padding: 1.875rem 1.875rem;
`;
const ChatCaptionText = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-left: 1.875rem; */
`;
const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.875rem;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #0097db #c4c4c4;
  scrollbar-width: thin;
  scrollbar-color: #0097db #c4c4c4;

  &::-webkit-scrollbar-thumb {
    opacity: 1;
    display: block;
  }

  &::-webkit-scrollbar-track {
    border: none;
    background-color: #c4c4c4;
    border-color: #c4c4c4;
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #c4c4c4;
    // border-right: 2px;
    /* or add it to the track */
    border-color: #c4c4c4;
    opacity: 0;
    -webkit-box-shadow: none;
  }

  /* Add a thumb */
  &::-webkit-scrollbar-thumb {
    background: #0097db;
    border-radius: 2.5px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;
const ChatMessageBoxRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1.875rem;

  & .pending-wrapper {
    display: flex;
  }

  & .message {
    word-break: break-all;
    max-width: 90%;
    display: flex;
    flex-direction: row;
    border-radius: ${px(Defaults.borderRadius)};
    padding: 0.3125rem 1vh;
  }

  & .message.date {
    padding: 0 2.1rem;
    align-self: center;
    align-items: center;
    justify-content: center;
    color: ${Defaults.grayColor};
    background-color: #f1f1f1;
  }

  & .message.user {
    align-self: flex-end;
    background-color: #effafd;
  }

  & .text .new-tab {
    color: ${Defaults.mainColor};
  }

  & .text.pdf {
    height: fit-content;
  }

  & .message.system {
    align-self: flex-start;
    background-color: #f5f5f5;
  }

  & .message.hint,
  & .message.select {
    align-self: flex-start;
    border: 1px solid #e9e9e9;
  }
  & .message.select {
    width: 100%;
  }

  & .options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.875rem 0;
  }
  & .option {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.6vh;
    border: 1px solid ${Defaults.mainColor};
    border-radius: ${px(Defaults.borderRadius)};
    color: ${Defaults.mainColor};
    fill: ${Defaults.mainColor};
    transition: ${Defaults.transition};
    user-select: none;
  }
  & .option:not(:first-child) {
    margin-left: 1.875rem;
  }
  & .option svg {
    fill: inherit;
  }
  & .option.enabled:hover,
  .option.selected {
    background-color: ${Defaults.mainColor};
    color: white;
    fill: white;
  }
  & .option.enabled:hover {
    cursor: pointer;
  }

  & div.sender {
    display: flex;
    height: 1.875rem;
  }

  & .message.user div.sender {
    color: ${Defaults.grayColor};
  }

  & .message.system div.sender {
    color: ${Defaults.mainColor};
  }

  & .text {
    flex-grow: 1;
  }

  & .time {
    display: flex;
    margin-left: 1vw;
    align-items: flex-end;
    color: ${Defaults.grayColor};
  }

  & .time .pending {
    align-self: center;
    width: 1rem;
  }
`;
const ChatFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  padding: 1.875rem;
`;
const ChatInput = styled.input`
  display: flex;
  flex-grow: 1;
  margin-left: 1.875rem;
  outline: none;
  border: none;
  /* font-family: ${Defaults.fontFamily}; */
  font-size: ${px(Defaults.fontSize)};
  &::placeholder {
    color: ${pageInfoCaptionColor};
  }
`;
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.875rem;
  cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
  & svg {
    fill: ${(props) => props.fill || pageInfoCaptionColor};
    transition: ${Defaults.transition};
  }
  &:hover svg {
    fill: ${(props) => props.fill || Defaults.mainColor};
  }
`;
const AttachBox = styled.label`
  & input[type='file'] {
    display: none;
  }
`;

function insertDates(messages: ChatMessage[]): ChatMessage[] {
  const messages2: ChatMessage[] = [];
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    const prevMessage = i > 0 ? messages[i - 1] : undefined;
    if (
      prevMessage === undefined ||
      (!!message.date &&
        prevMessage.date &&
        message.date.getDate() !== prevMessage.date.getDate())
    ) {
      messages2.push({ type: 'date', date: copyDate(message.date) });
    }
    messages2.push(message);
  }
  return messages2;
}

function copyDate(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function formatChatDate(date: Date): string {
  let result = `${tt('months', date.getMonth().toString())} ${date.getDate()}`;
  if (date.getFullYear() !== new Date().getFullYear()) {
    result += `, ${date.getFullYear()}`;
  }
  return result;
}

function formatChatTime(date: Date): string {
  return `${lpad(date.getHours())}:${lpad(date.getMinutes())}`;
}

function lpad(v: any): string {
  return v.toString().padStart(2, '0');
}

export default ChatEx;
