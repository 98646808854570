import { ExchangeHistory } from '../../domain/ExchangeHistory';
import { JsonExchangeHistory } from '../json/JsonExchangeHistory';

const mapJsonToExchangeHistory = (json: JsonExchangeHistory): ExchangeHistory => ({
  fromSymbol: json.from_symbol,
  toSymbol: json.to_symbol,
  amount: json.amount,
  rate: json.rate,
  createdAt: new Date(json.created_at),
});

export default mapJsonToExchangeHistory;
