import React from 'react';
import styled from 'styled-components/macro';

import { Defaults, px, TextEx } from '../common';
import Txt from './Txt';

const Logo = ({
  minimized = false,
  padding,
  fontSize,
  white,
  onClick,
  center,
}: {
  center?: boolean;
  minimized?: boolean;
  padding?;
  fontSize?: number | string;
  white?: boolean;
  onClick?: () => void;
}) => (
  <LogoRoot center={center} padding={padding} onClick={onClick}>
    <LogoIconContainer>
      <LogoIcon size={'56px'} fill={white ? '#fff' : Defaults.mainColor} />
    </LogoIconContainer>
    <LogoTextContainer className={minimized ? 'minimized' : ''}>
      <LogoText color={white ? 'white' : Defaults.neroColor} fontSize={fontSize} />
    </LogoTextContainer>
  </LogoRoot>
);

export const LogoText = ({
  className,
  left,
  color,
  fontSize = '1.8rem',
  fontFamily,
}: {
  className?: string;
  left?: any;
  color?;
  fontFamily?: string;
  fontSize?: number | string;
}) => (
  <TextEx
    className={className}
    color={color}
    size={fontSize}
    fontFamily={fontFamily}
    weight={300}
    uppercase
    left={left}
    letterSpacing={'.1em'}>
    <Txt k={'app-title'} />
  </TextEx>
);

export const LogoIcon = ({ size, fill }: { size; fill?: string }) => (
  <svg
    style={{ height: size, width: size, fill, flexShrink: 0 }}
    viewBox={'0 0 80 192.7'}>
    <path d="M0.924839 137.779C0.924839 127.4 1.01729 116.929 0.832391 106.549C0.739943 103.518 1.66442 102.967 4.53031 103.518C13.7751 105.263 13.7751 105.171 13.7751 114.54C13.7751 128.502 13.8675 142.372 13.6827 156.333C13.6827 159.548 14.6071 160.65 17.8428 161.385C37.2569 165.886 50.8467 153.119 55.2842 139.432C61.1084 121.797 53.805 103.977 37.4418 95.9862C28.3819 91.4854 18.305 90.5669 8.69046 87.995C1.01729 85.7905 0.924839 85.9743 0.924839 78.3505C1.01729 55.1118 1.10973 31.873 0.924839 8.54248C0.924839 4.40911 2.31156 3.12318 6.00947 2.20465C32.6345 -4.59245 58.8897 10.0121 64.8988 35.5472C67.7647 47.8554 67.0251 59.7962 60.1839 70.9104C57.9652 74.4927 55.7465 76.0542 51.7712 73.1149C49.9222 71.7371 46.8715 71.3697 45.947 69.7163C44.7452 67.6037 48.0733 66.2259 49.0902 64.3889C55.1918 52.9073 55.0993 41.3339 48.4431 30.3116C41.8793 19.2892 31.6175 14.1455 18.6748 14.5129C15.4392 14.6048 13.5902 15.4314 13.6827 19.5648C13.96 36.8331 13.8675 54.1014 13.7751 71.4615C13.7751 74.4008 14.4222 75.6868 17.5655 76.3297C26.9952 78.2586 36.4248 80.6468 45.1149 85.2394C62.2178 94.241 71.9248 112.152 70.0759 131.717C68.1345 153.027 56.0238 169.376 37.8116 174.428C26.4405 177.551 14.9769 176.725 3.60583 173.877C1.01729 173.234 0.832391 171.673 0.924839 169.56C0.924839 158.905 0.924839 148.342 0.924839 137.779Z" />
  </svg>
);

const LogoRoot = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  padding: 26px;
  margin-left: -12px;

  ${(props) => (props.center ? `justify-content: center;` : '')}
  ${(props) => (props.padding ? `padding: ${px(props.padding)};` : '')};
  ${(props) => (props.onClick ? 'cursor: pointer;' : '')}

  @media (max-width: 992px) and (min-width: 767.69px) {
    justify-content: center;
    margin-left: 0;
    padding: 26px 0;
  }
`;
const LogoTextContainer = styled.div`
  visibility: visible;
  width: 201px;
  &.minimized {
    visibility: hidden;
  }

  @media (max-width: 992px) and (min-width: 767.69px) {
    display: none;
  }
`;

const LogoIconContainer = styled.div`
  margin-right: 8px;

  @media (max-width: 992px) and (min-width: 767.69px) {
    margin-right: 0px;
  }
`;

export default Logo;
