import {
  COOKIE_LAST_BUY_TRADING_ACTIVE,
  COOKIE_LAST_SELL_TRADING_ACTIVE,
} from '../components/tradeControl';
import { setPage } from '../config/history';
import { deletePersistedData } from '../store/TableStore';
import { getItem, removeItem, setItem } from '../utils/localStorage';
import { disconnectCurrentWSConnection } from '../utils/websocket';
import { setCurrentUser } from './settings';

const TOKEN_FIELD = 'sky_token';
const REFRESH_TOKEN = 'sky_refresh_token';
const REFRESH_IN_PROGRESS = 'sky_refresh_in_progress';

export function setAuthToken(token: string) {
  removeItem(TOKEN_FIELD);
  setItem(TOKEN_FIELD, token);
}

export function setRefreshInProgress(status: boolean) {
  removeItem(REFRESH_IN_PROGRESS);
  setItem(REFRESH_IN_PROGRESS, status);
}

export function getRefreshInProgress() {
  return getItem(REFRESH_IN_PROGRESS) === 'true';
}

export function getAuthToken() {
  return getItem(TOKEN_FIELD);
}

export function removeAuthToken() {
  return removeItem(TOKEN_FIELD);
}

export function removeRefreshToken() {
  return removeItem(REFRESH_TOKEN);
}

export function getAuthHeader(token?: string) {
  return {
    Authorization: `Bearer ${token || getAuthToken()}`,
  };
}

export function logout() {
  setCurrentUser();
  removeAuthToken();
  removeRefreshToken();
  disconnectCurrentWSConnection();
  removeItem(COOKIE_LAST_BUY_TRADING_ACTIVE);
  removeItem(COOKIE_LAST_SELL_TRADING_ACTIVE);
  setPage();
  deletePersistedData();
}
