import { action, makeAutoObservable, observable } from 'mobx';

import { setUserCurrency } from '../helpers/settings';
import CurrenciesService from '../services/CurrenciesService';
import OperationsService from '../services/OperationsService';
import { CurrencyBalance } from '../utils/types';

export class Currency {
  @observable fiatCurrency;
  @observable fiatCurrencyHasChangedOnce = false;
  @observable fiatCurrenciesList: string[] = [];
  @observable balances: CurrencyBalance[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action setFiatCurrency = (currency: string, isAuthorized = true) => {
    if (isAuthorized) {
      this.fiatCurrencyHasChangedOnce ||= true;
    }
    this.fiatCurrency = currency;
    setUserCurrency(currency);
  };

  @action getFiatCurrencies = async () => {
    try {
      const currencyList = await CurrenciesService.getСurrencies();
      this.fiatCurrenciesList = currencyList.map((currency) => currency.id.toUpperCase());
    } catch (error) {
      console.error(error);
    }
  };

  @action getBalances = async () => {
    try {
      const balance = await OperationsService.balance();
      const balances: CurrencyBalance[] = Object.entries(balance).map((item) => ({
        currency: item[0].toUpperCase(),
        amount: item[1],
      }));
      this.balances = balances;
    } catch (error) {
      console.error(error);
    }
  };

  setFiatCurrencyByRegion = (region: string) => {
    switch (region) {
      case 'RU':
        this.fiatCurrency = 'RUB';
        break;
      case 'BY':
        this.fiatCurrency = 'BYN';
        break;
      case 'KZ':
        this.fiatCurrency = 'KZT';
        break;
      case 'UA':
        this.fiatCurrency = 'UAH';
        break;
      default:
        this.fiatCurrency = 'USD';
    }
  };
}

export const CurrencyStore = new Currency();
