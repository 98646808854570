import React from 'react';
import styled from 'styled-components/macro';

import { openLink } from '../../helpers/utils';
import { Defaults, px } from '../common';
import { PageText, PageTextProps } from '../controls/Page';

export function initParticlesBackground() {
  const init = document['init-particles-background']; // it's defined in index.html
  if (init) {
    init();
  }
}

export function initCommon() {
  const init = document['init-common']; // it's defined in index.html
  if (init) {
    try {
      init();
    } catch (error) {}
  }
}

export function initSwiper(id: string) {
  const init = document['init-swiper']; // it's defined in index.html
  if (init) {
    init(id);
  }
}

export const goTo = (id: string) => () => {
  const el: HTMLElement | null = document.querySelector(id);
  if (el) {
    window.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
  }
};

export function goUp() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

export function showSkyPayDemo() {
  openLink('https://www.youtube.com/watch?v=O2ACdshsu7I&feature=youtu.be');
}

export const ArrowDownIcon = ({
  className = 'icon-chevron-down',
}: {
  className?: string;
}) => (
  <svg className={className} viewBox={'0 0 30 18'}>
    <path
      d="M29.6334 1.24157C29.1447 0.75285 28.3547 0.75285 27.866 1.24157L15 14.1076L2.13398 1.24157C1.64526
        0.75285 0.85526 0.75285 0.36654 1.24157C-0.12218 1.73029 -0.12218 2.52029 0.36654 3.00901L14.1163 16.7587C14.36
        17.0025 14.68 17.125 15 17.125C15.32 17.125 15.64 17.0025 15.8838 16.7587L29.6335 3.00901C30.1222 2.52029
        30.1222 1.73029 29.6334 1.24157Z"
    />
  </svg>
);

export const ArrowUpIcon = () => (
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none">
    <path
      d="M0.219942 9.65507C0.513174 9.94831 0.987175 9.94831 1.28041 9.65507L9.00003 1.93549L16.7196
            9.65508C17.0128 9.94831 17.4868 9.94831 17.7801 9.65508C18.0733 9.36184 18.0733 8.88784 17.7801
            8.59461L9.53024 0.344769C9.38399 0.198522 9.19201 0.125013 8.99999 0.125013C8.80797 0.125013 8.61599
            0.198522 8.46974 0.344769L0.219906 8.59461C-0.0732898 8.88784 -0.0732898 9.36184 0.219942 9.65507Z"
    />
  </svg>
);

export const HomeText = (props: PageTextProps) => (
  <PageText
    {...props}
    size={props.size || '1.125rem'}
    weight={props.weight || 500}
    fontFamily={'DIN Pro'}
  />
);

export const HomeCaption = (props: PageTextProps) => (
  <HomeText {...props} size={props.size || '3.8vh'} center />
);

export const HomeDescription = (props: PageTextProps) => (
  <HomeText
    {...props}
    size={props.size || '1.9vh'}
    weight={400}
    lineHeight={props.lineHeight || '1.875rem'}
    color={props.color || '#a8b0b3'}
  />
);

export const HomeButton = ({
  caption,
  width,
  height,
  top,
  left,
  right,
  background,
  hoverColor,
  img,
  disabled = false,
  onClick,
}: {
  caption: string;
  width;
  height?: any;
  top?;
  left?;
  right?: number | string;
  background?;
  hoverColor?: string;
  img?: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
}) => (
  <HomeButtonRoot
    width={width}
    height={height}
    top={top}
    left={left}
    right={right}
    onClick={() => (!disabled ? onClick() : undefined)}
    disabled={disabled}
    background={background}
    hoverColor={hoverColor}>
    {img ? img : undefined}
    <HomeText k={caption} color={'white'} size={'1.9vh'} left={img ? '1rem' : 0} />
  </HomeButtonRoot>
);

export const HomeDarkBackgroundColor = '#012946';
export const HomeFontFamily =
  'Euclid Circular B,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,' +
  'Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji';

export const TelegramIcon = ({
  className,
  size,
  fill,
}: {
  className?: string;
  size?: any;
  fill?: string;
}) => (
  <svg
    className={className}
    style={{ width: size, height: size, marginLeft: -1 }}
    fill={fill}
    viewBox={'0 0 40 40'}>
    <path
      d="M9.15113 19.5667L28.4345 12.1317C29.3295 11.8083 30.1111 12.35 29.8211 13.7033L29.8228 13.7017L26.5395 29.17C26.2961 30.2667 25.6445 30.5333 24.7328 30.0167L19.7328 26.3317L17.3211 28.655C17.0545 28.9217 16.8295 29.1467 16.3128 29.1467L16.6678 24.0583L25.9345 15.6867C26.3378 15.3317 25.8445 15.1317 25.3128 15.485L13.8611 22.695L8.92446 21.155C7.85279 20.815 7.82946 20.0833 9.15113 19.5667Z"
      fill="white"
    />
  </svg>
);

export const MailIcon = ({ size, fill }: { size: any; fill: string }) => (
  <svg
    style={{ width: size, height: size, marginLeft: -1 }}
    fill={fill}
    viewBox={'0 0 512 512'}>
    <path
      d="M49.106,178.729c6.472,4.567,25.981,18.131,58.528,40.685c32.548,22.554,57.482,39.92,74.803,52.099
			c1.903,1.335,5.946,4.237,12.131,8.71c6.186,4.476,11.326,8.093,15.416,10.852c4.093,2.758,9.041,5.852,14.849,9.277
			c5.806,3.422,11.279,5.996,16.418,7.7c5.14,1.718,9.898,2.569,14.275,2.569h0.287h0.288c4.377,0,9.137-0.852,14.277-2.569
			c5.137-1.704,10.615-4.281,16.416-7.7c5.804-3.429,10.752-6.52,14.845-9.277c4.093-2.759,9.229-6.376,15.417-10.852
			c6.184-4.477,10.232-7.375,12.135-8.71c17.508-12.179,62.051-43.11,133.615-92.79c13.894-9.703,25.502-21.411,34.827-35.116
			c9.332-13.699,13.993-28.07,13.993-43.105c0-12.564-4.523-23.319-13.565-32.264c-9.041-8.947-19.749-13.418-32.117-13.418H45.679
			c-14.655,0-25.933,4.948-33.832,14.844C3.949,79.562,0,91.934,0,106.779c0,11.991,5.236,24.985,15.703,38.974
			C26.169,159.743,37.307,170.736,49.106,178.729z"
    />
    <path
      d="M483.072,209.275c-62.424,42.251-109.824,75.087-142.177,98.501c-10.849,7.991-19.65,14.229-26.409,18.699
			c-6.759,4.473-15.748,9.041-26.98,13.702c-11.228,4.668-21.692,6.995-31.401,6.995h-0.291h-0.287
			c-9.707,0-20.177-2.327-31.405-6.995c-11.228-4.661-20.223-9.229-26.98-13.702c-6.755-4.47-15.559-10.708-26.407-18.699
			c-25.697-18.842-72.995-51.68-141.896-98.501C17.987,202.047,8.375,193.762,0,184.437v226.685c0,12.57,4.471,23.319,13.418,32.265
			c8.945,8.949,19.701,13.422,32.264,13.422h420.266c12.56,0,23.315-4.473,32.261-13.422c8.949-8.949,13.418-19.694,13.418-32.265
			V184.437C503.441,193.569,493.927,201.854,483.072,209.275z"
    />
  </svg>
);

export const ArrowRightIcon = ({ size, fill }: { size?; fill?: string }) => (
  <svg style={{ width: size, height: size, fill }} viewBox={'0 0 25 25'}>
    <path d="M24.7136 5.80873C24.7133 5.80843 24.713 5.80809 24.7127 5.8078L19.61 0.729684C19.2277 0.349265 18.6094 0.35068 18.2289 0.733004C17.8484 1.11528 17.8499 1.73359 18.2321 2.11406L21.6581 5.52342H0.976562C0.437207 5.52342 0 5.96063 0 6.49999C0 7.03934 0.437207 7.47655 0.976562 7.47655H21.6581L18.2322 10.8859C17.8499 11.2664 17.8484 11.8847 18.2289 12.267C18.6094 12.6493 19.2278 12.6507 19.61 12.2703L24.7127 7.19217C24.713 7.19188 24.7133 7.19154 24.7136 7.19124C25.0961 6.80951 25.0949 6.1892 24.7136 5.80873Z" />
  </svg>
);

export const HomeRoot = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
`;

const HomeButtonRoot = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  width: ${(props) => px(props.width)};
  height: ${(props) => px(props.height || '3.75rem')};
  margin-top: ${(props) => px(props.top)};
  margin-left: ${(props) => px(props.left)};
  margin-right: ${(props) => px(props.right)};
  border-radius: 2.5vh;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  background-color: ${(props) =>
    props.disabled ? Defaults.inactiveColor : props.background || '#00d558'};
  transition: ${Defaults.transition};

  &:hover {
    background-color: ${(props) =>
      props.disabled ? Defaults.inactiveColor : props.hoverColor || '#00ea69'};
  }
`;
