import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import { onLanguageChange, t } from '../../config/i18n';
import { CurrencyStore } from '../../store';
import { BankType, PaymentMethod } from '../../utils/types';
import { Column, ContentRowBox, Defaults, Spacer, TextEx } from '../common';
import CurrenciesSelect from '../controls/CurrenciesSelect';
import { pageFontSize } from '../controls/Page';
import RadioGroupEx from '../controls/RadioGroupEx';
import SelectEx, { SelectExOptionProps } from '../controls/SelectEx';
import Txt from '../controls/Txt';
import { PaymentMethodBox } from '../paymentMethods';

interface P2PFilterProps {
  currency: string;
  bidType: string;
  paymentMethod: BankType;
  paymentMethods: PaymentMethod[];
  allowedCrypto: string[];
  subCurrency: string;
  onChange: (name, value: string) => void;
  getPaymentMethods: () => void;
}

const borderColor = '#f1f1f1';
const controlHeight = '3.75rem';

const P2PFilter = ({
  paymentMethods,
  paymentMethod,
  currency,
  bidType,
  allowedCrypto,
  subCurrency,
  onChange,
  getPaymentMethods,
}: P2PFilterProps) => {
  const { fiatCurrenciesList } = CurrencyStore;

  useEffect(() => {
    onLanguageChange(getPaymentMethods);
    getPaymentMethods();
  }, []);

  const onFieldChange = (field: string) => (value: string) => onChange(field, value);

  const paymentMethodOptions: SelectExOptionProps[] = paymentMethods.map((pm) => ({
    id: pm.id,
    component:
      pm.name === 'all' ? (
        <AllPaymentMethods
          name={t('p2p.all-payment-methods')}
          count={paymentMethods.length - 1}
        />
      ) : (
        <PaymentMethodBox paymentMethod={pm} />
      ),
  }));

  return (
    <FilterBox className={'filter-p2p'} minWidth={'100%'}>
      <FilterControl label={'bids.specify-type'}>
        <RadioGroupEx
          value={bidType}
          options={['purchase', 'sale']}
          labels={['bids.type.purchase', 'bids.type.sale']}
          onChange={onFieldChange('bidType')}
          height={controlHeight}
        />
      </FilterControl>
      <FilterControl label={'p2p.currency'} left={'1.125rem'}>
        <CurrenciesSelect
          id={currency}
          onChange={onFieldChange('currency')}
          restriction={allowedCrypto}
          borderColor={borderColor}
          minWidth={'10.375rem'}
          height={controlHeight}
        />
      </FilterControl>
      <FilterControl label={'p2p.payment-method'} left={'1.125rem'}>
        <SelectEx
          id={paymentMethod?.id}
          options={paymentMethodOptions}
          fontSize={pageFontSize}
          onChange={onFieldChange('paymentMethod')}
          borderColor={borderColor}
          minWidth={'18.375rem'}
          height={controlHeight}
          allowScroll
        />
      </FilterControl>
      <FilterControl label={'p2p.subCurrency'} left={'1.125rem'}>
        <CurrenciesSelect
          id={subCurrency}
          onChange={onFieldChange('subCurrency')}
          restriction={fiatCurrenciesList}
          borderColor={borderColor}
          minWidth={'10.375rem'}
          height={controlHeight}
        />
      </FilterControl>
    </FilterBox>
  );
};

export default observer(P2PFilter);

export const FilterControl = ({
  label,
  children,
  left,
  right,
}: {
  label?: string;
  children: React.ReactNode;
  left?: number | string;
  right?: number | string;
}) => (
  <Column left={left} right={right} padding={'.5rem 0'}>
    {label ? (
      <>
        <TextEx size={pageFontSize} weight={500} left={'2px'}>
          <Txt k={label} />:
        </TextEx>
        <Spacer height={label ? '.8125rem' : '3.2vh'} />
      </>
    ) : undefined}
    {children}
  </Column>
);

export const AllPaymentMethods = ({ name, count }: { name: string; count: number }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <TextEx size={pageFontSize}>{name}</TextEx>
    <TextEx size={pageFontSize} left={'.5rem'} color={Defaults.grayColor}>
      {count}
    </TextEx>
  </div>
);

export const FilterBox = styled(ContentRowBox)`
  flex-direction: row;
  padding: 2.5rem;
  height: auto;
  align-items: flex-end;
`;
