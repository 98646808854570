import React, { useMemo, useState } from 'react';

import { getLanguage, setLanguage } from '../../config/i18n';
import { Defaults } from '../common';
import { ico } from '../controls/IconEx';
import SelectEx from '../controls/SelectEx';

const imgSize = '1.125rem';

const LangSelect = ({
  height,
  authorized = false,
  className,
}: {
  className?: string;
  height: number | string;
  authorized?: boolean;
}) => {
  const [lang, langChange] = useState(getLanguage());

  const selectProps = useMemo(
    () => ({
      backgroundColor: authorized ? Defaults.whiteColor : undefined,
      arrowColor: authorized ? Defaults.silverGray : undefined,
      minWidth: authorized ? '0' : '12vh',
    }),
    [],
  );

  const onLangChange = (language) => {
    langChange(language);
    setLanguage(language);
  };

  return (
    <SelectEx
      id={lang}
      authorized={authorized}
      borderRadius={'8px'}
      className={className}
      minWidth={selectProps.minWidth}
      resetArrowMarginLeft
      height={height}
      backgroundColor={selectProps.backgroundColor}
      arrowColor={selectProps.arrowColor}
      fontWeight={600}
      options={[
        {
          id: 'ru',
          value: 'RU',
          img: authorized ? undefined : ico('/ico/top/ru.svg', imgSize),
          imgPosition: 'left',
        },
        {
          id: 'en',
          value: 'EN',
          img: authorized ? undefined : ico('/ico/top/en.svg', imgSize),
          imgPosition: 'left',
        },
      ]}
      onChange={onLangChange}
    />
  );
};

export default LangSelect;
