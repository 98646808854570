import React from 'react';
import styled from 'styled-components/macro';

import {
  listenNotifications,
  Notifications,
  stopListenNotifications,
} from '../../helpers/notifications';
import { getCurrentUser, setCurrentUser, setUserIsAdmin } from '../../helpers/settings';
import UsersService from '../../services/UsersService';
import { Defaults, px } from '../common';
import Txt from '../controls/Txt';

interface UserProfileProps {
  height: number | string;
  tabletHidden?: boolean;
}

interface State {
  nickname: string;
  deals: number;
  rating: number;
  likes: number;
  dislikes: number;
  verified: boolean;
  registered: number;
}

const fontSize = '1rem';

export default class UserProfile extends React.Component<UserProfileProps> {
  state: State = {
    nickname: '',
    deals: 0,
    rating: 0,
    likes: 0,
    dislikes: 0,
    verified: false,
    registered: 0,
  };

  private updating = false;

  componentDidMount(): void {
    listenNotifications(this.handleNotifications);
    this.update();
  }

  componentWillUnmount(): void {
    stopListenNotifications(this.handleNotifications);
  }

  update = () => {
    if (this.updating) {
      return;
    }
    this.updating = true;

    UsersService.profile({ id: getCurrentUser() }, (userProfile) => {
      if (userProfile) {
        this.setState({
          nickname: userProfile.nickname,
          deals: userProfile.deals.deals,
          rating: userProfile.rating,
          likes: userProfile.deals.likes,
          dislikes: userProfile.deals.dislikes,
          verified: userProfile.verified,
          registered: userProfile.registered,
        });
        setCurrentUser(userProfile.nickname);
        setUserIsAdmin(userProfile.isa || false);
      }
    }).finally(() => (this.updating = false));
  };

  handleNotifications = (notifications: Notifications) => {
    if (notifications.unreadGroups > 0) {
      if (Object.keys(notifications.deals).length > 0) {
        this.update();
      }
    }
  };

  render(): React.ReactNode {
    const { tabletHidden } = this.props;
    const { nickname } = this.state;

    return (
      <>
        <UserContainer tabletHidden={tabletHidden}>
          <UserIconContainer>
            <UserIcon size={'3.5rem'} />
          </UserIconContainer>
          {nickname && (
            <div>
              <UserTitle>
                <Txt k="top-bar.user" />
              </UserTitle>
              <UserName>{nickname}</UserName>
            </div>
          )}
        </UserContainer>
      </>
    );
  }
}

const UserIcon = ({ size }: { size: number | string }) => (
  <svg
    style={{ width: size, height: size }}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="56" height="56" rx="28" fill="white" />
    <path
      d="M38.1855 14.875C38.1855 17.6598 37.0793 20.3305 35.1102 22.2996C33.141 24.2688 30.4703 25.375 27.6856 25.375C24.9008 25.375 22.2301 24.2688 20.2609 22.2996C18.2918 20.3305 17.1855 17.6598 17.1855 14.875C17.1855 12.0902 18.2918 9.41951 20.2609 7.45038C22.2301 5.48125 24.9008 4.375 27.6856 4.375C30.4703 4.375 33.141 5.48125 35.1102 7.45038C37.0793 9.41951 38.1855 12.0902 38.1855 14.875Z"
      fill="#F3F4F5"
    />
    <path
      d="M27.6924 28.875C17.1855 28.875 1.84705 30.5063 5.92303 38.3765C9.99901 46.2467 18.2176 51.625 27.6924 51.625C37.1671 51.625 45.3857 46.2467 49.4617 38.3765C53.5377 30.5063 38.1855 28.875 27.6924 28.875Z"
      fill="#F3F4F5"
    />
  </svg>
);

const ImgValueBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;
const Value = styled.span`
  color: black;
  font-size: ${px(fontSize)};
  ${(props) => (props.left ? `margin-left: ${px(props.left)}` : '')}
`;

const UserContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;

  @media (max-width: 768px) {
    ${(props) => (props.tabletHidden ? `display: none` : '')}
  }
`;

const UserIconContainer = styled.div`
  margin-right: 16px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 31px -19px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 31px -19px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 31px -19px rgba(34, 60, 80, 0.2);
`;

const UserName = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin: 0;
`;

const UserTitle = styled.p`
  font-weight: 400;
  margin-bottom: 4px;
  font-size: 12px;
  color: ${Defaults.silverGray};
`;
