import { Dialog, DialogActions, DialogProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';

import { CloseButton, Hr, px, Spacer, TextEx } from '../common';
import ActionButton from './ActionButton';
import Txt from './Txt';

export interface DialogColors {
  submitColor?: string;
  submitHover?: string;
  cancelColor?: string;
  cancelHover?: string;
}

const actionHeight = '3.75rem';

export const Confirm = ({
  open,
  submitCaption,
  cancelCaption,
  children,
  onSubmit,
  onCancel,
  colors,
  disabled = false,
  busy,
  submitLoading = false,
}: {
  open: boolean;
  submitCaption?;
  cancelCaption?: string;
  children: React.ReactNode;
  colors?: DialogColors;
  onSubmit;
  onCancel: () => void;
  disabled?: boolean;
  submitLoading?: boolean;
  busy?: boolean;
}) => (
  <Dialog open={open} onClose={onCancel} maxWidth={false}>
    <CloseButton
      position={'absolute'}
      right={'.75rem'}
      top={'.75rem'}
      onClick={onCancel}
    />
    <DialogContent top={'1.875rem'}>{children}</DialogContent>
    <DialogActions style={{ padding: '1.125rem', justifyContent: 'center' }}>
      <ActionButton
        width={'clamp(120px, 20vw, 250px)'}
        caption={submitCaption || 'common.confirm'}
        onClick={onSubmit}
        buttonColor={colors?.submitColor}
        height={actionHeight}
        hoverColor={colors?.submitHover}
        disabled={disabled}
        loading={submitLoading}
        busy={busy}
      />
      <Spacer width={'1vh'} />
      <ActionButton
        caption={cancelCaption || 'common.cancel'}
        onClick={onCancel}
        width={'clamp(120px, 20vw, 250px)'}
        height={actionHeight}
        buttonColor={colors?.cancelColor ?? '#fd3d34'}
        hoverColor={colors?.cancelHover ?? '#fd3d34'}
        swapColors
      />
    </DialogActions>
  </Dialog>
);

export const MessageBox = ({
  open,
  children,
  caption,
  closeCaption,
  onClose,
  cancelCaption,
  onCancel,
  maxWidth,
  noFooter,
}: {
  open: boolean;
  children: React.ReactNode;
  caption?: string;
  closeCaption?: string;
  onClose: () => void;
  cancelCaption?: string;
  onCancel?: () => void;
  maxWidth?: DialogProps['maxWidth'];
  noFooter?: boolean;
}) => (
  <Dialog open={open} onClose={onCancel || onClose} maxWidth={maxWidth || false}>
    {caption ? (
      <>
        <MessageBoxCaption>
          <TextEx>
            <Txt k={caption} />
          </TextEx>
        </MessageBoxCaption>
        <Hr />
      </>
    ) : undefined}
    <CloseButton
      position={'absolute'}
      right={'.75rem'}
      top={'.75rem'}
      onClick={onCancel || onClose}
    />
    <DialogContent top={!caption ? '1.875rem' : undefined}>{children}</DialogContent>
    {caption ? <Hr /> : undefined}
    {!noFooter && (
      <DialogActions style={{ padding: '1.125rem', justifyContent: 'center' }}>
        <ActionButton
          caption={closeCaption || 'common.close'}
          height={actionHeight}
          onClick={onClose}
        />
        {onCancel ? (
          <>
            <Spacer width={'0.6vh'} />
            <ActionButton
              caption={cancelCaption || 'common.cancel'}
              height={actionHeight}
              onClick={onCancel}
              buttonColor={'#fd3d34'}
              hoverColor={'#fd3d34'}
              swapColors
            />
          </>
        ) : undefined}
      </DialogActions>
    )}
  </Dialog>
);

export const DialogTextLine = (props) => (
  <DialogTextLineRoot height={props.lineHeight} top={props.top}>
    <TextEx {...props}>{props.children}</TextEx>
  </DialogTextLineRoot>
);

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => px(props.top)};
  padding: 1.875rem;
`;
const DialogTextLineRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => px(props.height)};
  width: 100%;
  margin-top: ${(props) => px(props.top)};
`;
const MessageBoxCaption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1.875rem;
  height: 6vh;
`;
