import './css/home.css';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { getLanguage, onLanguageChange } from '../../config/i18n';
import {
  cl,
  formatNumber,
  MobileWidthLimit,
  offResize,
  offScroll,
  onResize,
  onScroll,
  openSkyBot,
  toggleClass,
} from '../../helpers/utils';
import CurrenciesService from '../../services/CurrenciesService';
import { AuthStore, LanguageStore } from '../../store';
import { FastDealRate } from '../../utils/types';
import { Defaults, onClickOutside, px } from '../common';
import IconEx from '../controls/IconEx';
import { LogoIcon } from '../controls/Logo';
import SelectEx from '../controls/SelectEx';
import Txt from '../controls/Txt';
import { Cryptos } from '../currencies';
import { HomeText, TelegramIcon } from './home-common';

interface HomeTopMenuProps {
  active?: string;
  fixed?: boolean;
}

interface MenuitemProps {
  id: string;
  page?: string;
  highlight?: boolean;
}

const gray = '#3c4c62';
const blue = '#0099de';

const menuItems: MenuitemProps[] = [
  { id: 'pc', page: 'platform' },
  { id: 'phone' },
  { id: 'sky-pay' },
  { id: 'training' },
  { id: 'faq' },
  { id: 'contacts' },
  { id: 'partner', highlight: true },
];

interface State {
  balance: string;
  balances: FastDealRate[];
  fixed: boolean;
  openMenuMobile: boolean;
}

export default class HomeTopMenu extends React.Component<HomeTopMenuProps> {
  state: State = {
    balance: 'BTC',
    balances: [],
    fixed: this.props.fixed ?? false,
    openMenuMobile: false,
  };

  componentDidMount(): void {
    this.updateRate();
    onLanguageChange(() => this.setState({}));
    onResize(this.onResize);
    onScroll(this.onScroll);
  }

  componentWillUnmount(): void {
    offResize(this.onResize);
    offScroll(this.onScroll);
  }

  updateRate = () => {
    CurrenciesService.getFastDealRates()
      .then((rates) => {
        if (rates) {
          const filterRates = rates.filter((rate) =>
            Cryptos.includes(rate.symbol.toUpperCase()),
          );
          this.setState({ balances: filterRates });
        }
      })
      .catch(() => undefined);
  };

  onResize = () => this.setState({ minimized: window.innerWidth <= MobileWidthLimit });

  onScroll = () => {
    this.setState({ fixed: this.props.fixed ?? window.scrollY > 0 });
  };

  changeBalance = (balance) => this.setState({ balance });

  toggleMenuMobile = () => {
    const openMenuMobile = !this.state.openMenuMobile;
    toggleClass('body', openMenuMobile, 'fixed');
    this.setState({ openMenuMobile });
  };

  public render(): React.ReactNode {
    const { balance, balances, fixed, openMenuMobile } = this.state;
    const { active } = this.props;
    const n = getLanguage() === 'ru' ? 'rub' : 'usd';

    return (
      <header className={'header'} id={'header'}>
        <div className={'container'}>
          <div className={`top-nav block-with-lazy ${fixed ? 'fixed' : ''}`}>
            <div className={'container'}>
              <div className={'row'}>
                <div className={'col-xl-auto col'}>
                  <Link
                    className={'top-nav__logo'}
                    to={'/'}
                    onClick={() => window.scrollTo({ top: 0 })}>
                    <LogoIcon size={'1.7em'} fill={Defaults.mainColor} />
                    <span>
                      <Txt k={'app-title'} />
                    </span>
                  </Link>
                </div>

                <div
                  itemScope={true}
                  itemType={'http://schema.org/SiteNavigationElement'}
                  className={'col d-none d-xl-block'}>
                  <ul
                    itemProp={'about'}
                    itemScope={true}
                    itemType={'http://schema.org/ItemList'}
                    className={'top-nav__nav'}>
                    {menuItems.map((mi, n) => (
                      <li
                        itemProp={'itemListElement'}
                        itemScope={true}
                        itemType={'http://schema.org/ItemList'}
                        className={'top-nav__item'}
                        key={n}>
                        <Link
                          className={`top-nav__link ${cl(
                            mi.id === active,
                            'top-nav__link--current',
                          )} ${cl(!!mi.highlight, 'top-nav__link--active')}`}
                          to={`/${mi.page || mi.id}`}
                          itemProp={'url'}
                          onClick={() => window.scrollTo({ top: 0 })}>
                          {' '}
                          <Txt k={`home-menu.${mi.id}`} />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className={'col-auto'}>
                  <div className={'custom-select-wrap'}>
                    <LangSelect fixed={fixed} />
                  </div>
                </div>

                <div className={'col-auto d-none d-md-block'}>
                  <div className={'custom-select-wrap custom-select-wrap--currency2'}>
                    <SelectEx
                      id={balance.toUpperCase()}
                      className={`top-hover ${cl(fixed, 'fixed')} rate-select`}
                      // fixed={fixed}
                      height={'50px'}
                      options={balances
                        .filter((bal) => bal.currency === n)
                        .map((b) => ({
                          id: b.symbol.toUpperCase(),
                          component: (
                            <Balance
                              crypto={b.symbol.toUpperCase()}
                              currency={b.currency.toUpperCase()}
                              amount={b.rate}
                            />
                          ),
                        }))}
                      onChange={this.changeBalance}
                    />
                  </div>
                </div>

                <div className={'col-auto'}>
                  <EnterButton />
                </div>

                <div className={'col-auto d-xl-none'}>
                  <div
                    className={`toggle-menu-mobile toggle-menu-mobile--js ${cl(
                      openMenuMobile,
                      'on',
                    )}`}
                    onClick={this.toggleMenuMobile}>
                    <span />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`menu-mobile menu-mobile--js d-xl-none ${cl(
                openMenuMobile,
                'active',
              )}`}>
              <div className={'menu-mobile__inner'}>
                <div className={'canvas-wrap'} id={'particles3-js'} />
                <ul className={'menu-mobile__nav'}>
                  {menuItems.map((mi, n) => (
                    <li className={'menu-mobile__item'} key={n}>
                      <Link
                        className={`menu-mobile__link ${cl(
                          mi.id === active,
                          'menu-mobile__link--current',
                        )} ${cl(!!mi.highlight, 'menu-mobile__link--active')}`}
                        to={`/${mi.page || mi.id}`}
                        onClick={() => {
                          toggleClass('body', false, 'fixed');
                          window.scrollTo({ top: 0 });
                        }}>
                        <Txt k={`home-menu.${mi.id}`} />
                      </Link>
                    </li>
                  ))}
                </ul>

                <div className={'menu-mobile__footer'}>
                  {balances
                    .filter((bal) => bal.currency === n)
                    .map((b, n) => (
                      <Balance
                        crypto={b.symbol}
                        key={n}
                        currency={b.currency.toUpperCase()}
                        amount={b.rate}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export const Balance = ({
  crypto,
  amount,
  currency,
}: {
  crypto;
  currency: string;
  amount: number;
}) => (
  <div className={'home-balance'}>
    <img
      src={`/img/svg/${crypto.toLowerCase()}.svg`}
      alt={crypto}
      style={{ width: '1.6em', marginLeft: '.6em' }}
    />
    <div className={'column'}>
      <span>
        {formatNumber(amount)}&nbsp;{currency}
      </span>
    </div>
  </div>
);

export const LangSelect = observer(({ fixed }: { fixed: boolean }) => {
  const { lang, options, setLanguage } = LanguageStore;
  // onLanguageChange(setLanguage);

  return (
    <HomeSelect
      value={lang}
      options={toJS(options)}
      onChange={setLanguage}
      className={'lang-select'}
      fixed={fixed}
    />
  );
});

export const LangOption = ({ img, lang }: { img; lang: string }) => (
  <div className={'lang-select-option'}>
    <IconEx src={img} size={'1.6em'} />
    <HomeText k={lang} />
  </div>
);

export interface HomeSelectOption {
  id: any;
  component: React.ReactNode;
}

export const HomeSelect = ({
  className,
  fixed,
  value,
  options,
  onChange,
}: {
  className: string;
  fixed: boolean;
  value: any;
  options: HomeSelectOption[];
  onChange: (id: any) => void;
}) => {
  const [open, setOpen] = React.useState(false);

  const onClick = (event) => {
    if (!open) {
      onClickOutside(event.currentTarget, () => setOpen(false));
    }
    setOpen(!open);
  };

  const optionClick = (id) => () => onChange(id);

  const current = options.filter((option) => option.id === value);
  const filteredOptions = options.filter((option) => option.id !== value);

  return (
    <div
      className={`home-select ${className} ${cl(fixed, 'fixed')} ${cl(open, 'open')}`}
      onClick={onClick}>
      <div className={`current ${cl(open, 'open')}`}>
        {!!current.length && current[0].component}
      </div>
      <div className={`options ${cl(open, 'open')}`}>
        {filteredOptions.map((option, n) => (
          <div className={'option'} key={n} onClick={optionClick(option.id)}>
            {option.component}
          </div>
        ))}
      </div>
      <div className={'arrow'}>
        <Arrow size={'0.66em'} fill={'#878787'} rotate={open} />
      </div>
    </div>
  );
};

export const EnterButton = () => (
  <button className={'top-nav__btn'} onClick={() => AuthStore.openLoginDialog()}>
    <Txt k={'home-menu.enter'} />
  </button>
);

export const TelegramButton = ({
  left,
  right,
  dark,
}: {
  left?;
  right?: number | string;
  dark?: boolean;
}) => (
  <TelegramButtonRoot
    left={left}
    right={right}
    onClick={() => openSkyBot()}
    border={dark ? blue : gray}>
    <TelegramIcon size={'2.4vh'} fill={dark ? 'white' : blue} />
  </TelegramButtonRoot>
);

const Arrow = ({ size, fill, rotate }: { size: any; fill: string; rotate: boolean }) => (
  <ArrowSvg
    className={rotate ? 'rotate' : ''}
    size={size}
    fill={fill}
    viewBox={'0 0 450 450'}>
    <path
      d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,
                0-44.751 c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,
                32.391-12.354,44.744,0 c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,
                354.706,225.923,354.706z"
    />
  </ArrowSvg>
);
const TelegramButtonRoot = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => px(props.left)};
  margin-right: ${(props) => px(props.right)};
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  border: 0.2vh solid ${(props) => props.border};
  cursor: pointer;
  transition: ${Defaults.transition};
  user-select: none;

  &:hover {
    background-color: ${(props) => props.border};
  }
`;

const ArrowSvg = styled.svg`
  width: ${(props) => px(props.size)};
  height: ${(props) => px(props.size)};
  margin-top: 0.8vh;
  transition: transform ${Defaults.transition};
  transform: rotate(0);
  &.rotate {
    transform: rotate(180deg);
  }
`;
