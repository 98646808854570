import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Defaults } from '../common';
import { ColumnHeader, rowValueToString } from './TableEx';

export const MobileTableRow = ({
  columns,
  row,
  rowIndex,
  onClick,
  pinnedColumns = columns.map((column) => column.id),
  layout,
  style,
  className,
}: {
  columns: ColumnHeader[];
  className?: string;
  row: Record<string, unknown>;
  layout?: 'row' | 'column';
  rowIndex: number;
  onClick?: () => void;
  pinnedColumns?: string[];
  style?: CSSProperties;
}) => {
  const { t } = useTranslation();
  const [active, setIsActive] = useState(false);

  const toggleActive = () =>
    pinnedColumns?.length !== columns.length && setIsActive((active) => !active);

  return (
    <div
      key={rowIndex}
      style={{
        borderRadius: !rowIndex ? '8px' : 'unset',
        height: 'auto',
        backgroundColor: rowIndex % 2 === 0 ? Defaults.whiteColor : Defaults.aliceBlue,
        ...style,
      }}
      onClick={toggleActive}>
      <MainContainer>
        <RowContainer
          layout={layout}
          activatable={pinnedColumns?.length !== columns.length}>
          {pinnedColumns?.map((columnId) => {
            const column = columns.find((column) => column.id === columnId);
            if (!column) return '';
            return layout === 'column' ? (
              <ActiveDataContainer key={column.id}>
                <span>{t(column.caption ?? '')}</span>
                <div style={{ width: !column.caption ? '100%' : 'unset' }}>
                  {column?.render?.(row[column.id], row, rowIndex) ??
                    rowValueToString(row[column.id])}
                </div>
              </ActiveDataContainer>
            ) : (
              column?.render?.(row[column.id], row, rowIndex) ??
                rowValueToString(row[column.id])
            );
          })}
        </RowContainer>
        {pinnedColumns?.length !== columns.length && (
          <ChevronIcon direction={active ? 1 : -1} />
        )}
      </MainContainer>

      {active && (
        <ActiveContainer>
          {columns
            .filter((column) => !pinnedColumns?.includes(column.id))
            .map((column) => (
              <ActiveDataContainer key={column.id}>
                <span>{t(column.caption ?? '')}</span>
                <div style={{ width: !column.caption ? '100%' : 'unset' }}>
                  {column?.render?.(row[column.id], row, rowIndex) ??
                    rowValueToString(row[column.id])}
                </div>
              </ActiveDataContainer>
            ))}
        </ActiveContainer>
      )}
    </div>
  );
};

const MainContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #f3f4f5;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.layout ? props.layout : 'row')};
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.layout === 'row' || props.activatable ? `padding-right: 16px;` : ''}
  ${(props) => (props.layout === 'column' ? `gap: 8px;` : '')}
  width: 100%;
`;

const ActiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
`;

const ActiveDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const ChevronIcon = ({ direction }: { direction: number }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    transform={`scale(1 ${direction})`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 15L12 9L6 15"
      stroke="#1D1D1D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
