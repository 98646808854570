import { AxiosError } from 'axios';

import { setPage } from '../config/history';
import {
  getRefreshInProgress,
  logout,
  removeRefreshToken,
  setAuthToken,
  setRefreshInProgress,
} from '../helpers/authHeader';
import { API_URL, getRefreshToken, setRefreshToken } from '../helpers/settings';
import { generalRequest } from './axiosSetup';
import { showMessage } from '../helpers/notifications';

export const AxiosErrorHandlers: {
  [index: string | number]: (error: AxiosError) => any;
} = {
  ['401']: async (error: any) => {
    if (error.response?.data.detail === 'Session is expired') {
      return logout();
    }

    const refreshToken = getRefreshToken();

    if (refreshToken && !getRefreshInProgress()) {
      removeRefreshToken();
      try {
        setRefreshInProgress(true);

        const { access, refresh }: Record<string, any> = await generalRequest({
          url: `${API_URL}/rest/v1/auth/refresh`,
          method: 'POST',
          data: { token: refreshToken },
          headers: {},
        });
        setAuthToken(access);
        setRefreshToken(refresh);

        setRefreshInProgress(false);
      } catch (error) {
        setRefreshInProgress(false);
        logout();
      }
    } else {
      !getRefreshInProgress() && logout();
    }
  },
  ['412']: () => ({}),
  ['423']: () => {
    setPage('banned');
  },
  ['403']: (error) => {
    if (['User is baned'].includes(error?.response?.data?.['detail'])) {
      logout();
      setPage('banned');
    }
  },
  default: (error) => {
    console.error('UNHANDLED ERROR: ', error);
  },
};
