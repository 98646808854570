import { action, makeAutoObservable, observable } from 'mobx';

import { getUserProfile, UserProfile } from '../components/user/PageUserProfile';
import { UserCredentials } from '../components/wallet/PageWallet';
import { getCurrentUser, getUserCredentials } from '../helpers/settings';
import { nvl } from '../helpers/utils';
import { updater } from '../utils/api';

export class User {
  @observable user: UserProfile = {};
  @observable credentials: UserCredentials = {};

  constructor() {
    makeAutoObservable(this);
    updater(this.updateUser);
  }

  @action updateUser = () => {
    getCurrentUser() &&
      getUserProfile(getCurrentUser())
        .then((user) => {
          const login = nvl(user.id);
          const { password, secret } = getUserCredentials();
          this.user = { ...user };
          this.credentials = { login, password, secret };
        })
        .catch(() => ({}));
  };
}

export const UserStore = new User();
