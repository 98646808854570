import React from 'react';
import styled from 'styled-components/macro';

import { t } from '../../config/i18n';
import { px, Spacer, TextEx } from '../common';
import IconEx from './IconEx';

export const UserDeals = ({
  deals,
  left,
  width,
  size,
  hint,
  iconPath = '/ico/top/profile/person.svg',
  weight = 400,
}: {
  deals?: number;
  left?;
  width?;
  size: number | string;
  hint?: string;
  weight?: number | string;
  iconPath?: string;
}) => {
  if (deals === undefined) {
    return <></>;
  }
  return (
    <Root left={left} width={width} title={hint}>
      <IconEx src={iconPath} size={size} />
      <TextEx left={'0.5rem'} size={size} weight={weight}>
        {deals}
      </TextEx>
    </Root>
  );
};

export const UserRating = ({
  rating,
  left,
  width,
  size,
  hint,
  iconPath = '/ico/top/profile/diamond.svg',
  weight = 400,
}: {
  rating?: number;
  left?;
  width?;
  size: number | string;
  hint?: string;
  weight?: number | string;
  iconPath?: string;
}) => {
  if (rating === undefined) {
    return <></>;
  }
  return (
    <Root left={left} width={width} title={hint}>
      <IconEx src={iconPath} size={size} />
      <TextEx left={'0.5rem'} size={size} weight={weight}>
        {rating}
      </TextEx>
    </Root>
  );
};

export const UserDealsRating = ({
  deals,
  rating,
  left,
  width,
  size,
}: {
  deals?: number;
  rating?: number;
  left?;
  width?;
  size: number | string;
}) => (
  <Root left={left}>
    <UserDeals deals={deals} size={size} width={width} hint={t('user-profile.deals')} />
    {deals !== undefined && rating !== undefined ? <Spacer width={'1vw'} /> : undefined}
    <UserRating
      rating={rating}
      size={size}
      width={width}
      hint={t('user-profile.rating')}
    />
  </Root>
);

export const UserReviews = ({
  likes,
  dislikes,
  left,
  size,
}: {
  likes?: number;
  dislikes?: number;
  left?;
  size: number | string;
}) => (
  <Root left={left}>
    {likes !== undefined ? (
      <>
        <LikeIcon size={size} fill={'#989da3'} />
        <TextEx left={'0.3125rem'} size={size}>
          {likes}
        </TextEx>
      </>
    ) : undefined}
    {likes !== undefined && dislikes !== undefined ? <Spacer width={'1vw'} /> : undefined}
    {dislikes !== undefined ? (
      <>
        <LikeIcon size={size} fill={'#989da3'} flip />
        <TextEx left={'0.3125rem'} size={size}>
          {dislikes}
        </TextEx>
      </>
    ) : undefined}
  </Root>
);

export const UserLikesDislikes = ({
  likes = 0,
  dislikes = 0,
  left,
  size,
}: {
  likes?: number;
  dislikes?: number;
  left?;
  size: number | string;
}) => (
  <Root left={left}>
    {likes >= 0 && (
      <>
        <IconEx src={'/ico/top/profile/like.svg'} size={size} />
        <TextEx left={'0.5rem'} size={size} weight={500}>
          {likes}
        </TextEx>
      </>
    )}
    {likes >= 0 && dislikes >= 0 && (
      <TextEx left={'0.5rem'} right={'0.5rem'} size={size} weight={500}>
        /
      </TextEx>
    )}
    {dislikes >= 0 && (
      <>
        <TextEx right={'0.5rem'} size={size} weight={500}>
          {dislikes}
        </TextEx>
        <IconEx src={'/ico/top/profile/dislike.svg'} size={size} />{' '}
      </>
    )}
  </Root>
);
export const LikeIcon = ({
  size,
  fill,
  flip,
}: {
  size: number | string;
  fill: string;
  flip?: boolean;
}) => (
  <LikeIconRoot
    size={size}
    viewBox={'0 0 511.999 511.999'}
    fill={fill}
    className={flip ? 'flip' : ''}>
    <path
      d="M83.578,167.256H16.716C7.524,167.256,0,174.742,0,183.971v300.881c0,9.225,7.491,16.713,16.716,16.713h66.862
		c9.225,0,16.716-7.489,16.716-16.713V183.971C100.294,174.742,92.769,167.256,83.578,167.256z"
    />
    <path
      d="M470.266,167.256c-2.692-0.456-128.739,0-128.739,0l17.606-48.032c12.148-33.174,4.283-83.827-29.424-101.835
		c-10.975-5.864-26.309-8.809-38.672-5.697c-7.09,1.784-13.321,6.478-17.035,12.767c-4.271,7.233-3.83,15.676-5.351,23.696
		c-3.857,20.342-13.469,39.683-28.354,54.2c-25.952,25.311-106.571,98.331-106.571,98.331v267.45h278.593
		c37.592,0.022,62.228-41.958,43.687-74.749c22.101-14.155,29.66-43.97,16.716-66.862c22.102-14.155,29.66-43.97,16.716-66.862
		C527.572,235.24,514.823,174.792,470.266,167.256z"
    />
  </LikeIconRoot>
);

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${(props) => px(props.left)};
  ${(props) => (props.width ? `width: ${px(props.width)};` : '')}
`;
const LikeIconRoot = styled.svg`
  width: ${(props) => px(props.size)};
  height: ${(props) => px(props.size)};
  &.flip {
    transform: scaleY(-1);
    margin-top: -2px;
  }
`;
