import React, { ChangeEvent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { isLength, isStrongPassword } from 'validator';

import { t, tt } from '../../config/i18n';
import { removeAuthToken } from '../../helpers/authHeader';
import {
  CAPTCHA_SITE_KEY_V2,
  getMarketingCampaign,
  getReferral,
  getRegistered,
  getUserEmail,
  setCurrentUser,
  setMarketingCampaign,
  setReferral,
  setRefreshToken,
  setRegistered,
  setUserCredentials,
  setUserEmail,
} from '../../helpers/settings';
import {
  cl,
  isEmail,
  onEnterPress,
  removeSpaces,
  toggleClass,
} from '../../helpers/utils';
import AuthService from '../../services/AuthService';
import { AuthStore } from '../../store';
import { classNames } from '../../utils/api';
import { swaggerErrorWrapper } from '../../utils/axiosHelpers';
import { FetchError } from '../../utils/fetchApi';
import { CloseButton } from '../common';
import Txt from '../controls/Txt';

type LoginAction = 'login' | 'register';

interface PageLoginProps {
  open: boolean;
  openAction?: LoginAction;
  email?: string;
  onSuccess?: (action: LoginAction) => void;
  onClose: () => void;
  onLogin?: (token: string) => boolean;
}

interface State {
  panel: 'create' | 'register' | 'password' | 'login' | 'mfa';
  email?: string;
  code?: string;
  password?: string;
  nickname?: string;
  error?: string;
  failureCount: number;
  loading: boolean;
}

export function toggleBodyFixed(fixed: boolean) {
  toggleClass('body', fixed, 'fixed');
}

export default class PageLogin extends React.Component<PageLoginProps> {
  public state: State = {
    panel: getDefaultPanel(this.props.openAction),
    email: getUserEmail(),
    failureCount: 0,
    loading: false,
  };

  setPanel = (panel: string) => () => {
    this.clearError();
    this.setState({ panel });
  };

  createAccount = () => {
    const { email } = this.state;
    if (email) {
      setCurrentUser();
      removeAuthToken();

      AuthService.registerEmail(
        { email },
        ({ nickname }) => this.setState({ nickname, panel: 'register' }),
        this.handleFetchError,
      );
    }
  };

  registerAccount = (code: string) => {
    const { email } = this.state;
    if (!!email && !!code) {
      this.setState({ loading: true }, () =>
        AuthService.validateEmail(
          { email, code: code.trim() },
          ({ is_valid }) => {
            if (is_valid) {
              this.setState({ code, panel: 'password' });
            }
          },
          this.handleFetchError,
        ).finally(() => this.setState({ loading: false })),
      );
    }
  };

  registrationComplete = (
    password,
    secret: string,
    setAuth: (token?: string) => void,
  ) => {
    const { email, code } = this.state;
    if (!!code && !!email) {
      AuthService.registerComplete(
        {
          email,
          code,
          password,
          secret,
          ref: getReferral(),
          cmp: getMarketingCampaign(),
        },
        ({ success }) => {
          if (success) {
            this.login('register', email, password, undefined, setAuth);
            setUserCredentials(password, secret);
            setReferral();
            setMarketingCampaign();
          }
        },
        this.handleFetchError,
      );
    }
  };

  handleFetchError = (e) => {
    if (e.response) {
      this.setState({
        error: translateError(e.response.data.detail || e.response.statusText),
      });
    } else {
      this.setState({ error: 'unknown auth error' });
    }
  };

  enterMfaCode = (code: string, setAuth: (token?: string) => void) => {
    const { email, password } = this.state;
    this.login('login', email, password, code, setAuth);
  };

  close = () => {
    this.props.onClose();
    const { panel } = this.state;
    this.setState({
      panel: panel === 'login' || panel === 'create' ? panel : getDefaultPanel(),
      submitError: undefined,
    });
    this.clearError();
  };

  setEmail = (email: string) => this.setState({ email: removeSpaces(email) });
  clearError = () => this.setState({ error: undefined });

  login = (
    action: LoginAction,
    email,
    password,
    code: string | undefined,
    setAuth: (token?: string) => void,
  ) => {
    const { onSuccess, onLogin } = this.props;
    AuthService.login(
      {
        email,
        password,
        code,
      },
      (res) => {
        if (res.request_code) {
          return this.setState({ panel: 'mfa', password });
        }
        if (res.access) {
          if (!onLogin?.(res.access)) {
            return;
          }

          setRefreshToken(res?.refresh || '');
          setAuth(res.access);
          setRegistered(true);
          setUserEmail(email);

          this.close();
          if (onSuccess) {
            onSuccess(action);
          }
          this.setState({ panel: 'login', failureCount: 0 });
        }
      },
      (e: FetchError) => {
        this.handleFetchError(e);
        this.setState({ failureCount: this.state.failureCount + 1 });
      },
    );
  };

  public render(): React.ReactNode {
    if (!!this.props.email && this.state.email !== this.props.email) {
      this.setState({
        email: removeSpaces(this.props.email),
        panel: this.props.openAction === 'login' ? 'login' : 'create',
      });
    }
    const { open } = this.props;
    const { panel, email, nickname, failureCount, error, loading } = this.state;
    const { setAuthorization } = AuthStore;

    return (
      <div className={`modal-win modal-form ${cl(open, 'open')}`}>
        <CloseButton
          onClick={this.close}
          hint={'common.close'}
          fill={'#0'}
          size={'1em'}
          padding={'0.2em'}
          position={'fixed'}
          right={'1em'}
          top={'1em'}
        />
        <div className={'modal-win__inner'}>
          <div className={'form-wrap'}>
            {
              {
                create: (
                  <CreateAccountPanel
                    email={email}
                    setEmail={this.setEmail}
                    onSubmit={this.createAccount}
                    onLogin={this.setPanel('login')}
                    error={error}
                    onClearError={this.clearError}
                  />
                ),
                register: (
                  <RegisterAccountPanel
                    email={email}
                    loading={loading}
                    setEmail={this.setEmail}
                    onSubmit={this.registerAccount}
                    error={error}
                    onClearError={this.clearError}
                  />
                ),
                password: (
                  <PasswordPanel
                    nickname={nickname}
                    onSubmit={(password, secret) => {
                      this.registrationComplete(password, secret, setAuthorization);
                    }}
                    error={error}
                    onClearError={this.clearError}
                  />
                ),
                login: (
                  <LoginPanel
                    email={email}
                    setEmail={this.setEmail}
                    verifiedUser={failureCount < 3}
                    onSubmit={(password) =>
                      this.login('login', email, password, undefined, setAuthorization)
                    }
                    onRegister={this.setPanel('create')}
                    error={error}
                    onClearError={this.clearError}
                  />
                ),
                mfa: (
                  <MfaCodePanel
                    onSubmit={(mfa) => this.enterMfaCode(mfa, setAuthorization)}
                    error={error}
                    onClearError={this.clearError}
                  />
                ),
              }[panel]
            }
          </div>
        </div>
      </div>
    );
  }
}

const CreateAccountPanel = ({
  email,
  error,
  setEmail,
  onLogin,
  onSubmit,
  onClearError,
}: {
  email?;
  error?: string;
  setEmail: (value) => void;
  onLogin;
  onSubmit;
  onClearError: () => void;
}) => {
  const [emailError, setEmailError] = React.useState('');
  const [confirm, setConfirm] = React.useState(false);
  const [confirmError, setConfirmError] = React.useState(false);

  function onEmailChange(value) {
    setEmail(value);
    setEmailError('');
    onClearError();
  }

  function onConfirmChange(value: boolean) {
    setConfirm(value);
    setConfirmError(false);
  }

  function submit() {
    const emailErr = validateEmail(email);
    if (emailErr) {
      return setEmailError(emailErr);
    }
    if (!confirm) {
      return setConfirmError(true);
    }
    onSubmit();
  }

  function login(e) {
    e.preventDefault();
    onLogin();
  }

  return (
    <>
      <PanelLogo />
      <PanelCaption k={'auth.create.title'} description={'auth.create.sub-title'} />
      <LabelControl label={'auth.email'} error={emailError || error}>
        <LoginInput
          value={email}
          // type={'email'}
          name={'email'}
          hint={'auth.email-hint'}
          onChange={onEmailChange}
          error={!!emailError || !!error}
          autoFocus={!email}
        />
      </LabelControl>
      <div className={'form-wrap__policy'}>
        <label className={'custom-input'}>
          <input
            className={'custom-input__input'}
            type={'checkbox'}
            checked={confirm}
            onChange={() => onConfirmChange(!confirm)}
          />
          <span className={`custom-input__lab ${cl(confirmError, 'border-danger')}`} />
          <div
            className={'border-danger'}
            dangerouslySetInnerHTML={{ __html: t('auth.create.confirm-text') }}
          />
        </label>
      </div>
      <LoginButton caption={'auth.create.perform'} onClick={submit} />
      <div className={'text-center'}>
        <Txt k={'auth.create.account-exists'} />
        &nbsp;
        <a className={'tdu'} href={'/'} onClick={login}>
          <Txt k={'auth.login.perform'} />
        </a>
      </div>
    </>
  );
};

const RegisterAccountPanel = ({
  email,
  error,
  loading,
  setEmail,
  onSubmit,
  onClearError,
}: {
  email?;
  error?: string;
  loading: boolean;
  setEmail: (value) => void;
  onSubmit: (code: string) => void;
  onClearError: () => void;
}) => {
  const [emailError, setEmailError] = React.useState('');
  const [code, setCode] = React.useState('');
  const [codeError, setCodeError] = React.useState('');

  function onEmailChange(value) {
    setEmail(value);
    setEmailError('');
  }

  function onCodeChange(value) {
    setCode(value.replaceAll(' ', ''));
    setCodeError('');
    onClearError();
  }

  function submit() {
    const emailErr = validateEmail(email);
    if (emailErr) {
      return setEmailError(emailErr);
    }
    if (!code) {
      return setCodeError('auth.error.code-required');
    }
    onSubmit(code);
  }

  return (
    <>
      <PanelLogo />
      <PanelCaption k={'auth.register.title'} description={'auth.register.sub-title'} />
      <LabelControl label={'auth.email'} error={emailError}>
        <LoginInput
          value={email}
          // type={'email'}
          name={'email'}
          hint={'auth.email-hint'}
          onChange={onEmailChange}
          error={!!emailError}
          autoFocus={!email}
        />
      </LabelControl>
      <LabelControl label={'auth.code'} error={codeError || error}>
        <LoginInput
          value={code}
          type={'text'}
          hint={'auth.code-hint'}
          onChange={onCodeChange}
          error={!!codeError || !!error}
          autoFocus={true}
        />
      </LabelControl>
      <LoginButton
        caption={'auth.register.perform'}
        onClick={submit}
        disabled={loading}
      />
    </>
  );
};

const PasswordPanel = ({
  nickname,
  error,
  onSubmit,
  onClearError,
}: {
  nickname?;
  error?: string;
  onSubmit: (password, secret: string) => void;
  onClearError: () => void;
}) => {
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [secret, setSecret] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [password2Error, setPassword2Error] = React.useState('');
  const [secretError, setSecretError] = React.useState('');
  const [secretAttention, setSecretAttention] = React.useState('');

  function onPasswordChange(value) {
    setPassword(value);
    setPasswordError('');
    onClearError();
  }

  function onPassword2Change(value) {
    setPassword2(value);
    setPassword2Error('');
    onClearError();
  }

  function onSecretChange(value) {
    setSecret(value);
    setSecretError('');
    onClearError();
  }

  function submit() {
    const passwordErr = validatePassword(password);
    if (passwordErr) {
      return setPasswordError(passwordErr);
    }
    if (!password2) {
      return setPassword2Error('auth.error.password-2-required');
    }
    if (password2 !== password) {
      return setPassword2Error('auth.error.password-2-wrong');
    }
    if (!secret) {
      return setSecretError('auth.error.secret-required');
    }
    if (!isLength(secret, { min: 5 })) {
      return setSecretError('auth.error.secret-wrong');
    }

    secretAttention == 'auth.secret-attention'
      ? onSubmit(password, secret)
      : setSecretAttention('auth.secret-attention');
  }

  return (
    <>
      <PanelLogo />
      <PanelCaption k={'auth.change-password.title'} error={error} />
      <LabelControl
        label={'auth.password'}
        error={passwordError}
        hint={'auth.new-password-hint'}>
        <LoginInput
          value={password}
          type={'password'}
          hint={'auth.password-hint'}
          onChange={onPasswordChange}
          error={!!passwordError}
          autoFocus
        />
      </LabelControl>
      <LabelControl label={'auth.password-2'} error={password2Error}>
        <LoginInput
          value={password2}
          type={'password'}
          hint={'auth.password-2-hint'}
          onChange={onPassword2Change}
          error={!!password2Error}
        />
      </LabelControl>
      <LabelControl
        label={'auth.secret'}
        error={secretError}
        attention={secretAttention}
        hint={'auth.new-secret-hint'}>
        <LoginInput
          value={secret}
          type={'text'}
          hint={'auth.secret-hint'}
          onChange={onSecretChange}
          attention={!!secretAttention}
          error={!!secretError}
        />
      </LabelControl>
      <LabelControl label={'auth.account'} hint={'auth.account-hint'}>
        <LoginInput value={nickname} type={'text'} />
      </LabelControl>
      <LoginButton caption={'auth.register.perform'} onClick={submit} />
    </>
  );
};

const LoginPanel = ({
  email,
  verifiedUser,
  error,
  setEmail,
  onSubmit,
  onRegister,
  onClearError,
}: {
  email?: string;
  verifiedUser: boolean;
  error?: string;
  setEmail: (value) => void;
  onSubmit: (password: string) => void;
  onRegister;
  onClearError: () => void;
}) => {
  const [emailError, setEmailError] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [verified, setVerified] = React.useState(false);

  function onEmailChange(value) {
    setEmail(value);
    setEmailError('');
  }

  function onPasswordChange(value) {
    setPassword(value);
    setPasswordError('');
    onClearError();
  }

  function canSubmit(): boolean {
    return verifiedUser || verified;
  }

  function login() {
    if (!canSubmit()) {
      return;
    }

    const emailErr = validateEmail(email);
    if (emailErr) {
      return setEmailError(emailErr);
    }

    if (!password) {
      return setPasswordError('auth.error.password-required');
    }
    // const passwordErr = validatePassword(password);
    // if (passwordErr) {
    //   return setPasswordError(passwordErr);
    // }

    onSubmit(password);
    setPassword('');
  }

  function register(e) {
    e.preventDefault();
    onRegister();
  }

  return (
    <div className={'form-wrap'} style={{ width: '26em' }}>
      <PanelLogo />
      <PanelCaption k={'auth.login.title'} />
      <LabelControl label={'auth.email'} error={emailError}>
        <LoginInput
          value={email}
          // type={'email'}
          name={'email'}
          hint={'auth.email-hint'}
          onChange={onEmailChange}
          error={!!emailError}
          autoFocus={!email}
        />
      </LabelControl>
      <LabelControl label={'auth.password'} error={passwordError || error}>
        <LoginInput
          value={password}
          type={'password'}
          name={'password'}
          hint={'auth.password-hint'}
          error={!!passwordError || !!error}
          autoFocus={!!email}
          onChange={onPasswordChange}
          onKeyPress={onEnterPress(login)}
        />
      </LabelControl>
      {!verifiedUser ? (
        <LabelControl>
          <ReCAPTCHA
            sitekey={CAPTCHA_SITE_KEY_V2}
            onChange={(token) => setVerified(!!token)}
          />
        </LabelControl>
      ) : undefined}
      <LoginButton
        caption={'auth.login.perform'}
        onClick={login}
        disabled={!canSubmit()}
      />
      <div className={'text-center'}>
        <Txt k={'auth.login.account-not-exists'} />
        &nbsp;
        <a className={'tdu'} href={'/'} onClick={register}>
          <Txt k={'auth.create.title'} />
        </a>
      </div>
    </div>
  );
};

const MfaCodePanel = ({
  error,
  onSubmit,
  onClearError,
}: {
  error?: string;
  onSubmit: (mfa: string) => void;
  onClearError: () => void;
}) => {
  const [mfa, setMfa] = React.useState('');
  const [mfaError, setMfaError] = React.useState('');

  function onMfaChange(value: string) {
    setMfa(value);
    setMfaError('');
    onClearError();
  }

  function submit() {
    if (!mfa) {
      return setMfaError('auth.error.code-required');
    }
    onSubmit(mfa);
  }

  return (
    <>
      <PanelLogo />
      <PanelCaption k={'auth.mfa.title'} />
      <LabelControl label={'auth.code'} error={mfaError || error}>
        <LoginInput
          value={mfa}
          type={'text'}
          hint={'auth.code-hint'}
          error={!!mfaError || !!error}
          onChange={onMfaChange}
          onKeyPress={onEnterPress(submit)}
          autoFocus
        />
      </LabelControl>
      <LoginButton caption={'auth.mfa.perform'} onClick={submit} />
    </>
  );
};

const PanelLogo = () => (
  <div className={'logo-main'}>
    <img className={'icon icon-logo'} src={'/img/svg/logo.svg'} alt={'logo'} />
    <div className={'logo-main__logo-title'}>
      <Txt k={'app-title'} />
    </div>
  </div>
);

const PanelCaption = ({ k, description, error }: { k; description?; error?: string }) => {
  return (
    <div className={'text-center'}>
      <div className={'form-wrap__title-top'}>
        <Txt k={k} />
      </div>
      {!error && !!description ? (
        <div className={'form-wrap__text'}>
          <Txt k={description} />
        </div>
      ) : undefined}
      <p className={'text-danger'}>
        {error ? (
          <Txt k={swaggerErrorWrapper(error)} />
        ) : !description ? (
          <>&nbsp;</>
        ) : undefined}
      </p>
    </div>
  );
};

const LabelControl = ({
  label,
  hint,
  attention,
  error,
  children,
}: {
  label?;
  hint?;
  attention?: string;
  error?: string;
  children: React.ReactNode;
}) => (
  <div className={'form-wrap__input-wrap form-group'}>
    <label>
      <span className={'form-wrap__title'}>
        {label ? (
          <>
            <Txt k={label} />:{' '}
          </>
        ) : undefined}
      </span>
      {children}
      {!error && !attention && !!hint ? (
        <p className={'text-primary pb-1'}>
          <Txt k={hint} />
        </p>
      ) : undefined}
      <p className={'text-danger'}>
        {error ? <Txt k={swaggerErrorWrapper(error)} /> : !hint ? <>&nbsp;</> : undefined}
      </p>
      <p className={'text-success'}>{!error && !!attention && <Txt k={attention} />}</p>
    </label>
  </div>
);

const LoginInput = ({
  value,
  name,
  type,
  hint,
  error,
  attention,
  autoFocus,
  onChange,
  onKeyPress,
}: {
  value?;
  name?;
  type?;
  hint?: string;
  attention?: boolean;
  error?;
  autoFocus?: boolean;
  onChange?: (value) => void;
  onKeyPress?: (e) => void;
}) => (
  <input
    className={`form-wrap__input form-control ${classNames(
      error && 'border-danger',
      attention && 'border-success',
    )}`}
    value={value}
    type={type}
    name={name}
    placeholder={t(hint)}
    autoFocus={autoFocus}
    onChange={
      onChange
        ? (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)
        : undefined
    }
    onKeyPress={onKeyPress}
    readOnly={!onChange}
  />
);

const LoginButton = ({
  caption,
  disabled,
  onClick,
}: {
  caption: string;
  disabled?: boolean;
  onClick: () => void;
}) => (
  <button className={'btn btn-warning btn-block'} onClick={onClick} disabled={disabled}>
    <Txt k={caption} />
  </button>
);

function getDefaultPanel(openAction?: LoginAction) {
  return openAction === 'login' || getRegistered() ? 'login' : 'create';
}

function validateEmail(email?: string): string | undefined {
  if (!email) {
    return 'auth.error.email-required';
  }
  if (!isEmail(email)) {
    return 'auth.error.email-wrong';
  }
}

export function validatePassword(password?: string): string | undefined {
  if (!password) {
    return 'auth.error.password-required';
  }
  if (
    !isStrongPassword(password, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 0,
    })
  ) {
    return 'auth.error.password-wrong';
  }
}

function translateError(error: string): string {
  const unknown = 'unknown error';
  const translated = tt('errors', error, unknown);
  return translated !== unknown ? translated : error;
}
