import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { formatNumber } from '../../../helpers/utils';
import { Defaults, TextEx } from '../../common';
import { UserDeals, UserLikesDislikes, UserRating } from '../../controls/User';
import { CalendarClockIcon } from '../../fastdeal/Icons';
import { ArrowRightIcon } from '../../promocodes/PromocodeWindows';
import SpinnerContainer from '../../universalComponents/SpinnerContainer';
import { UserProfile } from '../PageUserProfile';
import { UserInfoLine } from './UserInfoLine';

export const UserInfoBlock = ({ user }: { user: UserProfile }) => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <SpinnerContainer loading={!user.id}>
        <StyledSpinnerContainer>
          <StyledBlock flexGrow={1}>
            <TextEx color={Defaults.silverGray}>{t(`wallet.user`)}</TextEx>
            <StyledTextEx
              top={'0.5rem'}
              color={Defaults.neroColor}
              size={'1.875rem'}
              bottom={'2.5rem'}>
              {user.id}
            </StyledTextEx>
            <UserInfoLine caption={'user-profile.verification'} bottom={'1rem'}>
              {!!user.verified && (
                <TextEx color={Defaults.activatedColor} size={'1.125rem'}>
                  {t(`user-profile.verification-yes`)}
                </TextEx>
              )}
              {!user.verified && (
                <Link
                  className="user-profile-box__link"
                  to={{ pathname: 'settings', state: { verificationStep: true } }}>
                  <TextEx color={Defaults.cinnabar} size={'1.125rem'}>
                    {t(`user-profile.verification-no`)}
                  </TextEx>
                  <ArrowRightIcon size={'1rem'} fill={'black'} />
                </Link>
              )}
            </UserInfoLine>
            <UserInfoLine caption={'user-profile.duration'}>
              <StyledDurationWrapper>
                <CalendarClockIcon />
                <TextEx
                  weight={500}
                  size={'1.125rem'}
                  color={Defaults.neroColor}
                  left={'0.5rem'}>
                  {user.duration}
                </TextEx>
              </StyledDurationWrapper>
            </UserInfoLine>
          </StyledBlock>
          <StyledBlock>
            <StyledDivider />
          </StyledBlock>
          <StyledBlock flexGrow={1}>
            <UserInfoLine caption={'user-profile.amount'} bottom={'1rem'}>
              <TextEx
                weight={'500'}
                color={Defaults.blackColor}
                size={'1.125rem'}
                textAlign={'right'}>
                {`${formatNumber(user.amount, 8)} ${user.currency}`}
              </TextEx>
            </UserInfoLine>
            <UserInfoLine caption={'user-profile.deals'} bottom={'1rem'}>
              <UserDeals
                deals={user.deals}
                size={'1.125rem'}
                weight={'500'}
                iconPath={'/ico/top/profile/fire.svg'}
              />
            </UserInfoLine>
            <UserInfoLine caption={'user-profile.rating'} bottom={'1rem'}>
              <UserRating
                rating={user.rating}
                size={'1.125rem'}
                weight={'500'}
                iconPath={'/ico/top/profile/diamond-second.svg'}
              />
            </UserInfoLine>
            <UserInfoLine caption={'user-profile.reviews'}>
              <UserLikesDislikes
                likes={user.likes}
                dislikes={user.dislikes}
                size={'1.125rem'}
              />
            </UserInfoLine>
          </StyledBlock>
        </StyledSpinnerContainer>
      </SpinnerContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  border-radius: 8px;
  box-shadow: 2px 3px 32px 0px rgba(168, 184, 200, 0.32);
  background-color: ${Defaults.whiteColor};
`;

const StyledSpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
  flex-grow: ${(props) => props.flexGrow || 0};

  @media (max-width: 1670px) and (min-width: 576px) {
    flex-direction: row;
  }
`;

const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: ${(props) => props.flexGrow || 0};
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 2.5rem 0;
  background-color: ${Defaults.black24};

  @media (max-width: 1670px) and (min-width: 576px) {
    margin: 0 2.5rem;
    height: 100%;
    width: 1px;
    background-color: ${Defaults.black24};
  }
`;

const StyledTextEx = styled(TextEx)`
  @media (max-width: 1670px) and (min-width: 576px) {
    margin-bottom: 0.7rem;
  }
`;

const StyledDurationWrapper = styled.div`
  display: flex;
  align-items: center;
`;
