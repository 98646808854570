import React from 'react';
import styled from 'styled-components/macro';

import { calcSize, Defaults, px } from '../common';

interface CheckboxExProps {
  checked: boolean;
  size?: number | string;
  left?: any;
  onChange?: (checked: boolean) => void;
  hideUncheckedSign?: boolean;
  checkedColor?: string;
  uncheckedColor?: string;
  signColor?: string;
}

const CheckboxEx = ({
  checked,
  size = '.75rem',
  left,
  onChange,
  hideUncheckedSign,
  checkedColor,
  uncheckedColor,
  signColor,
}: CheckboxExProps) => (
  <CheckboxRoot
    size={size}
    left={left}
    color={
      checked ? checkedColor || Defaults.greenColor : uncheckedColor || Defaults.redColor
    }
    onClick={onChange ? () => onChange(!checked) : undefined}>
    {checked ? (
      <IconChecked size={calcSize(size, (n) => n * 0.8)} fill={signColor || 'white'} />
    ) : !hideUncheckedSign ? (
      <IconUnchecked size={calcSize(size, (n) => n * 0.8)} fill={signColor || 'white'} />
    ) : undefined}
  </CheckboxRoot>
);

export const IconChecked = ({ size, fill }: { size: any; fill: string }) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 26 26'} fill={fill}>
    <path
      d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,
            0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,
            0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z"
    />
  </svg>
);

export const IconUnchecked = ({ size, fill }: { size: any; fill: string }) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 512 512'} fill={fill}>
    <path
      d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,
            0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,
            14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,
            4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,
            9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"
    />
  </svg>
);

const CheckboxRoot = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${(props) => px(props.size)};
  height: ${(props) => px(props.size)};
  margin-left: ${(props) => px(props.left)};
  background-color: ${(props) => props.color};
  border-radius: 2px;
  ${(props) => (props.onClick ? 'cursor: pointer;' : '')}
`;

export default CheckboxEx;
