import { action, makeAutoObservable, observable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

import { Bid } from '../components/bids/BidCard';
import { P2PRow } from '../components/p2p';
import BrokersService from '../services/BrokersService';
import { BankType, DealShort } from '../utils/types';

export type PersistedBrokers = Record<string, BankType>;

let shadowBrokers: PersistedBrokers = {};

export const setShadowBrokers = (persistedBrokers) => {
  shadowBrokers = persistedBrokers;
};

export class Brokers {
  @observable.deep private _persistedBrokers: PersistedBrokers = {};

  constructor({
    localStoragePropName,
    persistedBrokers,
  }: {
    localStoragePropName?: string;
    persistedBrokers: PersistedBrokers;
  }) {
    makeAutoObservable(this);
    localStoragePropName &&
      makePersistable(this, {
        name: localStoragePropName,
        expireIn: 86400000, // One day in milliseconds
        removeOnExpiration: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        properties: ['_persistedBrokers'],
        storage: window.localStorage,
      });
    this._persistedBrokers = persistedBrokers;
  }

  get persistedBrokers() {
    return this._persistedBrokers;
  }

  @action setPersistedBrokers = (persistedBrokers: PersistedBrokers) => {
    this._persistedBrokers = persistedBrokers;
  };

  loadBrokersLogoAndName = async (
    data: DealShort[] | Bid[] | P2PRow[],
    dealIndex = 0,
  ) => {
    const brokerId = data[dealIndex]?.brokerId;

    if (brokerId && !shadowBrokers[brokerId]) {
      const brokerData = await BrokersService.getBrokerById(brokerId);
      if (!shadowBrokers[brokerId]) {
        shadowBrokers = {
          ...shadowBrokers,
          [brokerId]: brokerData,
        };
      }
    }
    if (data.length !== dealIndex + 1) {
      this.loadBrokersLogoAndName(data, ++dealIndex);
    } else {
      this.setPersistedBrokers({ ...shadowBrokers });
    }
  };

  async clearPersistedData() {
    await clearPersistedStore(this);
  }
}

export const BrokersStore = new Brokers({
  localStoragePropName: 'brokers',
  persistedBrokers: {},
});
