import { useEffect, useMemo, useState } from 'react';

import useMediaQuery from '../../../hooks/useMediaQuery';

export const DOTS = '...';

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
  totalPages,
  limit = 10,
  currentPage,
}): (number | '...')[] => {
  const [siblingsCount, setSiblingsCount] = useState(1);

  const isXS = useMediaQuery(`(max-width: 576px)`);
  const isS = useMediaQuery(`(max-width: 768px)`);
  const isM = useMediaQuery(`(max-width: 992px)`);
  const isL = useMediaQuery(`(max-width: 1200px)`);
  const isXL = useMediaQuery(`(max-width: 1400px)`);

  const maxPages = isXS ? 4 : isS ? 6 : isM ? 8 : isL ? 10 : isXL ? 13 : 13;

  useEffect(() => {
    setSiblingsCount(isXS ? 0 : isS ? 1 : isM ? 1 : isL ? 2 : isXL ? 3 : 3);
  }, [isXS, isS, isM, isL, isXL]);

  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblingsCount + maxPages;

    if (totalPageNumbers >= totalPages) {
      return range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingsCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingsCount, totalPages);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingsCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingsCount;
      const rightRange = range(totalPages - rightItemCount + 1, totalPages);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return [
      firstPageIndex,
      ...range(leftSiblingIndex, rightSiblingIndex),
      DOTS,
      lastPageIndex,
    ];
  }, [totalPages, limit, siblingsCount, currentPage]);

  return paginationRange;
};
