import { FormControlLabel, Radio, RadioGroup, withStyles } from '@material-ui/core';
import { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import { RadioProps } from '@material-ui/core/Radio';
import React from 'react';
import { Translation } from 'react-i18next';

import { Defaults } from '../common';
import { pageFontSize } from './Page';

const RadioGroupEx = ({
  value,
  options,
  labels,
  onChange,
  minWidth,
  height,
}: {
  value: string;
  options: string[];
  labels: string[];
  onChange: (value: string) => void;
  minWidth?: number;
  height?: number | string;
}) => (
  <Translation>
    {(t) => (
      <RadioGroup
        row
        value={value}
        onChange={(e, v) => onChange(v)}
        style={{ minWidth, height }}>
        {options.map((option, i) => (
          <RadioLabel
            value={option}
            label={t(labels[i])}
            control={<RadioEx />}
            key={option}
            checked={option === value}
          />
        ))}
      </RadioGroup>
    )}
  </Translation>
);

const RadioLabel = withStyles({
  label: {
    fontSize: pageFontSize,
  },
})((props: FormControlLabelProps) => <FormControlLabel {...props} />);

const RadioEx = withStyles({
  root: {
    color: '#e9e9e9',
    '&$checked': {
      color: Defaults.mainColor,
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

export default RadioGroupEx;
